import React, { Component } from "react";
import SubHeader from "../components/SubHeader";
import { connect } from "react-redux";
import ReactHtmlParser from 'html-react-parser';
import isEmpty from "lodash/isEmpty";

import { fetchTerms } from "../actions";
import Loader from "../components/Loader";

class TermsOfUse extends Component {
  componentDidMount() {
    if (isEmpty(this.props.terms)) {
      this.props.fetchTerms((terms) => {
        // console.log("terms: ", terms[0]);
      });
    }
  }

  renderTerms() {
    const terms = this.props.terms[0];

    if (isEmpty(terms)) {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <React.Fragment>
          {ReactHtmlParser(terms.content.rendered)}
        </React.Fragment>
      );
    }
  }
  renderTermsTitle() {
    const termsTitle = this.props.terms[0];
    if (isEmpty(termsTitle)) {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <React.Fragment>
          {ReactHtmlParser(termsTitle.title.rendered)}
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <main className="main main--terms">
        <SubHeader title={this.renderTermsTitle()} body="" hasBorder={true} />
        <div className="generic-content">
          <div className="generic-content__block">{this.renderTerms()}</div>
        </div>
      </main>
    );
  }
}

function mapStateToProps({ terms }) {
  return {
    terms,
  };
}

export default connect(mapStateToProps, { fetchTerms })(TermsOfUse);
