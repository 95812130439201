import React, { Component } from "react";
import SubHeader from "../components/SubHeader";
import Translations from "../lang/translations.json";
import imgAbout from "../../img/LIESEL_presentation.png";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].about;
class About extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  questionnaire = (lang) => {
    switch (lang) {
      case "FR":
        return "cT00MyZtYWlsPTE";
      case "EN":
        return "cT0zMjEmbWFpbD0y";
      case "DE":
        return "cT0zMjAmbWFpbD0y";
      default:
        break;
    }
  };
  render() {
    const knownUser = window.localStorage.getItem("firstName");
    const contactAvizi = window.localStorage.getItem("contactAvizi")
      ? window.localStorage.getItem("contactAvizi")
      : "none";
    const contactEmail = window.localStorage.getItem("contactEmail")
      ? window.localStorage.getItem("contactEmail")
      : "none";
    const questionnaire = `https://app.avizi.fr/questionnaires-form/get/${this.questionnaire(
      lang
    )}/${contactEmail}/${contactAvizi}`;
    if (knownUser) {
      return (
        <main className="main main--about">
          <SubHeader
            title={Texts.qui_suis_je}
            body={Texts.je_suis_liesel}
            hasBorder={true}
          />
          <div className="generic-content">
            <div className="generic-content__block">
              <h3>
                {Texts.bonjour} {knownUser},
              </h3>
              <p>{Texts.je_suis_liesel}</p>
              <p>{Texts.passionnee_dalsace}</p>
              <p className="hide-desktop">{Texts.ajoutez_moi}</p>
              <p>
                {Texts.si_vous_avez}
                <span className="sub-header__body" onClick={this.handleClick}>
                  Avizers
                </span>
                {Texts.vous_renseigneront}
              </p>
              <p>{Texts.vous_appreciez}</p>
              <p>
                <a
                  href={questionnaire}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  {Texts.dites_le_nous}
                </a>
                &nbsp; {Texts.et_surtout}
              </p>
              <p>Liesel</p>
              <img src={imgAbout} alt="Alsace" className="about-img" />
            </div>
          </div>
        </main>
      );
    }
    return (
      <main className="main main--about">
        <SubHeader
          title={Texts.qui_suis_je}
          body={Texts.je_suis_liesel}
          hasBorder={true}
        />
        <div className="generic-content">
          <div className="generic-content__block">
            <h3>{Texts.bonjour},</h3>
            <p>{Texts.je_suis_liesel}</p>
            <p>{Texts.passionnee_dalsace}</p>
            <p>{Texts.pour_recevoir}</p>
            <p className="hide-desktop">{Texts.ajoutez_moi}</p>
            <p>{Texts.vous_appreciez}</p>
            <p>
              <a
                href={questionnaire}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                {Texts.dites_le_nous}
              </a>
              &nbsp; {Texts.et_surtout}
            </p>
            <p>Liesel</p>
            <img src={imgAbout} alt="Alsace" className="about-img" />
          </div>
        </div>
      </main>
    );
  }
}
export default About;
