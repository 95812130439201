// function getBaseUrl(suffix) {
//   let source = "liesel--alsace.apsulis.fr";
//   let parts = window.location.hostname.split(".");
//   let domain = parts.join(".");
//   let separator = '--';

//   if (
//     process.env.NODE_ENV === "production" &&
//     domain.includes("liesel.alsace")
//   ) {
//     source = "liesel.alsace";
//     separator = '.';
//   }

//   if (!suffix) {
//     return `https://api${separator}${source}/wp-json`;
//   } else {
//     return `https://api${separator}${source}`;
//   }
// }
console.log(process.env);

export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
