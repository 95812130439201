import { FETCH_ALL_FOOTERS } from '../constants/ActionTypes';

function allFootersReducer(state = [], action) {
  switch (action.type) {
    case FETCH_ALL_FOOTERS:
      return action.payload;
    default:
      return state;
  }
}

export default allFootersReducer;
