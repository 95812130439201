import React, { Component } from "react";
import Iframe from "react-iframe";
import SubHeader from "../components/SubHeader";
import Form from "../components/Form";
import { connect } from "react-redux";
import { fetchCities } from "../actions";
import Translations from "../lang/translations.json";
import { loadReCaptcha } from "react-recaptcha-v3";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].register;

let formUrl = "";
switch (lang) {
  case "FR":
    formUrl =
      "https://app.avizi.fr/widgets/public/14630c810471a0488db644aa3d5e2776/2529";
    break;
  case "EN":
    formUrl =
      "https://app.avizi.fr/widgets/public/14630c810471a0488db644aa3d5e2776/2530";
    break;
  case "DE":
    formUrl =
      "https://app.avizi.fr/widgets/public/14630c810471a0488db644aa3d5e2776/2531";
    break;
  default:
    formUrl =
      "https://app.avizi.fr/widgets/public/14630c810471a0488db644aa3d5e2776/2529";
    break;
}

class Register extends Component {
  componentDidMount() {
    loadReCaptcha("6LeCguQUAAAAAB-ueXIMSPULisgUU2NJH2ebZum0");

    const { cities } = this.props;
    if (!cities || !cities.length) {
      this.props.fetchCities((cities) => {
        this.setState({ cities });
      });
    } else {
      this.setState({ cities });
    }
  }

  render() {
    const { cities } = this.props;
    return (
      <main className="main main--abonnement">
        <SubHeader
          title={Texts.comme_lauriane_et_mathieu}
          body=""
          hasBorder={true}
        />
        <div className="iframeContainer">
          <Iframe
            url="https://www.youtube.com/embed/EWzpzk8X8-U"
            width="900px"
            height="720px"
            id="iframe__video"
            className="video"
            display="initial"
            position="relative"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen
          />
        </div>
        <div className="iframeContainer">
          <Iframe
            url={formUrl}
            width="900px"
            height="720px"
            id="iframe__form"
            className=""
            display="initial"
            position="relative"
          />
        </div>
      </main>
    );
  }
}

const mapStateToProps = ({ cities }) => {
  return { cities };
};

export default connect(mapStateToProps, { fetchCities })(Register);
