import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { frDateFormat } from "../helpers";
import {
  MODAL_TYPE_DATE,
  MODAL_TYPE_LOCATION,
  //MODAL_TYPE_SUBSCRIBE,
} from "../constants/ModalTypes";
import { showModal } from "../actions";
import Translations from "../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].searchbox;

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.openDateModal = this.openDateModal.bind(this);
    this.openLocationModal = this.openLocationModal.bind(this);
  }

  componentDidMount() {}

  openDateModal = (e) => {
    e.preventDefault();
    this.props.showModal(MODAL_TYPE_DATE, {
      title: "Title of date modal",
      position: "left",
    });
  };

  openLocationModal = (e) => {
    e.preventDefault();
    // console.log(this.props)
    this.props.showModal(MODAL_TYPE_LOCATION, {
      title: "Title of location modal",
      position: "right",
    });
  };

  openSubscribeModal = () => {
    return;
    /**
     * Deactivate modal for now
     * https://apsulis.freshdesk.com/a/tickets/901
     */
    // this.props.showModal(MODAL_TYPE_SUBSCRIBE, {
    //   title: "Title of subscribe modal",
    //   position: "center",
    // });
  };

  renderSearchBoxDate() {
    var value = this.props.selectedDate
      ? frDateFormat(this.props.selectedDate)
      : Texts.quand;

    if (value === moment().format("DD/MM/YYYY")) {
      value = "Aujourd'hui";
    }

    return (
      <div className="search-box__inner search-box__inner--date">
        {this.props.isActive ? (
          <div className="search-box__link">
            <div className="search-box__header search-box__header--date">
              <h4 className="search-box__title hide-desktop">{Texts.quand}</h4>
              <h4 className="search-box__title hide-mobile">{Texts.quand}</h4>
            </div>
            <div className="search-box__body hide-mobile">
              <p className="search-box__txt">{Texts.quand}</p>
            </div>
          </div>
        ) : (
          <a
            href="/"
            title=""
            className="search-box__link"
            onClick={this.openDateModal}
          >
            <div className="search-box__header search-box__header--date">
              <h4 className="search-box__title hide-desktop">{value}</h4>
              <h4 className="search-box__title hide-mobile">{Texts.quand}</h4>
            </div>
            <div className="search-box__body hide-mobile">
              <p className="search-box__txt">{value}</p>
            </div>
          </a>
        )}
      </div>
    );
  }

  renderSearchBoxLocation() {
    let ville = this.props.selectedCity ? this.props.selectedCity : Texts.ou;
    if (ville !== Texts.ou) {
      ville = ville.toLowerCase();
    }
    return (
      <div className="search-box__inner search-box__inner--location">
        {this.props.isActive ? (
          <div className="search-box__link">
            <div className="search-box__header search-box__header--location">
              <h4 className="search-box__title hide-desktop">{Texts.ou}</h4>
              <h4 className="search-box__title hide-mobile">{Texts.ou}</h4>
            </div>
            <div className="search-box__body hide-mobile">
              <p className="search-box__txt">{Texts.ou}</p>
            </div>
          </div>
        ) : (
          <a
            href="/"
            title=""
            className="search-box__link"
            onClick={this.openLocationModal}
          >
            <div className="search-box__header search-box__header--location">
              <h4 className="search-box__title hide-desktop">{ville}</h4>
              <h4 className="search-box__title hide-mobile">{Texts.ou}</h4>
            </div>
            <div className="search-box__body hide-mobile">
              <p className="search-box__txt">{ville}</p>
            </div>
          </a>
        )}
      </div>
    );
  }

  renderSearchBoxEat() {
    return (
      <div className="search-box__inner search-box__inner--eat">
        <Link
          to={`/resultats?manger=${this.props.href}`}
          title=""
          className="search-box__link"
        >
          <div className="search-box__header">
            <h4 className="search-box__title">Manger ?</h4>
          </div>
          <div className="search-box__body">
            <p className="search-box__txt">{this.props.category}</p>
          </div>
        </Link>
      </div>
    );
  }

  render() {
    let dateExpirationInscription = window.localStorage.getItem(
      "dateExpirationInscription"
    );
    let modalSubscribeStop = window.localStorage.getItem("modalSubscribeStop");
    if (dateExpirationInscription === null && modalSubscribeStop !== "true") {
      setTimeout(() => {
        this.openSubscribeModal();
      }, 10000);
    }
    const type = this.props.type;
    const classesBox = this.props.isActive
      ? "search-box is-active"
      : "search-box";

    if (type === "date") {
      return <div className={classesBox}>{this.renderSearchBoxDate()}</div>;
    } else if (type === "location") {
      return <div className={classesBox}>{this.renderSearchBoxLocation()}</div>;
    } else if (type === "eat") {
      return <div className="search-box">{this.renderSearchBoxEat()}</div>;
    }
  }
}

export default connect(null, { showModal })(SearchBox);
