import React, { Component } from "react";
import SubHeader from "../components/SubHeader";
import { connect } from "react-redux";
import ReactHtmlParser from "html-react-parser";
import isEmpty from "lodash/isEmpty";

import { fetchPage } from "../actions";
import Loader from "../components/Loader";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");

class Page extends Component {
  componentDidMount() {
    if (isEmpty(this.props.page) || this.props.page.slug !== this.props.location.pathname.replace("/page/", "")) {
      this.props.fetchPage(
        this.props.location.pathname.replace("/page/", ""),
        lang
      );
    }
  }

  renderPage() {
    const page = this.props.page;
    if (page === undefined || isEmpty(page) || typeof page !== "object") {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <React.Fragment>
          {ReactHtmlParser(page.content.rendered)}
        </React.Fragment>
      );
    }
  }
  renderPageTitle() {
    const pageTitle = this.props.page;
    if (isEmpty(pageTitle)) {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <React.Fragment>
          {ReactHtmlParser(pageTitle.title.rendered)}
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <main className="main main--terms">
        <SubHeader title={this.renderPageTitle()} body="" hasBorder={true} />
        <div className="generic-content">
          <div className="generic-content__block">{this.renderPage()}</div>
        </div>
      </main>
    );
  }
}

function mapStateToProps({ page }) {
  return {
    page,
  };
}

export default connect(mapStateToProps, { fetchPage })(Page);
