import React from 'react';
import SubHeader from '../components/SubHeader';

const NotFound = () => {
  return (
    <main className="main main--404">
      <SubHeader
        title="Erreur 404"
        body="La page que vous recherchez n'existe pas"
        hasBorder={false}
      />
    </main>
  );
};

export default NotFound;
