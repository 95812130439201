import React, { Component } from "react";
import PropTypes from "prop-types";

import closeMdGreyIcon from "../../img/ico-close-md-grey.svg";

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    onClose: PropTypes.func,
  };

  listenKeyboard = (event) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      this.props.onClose();
    }
  };

  componentDidMount() {
    if (this.props.onClose) {
      window.addEventListener("keydown", this.listenKeyboard, true);
    }
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      window.removeEventListener("keydown", this.listenKeyboard, true);
    }
  }

  onOverlayClick = () => {
    this.props.onClose();
  };

  onDialogClick = (event) => {
    event.stopPropagation();
  };

  render() {
    const { position } = this.props;
    const classes = `modal modal--${position}`;

    return (
      <div className={classes}>
        <div className="modal__outer">
          <div
            className="modal__close"
            style={{
              backgroundImage: `url(${closeMdGreyIcon})`,
            }}
            onClick={this.props.onClose}
          />
          <div className="modal__inner">
            {/* <div className="modal__title">{this.props.title}</div> */}
            {this.props.children}
          </div>
        </div>
        <div className="modal__overlay" onClick={this.onOverlayClick} />
      </div>
    );
  }
}
