import React from "react";

const ButtonChecked = ({
  title,
  isSelected,
  id,
  imgURL,
  handleOnClick,
  copyright,
}) => {
  const classesBtn = isSelected ? "checked-btn is-active" : "checked-btn";
  return (
    <button onClick={handleOnClick}>
      <div key={id} className="slide slide--filter">
        <div className="slide__inner">
          <img src={imgURL} alt="" className="slide__img" />
          <div className="slide__credit">{copyright}</div>
          <div className="slide__content">
            <h4 className="slide__title">{title}</h4>
            <span className={classesBtn} aria-pressed={isSelected}>
              Filtrer
            </span>
          </div>
        </div>
      </div>
    </button>
  );
};

export default ButtonChecked;
