import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

import { FACEBOOK_ID, GA_ID } from "../constants/Tracking";
import Axeptio from "../utils/Axeptio";
import Gtag from "../utils/Gtag";

class AxeptioProvider extends Component {
  state = {
    userChoice: false,
  };

  componentDidMount() {
    this.initAxeptio();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname, search },
    } = this.props;
    const {
      location: { pathname: prevPathname, search: prevSearch },
    } = prevProps;
    const page = `${pathname}${!!search.length ? search : ""}`;
    const prevPage = `${prevPathname}${!!prevSearch.length ? prevSearch : ""}`;

    if (page !== prevPage) {
      this.sendPageView(page);
    }
  }

  initAxeptio = () => {
    Axeptio.initializeAxeptio();
    Axeptio.loadAxeptioScript();
    Axeptio.reloadSpaForAxeptio();
    this.initCookies();
  };

  initCookies = () => {
    // https://developers.axeptio.eu/v/francais/cookies/gtag-et-google-consent-mode
    // google tools use queues to push information
    // before their libraries are loaded. We need to
    // define them if this code runs first
    window.adsbygoogle = window.adsbygoogle || [];
    window.dataLayer = window.dataLayer || [];

    const setState = (userChoice) => {
      this.setState({ userChoice });
    };

    // Axeptio use the same technique. _axcb holds the
    // callback functions that will be executed when
    // our lib is loaded
    window._axcb = window._axcb || [];

    // we pause ad requests by default
    // https://support.google.com/adsense/answer/9042142
    window.adsbygoogle.pauseAdRequests = 1;

    // first we need to push a "js" command to the queue. This will
    // register the exact time of the DOM being ready
    // as stated here: https://developers.google.com/analytics/devguides/collection/gtagjs
    Gtag.gtag("js", new Date());

    // don't send consent since it triggers first pageview twice
    const additionalConfigInfo = {
      anonymize_ip: true,
    };

    // after that we wand to update the consent
    // when the user gives his/her consent
    window._axcb.push(function (axeptio) {
      // now that axeptio is loaded, we add completion
      // handler to execute when the user give his/her consent.
      // this will also happen when we parse the axeptio_cookie
      // which contains the preferences of a user
      axeptio.on("cookies:complete", function (choices) {
        // if you renamed your vendors in Axeptio's admin panel
        // change the content of these variables.
        const gaVendorName = "google_analytics";
        const adsVendorName = "Google_Ads";
        const facebookPixelName = "facebook_pixel";

        if (choices[gaVendorName]) {
          Gtag.install(GA_ID, additionalConfigInfo, true);
          // consentSettings.anonymize_ip = "false"; // laisser à true
          setState(true);
        }

        if (choices[adsVendorName]) {
          // we don't grant consent when user accepts since we are no longer using it
        } else {
          //When ad_storage is set to 'denied', Google tags will not create or save cookies.
          //However, you can optionally elect to pass information through URL parameters
          //across pages in order to improve measurement quality.
          Gtag.gtag("set", "url_passthrough", true);
          window.adsbygoogle.requestNonPersonalizedAds = 1;
        }

        // we don't update the Google Consent Mode variable since we are no longer using it
        // Ads can be resumed.
        window.adsbygoogle.pauseAdRequests = 0;

        // facebook
        const advancedMatching = { null: null }; // optional
        const options = {
          autoConfig: true, // set pixel's autoConfig
          debug: true, // enable logs
        };

        ReactPixel.init(FACEBOOK_ID, advancedMatching, options);
        if (choices[facebookPixelName]) {
          ReactPixel.grantConsent();
        } else {
          ReactPixel.revokeConsent();
        }
      });
    });
  };

  sendPageView = (page) => {
    // https://developers.google.com/tag-platform/gtagjs/reference/events#page_view
    Gtag.gtag("event", "page_view", { send_to: GA_ID, page_location: page });
  };

  render() {
    if (!this.state.userChoice) {
      return null;
    }

    return (
      <script src="https://alsace.analytics.pylot.fr/js/oswtracking.js"></script>
    );
  }
}

export default withRouter(AxeptioProvider);
