import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { fetchSettings } from "../actions";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AxeptioProvider from "../hooks/useAxeptio";
import MatomoHook from "../hooks/useMatomo";

import Home from "./Home";
import TermsOfUse from "./TermsOfUse";
import Page from "./Page";
import WhoAmI from "./About";
import Unsubscribe from "./Unsubscribe";
import Article from "./Article";
import Register from "./Register";
import Results from "./Results";
import NotFound from "./NotFound";
import ScrollToTop from "../components/ScrollToTop";
import Blog from "./Blog";
import BlogPost from "./BlogPost";

const Router = (props) => {
  const fetchData = () => {
    props.fetchSettings();
  };

  useEffect(fetchData, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <AxeptioProvider />
        <MatomoHook />
        <div className="page-wrapper">
          <Header />
          <Switch>
            <Route exact path="/article/:articleTitle" component={Article} />
            <Route exact path="/mentions-legales" component={TermsOfUse} />
            <Route exact path="/page/:pageTitle" component={Page} />
            <Route exact path="/qui-suis-je" component={WhoAmI} />
            <Route exact path="/me-desabonner" component={Unsubscribe} />
            <Route exact path="/abonnement" component={Register} />
            <Route exact path="/resultats" component={Results} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:postTitle" component={BlogPost} />
            <Route exact path="/" component={Home} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default connect(null, { fetchSettings })(Router);
