import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { Helmet } from "react-helmet";
import {
  fetchCrushesOfTheDay,
  fetchCrushesByCoordsGRC,
  fetchCities,
  fetchInspirations,
  fetchCrushesCatering,
  fetchUser,
  fetchAviziTerritory,
  getUserLocation,
} from "../actions";
import { slugify, getRandomInt } from "../helpers";
import {
  getClosestCity,
  getCityTerritoryMatch,
  getSelectedDate,
  getUserLocationCustom,
} from "../shared";
import SubHeader from "../components/SubHeader";
import SearchBox from "../components/SearchBox";
import Crush from "../components/Crush";
import Loader from "../components/Loader";
import Slider from "../components/Slider";
import FlatBox from "../components/FlatBox";
import Banner from "../components/Banner";
import ModalRoot from "../containers/ModalRoot";
import ReactPixel from "react-facebook-pixel";
import Translations from "../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].home;
const userLocation = getUserLocationCustom();
// console.log(userLocation);
class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: "",
      selectedCity: window.localStorage.getItem("selectedCity") || Texts.ou,
      selectedInspirationIndex: -1,
      selectedUser: window.localStorage.getItem("firstName") || "",
      selectedAviziTerritory: "",
      userLocation: userLocation ? userLocation : "",
    };
  }

  componentDidMount() {
    // Facebook pixel
    ReactPixel.pageView();

    // Get params from URL
    const userLocation = getUserLocationCustom();
    if (userLocation !== undefined) {
      const latitude = userLocation[0];
      const longitude = userLocation[1];
      this.setState({
        userLocation: {
          latitude,
          longitude,
        },
      });
    }

    const qParam = this.getURLParam();
    // Get user geolocation
    if (
      isEmpty(this.props.userLocation) &&
      this.state.userLocation === undefined
    ) {
      this.props.getUserLocation((userLocation) => {
        const { latitude, longitude } = userLocation;
        this.setState({
          userLocation: {
            latitude,
            longitude,
          },
        });
      });
    }
    if (this.state.userLocation !== undefined) {
      const latitude = this.state.userLocation[0];
      const longitude = this.state.userLocation[1];
      this.setState({
        userLocation: {
          latitude,
          longitude,
        },
      });
    }

    // Get the date from URL params and update the searchbox text
    if (qParam.jour) {
      const selectedDate = getSelectedDate(qParam.jour);
      this.setState({ selectedDate });
    } else {
      this.setState({ selectedDate: "" });
    }

    // Fetch inspirations
    if (!this.props.inspirations.length) {
      this.props.fetchInspirations((inspirations) => {
        // Get the inspiration param from URL and update state
        if (qParam.inspiration) {
          this.setSelectedInspirationIndex(qParam.inspiration, inspirations);
        }
      });
    } else {
      // Update inspiration state when we come from another page
      if (qParam.inspiration) {
        this.setSelectedInspirationIndex(
          qParam.inspiration,
          this.props.inspirations
        );
      }
    }

    // Fetch the crushes of the day on the home page
    if (!this.props.crushesOfTheDay.length) {
      this.fetchCrushes();
    }

    // Fetch the crushes of the day on the home page
    if (!this.props.crushesHopla.length && !qParam.territoire) {
      this.fetchCrushes(true);
    }

    // Fetch the catering crushes to get the different catering categories
    if (!this.props.crushesCatering.length) {
      this.props.fetchCrushesCatering();
    }

    // Get the user from URL params
    if (qParam.id) {
      this.updateSelectedUser(qParam.id);
    }

    // Get the Avizi territory from URL params
    if (qParam["id-campaign-avizi"]) {
      this.updateAviziTerritory(qParam["id-campaign-avizi"]);
    }
  }

  getURLParam() {
    const qString = this.props.location.search;
    const queryObj = qs.parse(qString, { ignoreQueryPrefix: true });
    return queryObj;
  }

  setSelectedInspirationIndex(inspirationParam, inspirations) {
    const selectedInspirationIndex = inspirations.findIndex(
      (inspiration) => inspiration.slug === inspirationParam
    );

    this.setState({ selectedInspirationIndex });
  }

  updateSelectedUser(userParam) {
    var selectedUser;
    if (isEmpty(this.props.user)) {
      this.props.fetchUser(userParam, (user) => {
        selectedUser = user.prenom;
        this.setState({ selectedUser });
      });
    } else {
      selectedUser = this.props.user.prenom;
      this.setState({ selectedUser });
    }
  }

  updateAviziTerritory(aviziParam) {
    var selectedAviziTerritory;
    if (isEmpty(this.props.aviziTerritory)) {
      this.props.fetchAviziTerritory(aviziParam, (aviziTerritory) => {
        selectedAviziTerritory = aviziTerritory.Territoire[0];
        this.setState({ selectedAviziTerritory });
      });
    } else {
      selectedAviziTerritory = this.props.aviziTerritory.Territoire[0];
      this.setState({ selectedAviziTerritory });
    }
  }

  fetchCrushes(hopla) {
    // Get slugified territory from URL
    const qParam = this.getURLParam();
    const todayFormatted = moment().format("DD/MM/YYYY");
    const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");

    this.props.fetchCities((cities) => {
      var territoryName, matchingCity, GRCTodayOne, GRCTodayTwoThree;
      // Get the territory from URL id-campaign-avizi param and find a matching city
      if (qParam["id-campaign-avizi"] && qParam["date-routage-avizi"]) {
        if (!this.state.selectedAviziTerritory) return null;
        const dateRoutage = moment
          .unix(qParam["date-routage-avizi"])
          .format("DD/MM/YYYY");
        const dateLendemain = moment
          .unix(qParam["date-routage-avizi"])
          .add(1, "days")
          .format("DD/MM/YYYY");
        territoryName = slugify(this.state.selectedAviziTerritory);
        matchingCity = getCityTerritoryMatch(cities, territoryName);
        // Add territory from campaign
        var searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set("territoire", territoryName);
        this.props.history.push({
          search: "?" + searchParams.toString(),
        });
        if (!matchingCity) return null;
        GRCTodayOne = matchingCity.metas._GRC_Quotidien_fiche_1[0];
        GRCTodayTwoThree = matchingCity.metas._GRC_Quotidien_fiche_2_et_3[0];
        // If we have a territory passed in the URL we fetch by GRC Criteria
        // console.log(
        //   "We have an id-campaign-avizi param in URL ==> Fetch with GRC Criteria"
        // );

        this.props.fetchCrushesOfTheDay(
          3,
          GRCTodayOne,
          GRCTodayTwoThree,
          dateLendemain,
          dateRoutage
        );
      }
      // Get the territory from URL params and find a matching city
      else if (qParam.territoire && qParam.territoire !== "autour-de-moi") {
        territoryName = qParam.territoire;
        matchingCity = getCityTerritoryMatch(cities, territoryName);
        GRCTodayOne = matchingCity.metas._GRC_Quotidien_fiche_1[0];
        GRCTodayTwoThree = matchingCity.metas._GRC_Quotidien_fiche_2_et_3[0];

        // If we have a territory passed in the URL we fetch by GRC Criteria
        // console.log(
        //   "We have a territory param in URL ==> Fetch with GRC Criteria"
        // );

        this.props.fetchCrushesOfTheDay(
          3,
          GRCTodayOne,
          GRCTodayTwoThree,
          todayFormatted,
          yesterday
        );
      } else {
        // Otherwise we try to fetch according to the user geolocation
        hopla
          ? this.fetchWithCoords(cities, true)
          : this.fetchWithCoords(cities, false);
      }
    });
  }

  fetchWithCoords = (cities, hopla) => {
    const that = this;
    const todayFormatted = moment().format("DD/MM/YYYY");
    const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          // console.log(
          //   "No territory param in URL and user allowed geolocation ==> Find territory with coordinates"
          // );

          const { latitude, longitude } =
            that.state.userLocation !== undefined &&
            that.state.userLocation.latitude !== undefined
              ? that.state.userLocation
              : pos.coords;
          const myPos = { latitude, longitude };

          that.setState({
            userLocation: {
              latitude,
              longitude,
            },
          });
          const closestCity = getClosestCity(myPos, cities);
          const GRCTodayOne = closestCity.metas._GRC_Quotidien_fiche_1[0];
          const GRCTodayTwoThree =
            closestCity.metas._GRC_Quotidien_fiche_2_et_3[0];
          // console.log(
          //   "Closest city from user: ",
          //   closestCity.commune + " => " + closestCity.metas._Territoire
          // );
          if (!that.state.closestCity) {
            that.setState({
              closestCity: closestCity.commune,
            });
          }
          if (hopla) {
            // console.log("hopla!");
            // nbCrushes = 12,
            // lat = "48,361447333333",
            // lon = "7,3452958666667",
            // rayon = 5,
            // critGRC = 213000080,
            // critGRC_2 = 213000081,
            // cateringID,
            // inspirationID,
            // dateFormatted,
            // yesterday,
            // commune
            that.props.fetchCrushesByCoordsGRC(
              3,
              latitude,
              longitude,
              5,
              GRCTodayOne,
              GRCTodayTwoThree,
              "1901496",
              "",
              todayFormatted,
              yesterday,
              closestCity.commune,
              true
            );
          } else {
            that.props.fetchCrushesOfTheDay(
              3,
              GRCTodayOne,
              GRCTodayTwoThree,
              todayFormatted,
              yesterday,
              latitude,
              longitude
            );
          }
        },
        function (err) {
          // console.log(err);
          // If the user does not allow his geolocation and we don't have a territorry in the URL
          // We fetch the crushes on the territory of Sélestat
          if (that.state.crushHome !== undefined && that.state.crushHome) {
            return;
          }
          if (!that.state.crushHome) {
            that.setState({
              crushHome: true,
            });
          }
          const randomCity = getRandomInt(0, cities.length);
          const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
          const GRCTodayOne =
            cities[randomCity].metas._GRC_Quotidien_fiche_1[0];
          const GRCTodayTwoThree =
            cities[randomCity].metas._GRC_Quotidien_fiche_2_et_3[0];
          that.props.fetchCrushesOfTheDay(
            3,
            GRCTodayOne,
            GRCTodayTwoThree,
            todayFormatted,
            yesterday
          );
          // console.log(
          //   "No territory param in URL and user did not allow geolocation ==> Fetch with RANDOM city : " +
          //     cities[randomCity].commune +
          //     " (" +
          //     cities[randomCity].metas._Territoire +
          //     ") GRC Criteria"
          // );
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  };

  renderCrushesOfTheDay() {
    const crushesOfTheDay = this.props.crushesOfTheDay;

    if (!crushesOfTheDay.length) {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <div className="crushes__list crushes__list--three">
          {crushesOfTheDay.map(this.renderCrush, this)}
        </div>
      );
    }
  }

  renderHopla() {
    const crushesHopla = this.props.crushesHopla.products;
    if (crushesHopla === undefined) {
      return;
    }
    // console.log(this.props.crushesHopla);
    if (!crushesHopla.length) {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <div className="crushes__list crushes__list--three">
          {crushesHopla.map(this.renderCrush, this)}
        </div>
      );
    }
  }
  renderCrushesOfTheDayFranceBleu() {
    const crushesOfTheDayFranceBleu = this.props.crushesOfTheDayFranceBleu;
    if (!crushesOfTheDayFranceBleu.length) {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <div className="crushes__list crushes__list--two">
          {crushesOfTheDayFranceBleu.map(this.renderCrush, this)}
        </div>
      );
    }
  }

  renderCateringFilterBox() {
    let params = this.props.location;
    const names = [
      "Saveurs d'Alsace",
      "Tables d'exception",
      "Vite et bon",
      "D’autres envies",
    ];
    return names.map((name) => {
      return (
        <FlatBox
          key={slugify(name)}
          type="eat"
          isActive={false}
          href={slugify(name)}
          category={name}
          params={params}
        />
      );
    });
  }

  renderCrush(crush, index) {
    const id = crush.id;
    const title = crush.nom;
    const location =
      crush.coordonnees !== undefined
        ? crush.coordonnees.libelle_commune
        : null;

    if (crush.criteres !== undefined && crush.criteres.length) {
      var criteriaImgURL = crush.criteres.find(
        (criteria) => criteria.id === 1900421
      );
      var criteriaImgALT = crush.criteres.find(
        (criteria) => criteria.id === 1900480
      );
      var criteriaDesc = crush.criteres.find(
        (criteria) => criteria.id === 1901440
      );
    }

    const imgURL = criteriaImgURL
      ? `https://${criteriaImgURL.valeur
          .replace("https://", "")
          .replace("http://", "")}`
      : null;

    const imgTitle = criteriaImgALT ? criteriaImgALT.valeur : null;

    const desc = criteriaDesc ? criteriaDesc.valeur : null;

    const link = title
      ? `/article/${slugify(title)}-${id}${this.props.location.search}`
      : `/article/${id}`;
    const type = index === 0 ? "big-on-mobile" : "small";
    const latitude = crush.latitude !== undefined ? crush.latitude : null;
    const longitude = crush.longitude !== undefined ? crush.longitude : null;

    return (
      <Crush
        key={id}
        type={type}
        title={title}
        link={link}
        location={location}
        imgURL={imgURL}
        imgTitle={imgTitle}
        desc={desc}
        latitude={latitude}
        longitude={longitude}
        userPos={this.state.userLocation}
        closestCity={this.state.closestCity}
      />
    );
  }

  render() {
    const subHeaderTitle = `${Texts.bonjour} ${this.state.selectedUser}`;
    const currentUrl = `${window.location.protocol}//${window.location.host}${this.props.location.pathname}`;

    return (
      <main className="main main--home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Liesel - {Texts.les_coups_de_coeur}</title>
          <link
            rel="canonical"
            href={`${
              currentUrl && this.props.location.pathname ? currentUrl : ""
            }`}
          />
        </Helmet>
        <SubHeader
          hasBorder={false}
          title={subHeaderTitle}
          body={Texts.je_suis}
          closestCity={this.state.closestCity}
        />
        <div className="searchboxes">
          <div className="searchboxes__inner">
            <SearchBox
              type="date"
              isActive={false}
              selectedDate={this.state.selectedDate}
            />
            <SearchBox
              type="location"
              isActive={false}
              selectedCity={this.state.selectedCity}
            />
            <ModalRoot />
          </div>
        </div>

        {this.props.inspirations.length > 0 && (
          <div className="tns tns--filter" id="inspirations">
            <Slider
              type="filter"
              data={this.props.inspirations}
              handleOnClick={this.setSelectedInspirationIndex}
              selectedIndex={this.state.selectedInspirationIndex}
            />
          </div>
        )}

        <div className="crushes">
          <div className="crushes__inner">
            <h2 className="crushes__title">{Texts.les_coups_de_coeur}</h2>
            {this.renderCrushesOfTheDay()}
          </div>
        </div>
        <div className="hopla">
          <div className="hopla__inner">
            <Banner />
          </div>
        </div>
        <div className="boxfilter">
          <div className="boxfilter__boxes">
            {this.renderCateringFilterBox()}
          </div>
          {this.renderHopla()}
        </div>
      </main>
    );
  }
}

function mapStateToProps({
  crushesOfTheDay,
  crushesHopla,
  crushesOfTheDayFranceBleu,
  cities,
  inspirations,
  crushesCatering,
  user,
  aviziTerritory,
  userLocation,
  closestCity,
}) {
  return {
    crushesOfTheDay,
    crushesHopla,
    crushesOfTheDayFranceBleu,
    cities,
    inspirations,
    crushesCatering,
    user,
    aviziTerritory,
    userLocation,
    closestCity,
  };
}

export default connect(mapStateToProps, {
  fetchCrushesOfTheDay,
  fetchCrushesByCoordsGRC,
  fetchCities,
  fetchInspirations,
  fetchCrushesCatering,
  fetchUser,
  fetchAviziTerritory,
  getUserLocation,
})(Home);
