import React from 'react';
import Translations from "../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].footer;
const Loader = props => {
  if (props.loading) {
    return (
      <div className="loader is-spinning" id="viewContent">
        <div className="loader__inner">
          <span className="loader__ico" />
          <span className="loader__txt">{Texts.chargement}</span>
        </div>
      </div>
    );
  } else {
    return (
      <button onClick={props.handleClick} className="loader loader--btn">
        <div className="loader__inner">
          <span className="loader__ico" />
          <span className="loader__txt">{props.text}</span>
        </div>
      </button>
    );
  }
};

export default Loader;
