import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { hideModal } from "../../actions";
import Modal from "../../components/Modal";
import Register from "../../routes/Register";

// title, afterClose, hideModal

class ModalSubscribe extends Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {}

  onClose() {
    this.props.hideModal();
    window.localStorage.setItem("modalSubscribeStop", "true");
    if (this.props.afterClose) {
      this.props.afterClose();
    }
  }

  onSubmit() {
    this.props.hideModal();

    if (this.props.afterClose) {
      this.props.afterClose();
    }
  }

  render() {
    return (
      <Modal
        title={this.props.title}
        position={this.props.position}
        onClose={this.onClose}
      >
        <div className="modalSubscribe">
          <Register />
        </div>
      </Modal>
    );
  }
}

ModalSubscribe.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(
  null,
  { hideModal }
)(withRouter(ModalSubscribe));
