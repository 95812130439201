import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import qs from "qs";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import "moment-timezone";
import { slugify } from "../helpers";

import { fetchAllFooters, fetchSelectedFooter } from "../actions";
import Loader from "../components/Loader";
import defaultFooterImg from "../../img/LIESEL_web-default-footer.png";
import logoAlsace from "../../img/logo-alsace.svg";
import logoAvizersAlsace from "../../img/logo-avizers-alsace.jpg";
import envelopeIcon from "../../img/ico-envelope.png";
import Translations from "../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].footer;
const knownUser = window.localStorage.getItem("firstName");
const FooterPrimary = (props) => {
  const {
    settings: { images },
  } = props;

  // Image en fonction de la langue
  let imgFooter = defaultFooterImg;
  if (images && images[1]) {
    switch (lang) {
      case "EN":
        imgFooter = images[1].footer.en;
        break;
      case "DE":
        imgFooter = images[1].footer.de;
        break;
      default:
        imgFooter = images[1].footer.fr;
        break;
    }
  }

  return (
    <footer className="footer" id="footer">
      <div className="footer__inner">
        <p className="footer__tagline">
          <span className="footer__tagline-top">
            {Texts.pour_profiter_chaque_jour}
          </span>
          <br />
          <span className="footer__tagline-bottom">
            <span>{Texts.du_meilleur_de_l}</span>
            <img
              src={logoAlsace}
              alt="Alsace"
              className="footer__tagline-img"
            />
          </span>
        </p>
        <p className="footer__bottom">
          <img src={imgFooter} alt="Alsace" className="footer-img" />
        </p>
        <div className="links">
          <a
            href={`/page/${slugify(Texts.mentions_legales)}`}
            title={Texts.mentions_legales}
            className=""
          >
            {Texts.mentions_legales}
          </a>
          <a
            href={`/page/${slugify(Texts.politique_de_protection_des_donnees)}`}
            title={Texts.politique_de_protection_des_donnees}
            className=""
          >
            {Texts.politique_de_protection_des_donnees}
          </a>
          <a
            href={`/page/${slugify(Texts.accord_de_responsabilite_conjointe)}`}
            title={Texts.accord_de_responsabilite_conjointe}
            className=""
          >
            {Texts.accord_de_responsabilite_conjointe}
          </a>
          <a
            href={`/page/${slugify(Texts.politique_des_cookies)}`}
            title={Texts.politique_des_cookies}
            className=""
          >
            {Texts.politique_des_cookies}
          </a>
        </div>
      </div>
      {props.hasBtnMessage && !knownUser && (
        <div>
          <Link
            to={{ pathname: "/abonnement", search: props.location.search }}
            title="abonnement"
            className="footer__btn-message"
            style={{
              backgroundImage: `url(${envelopeIcon})`,
            }}
          />
          <Link
            to={{ pathname: "/abonnement", search: props.location.search }}
            title="abonnement"
            className={`footer__btn-message-text ${props.classBtn}`}
          >
            {Texts.abonnezvous}
          </Link>
        </div>
      )}
    </footer>
  );
};

const FooterSecondary = (props) => {
  var title = props.title;
  var isOpen = props.isOpen;
  if (props.title === "Alsace Destination Tourisme") {
    title = "Alsace";
  }
  return (
    <footer className="footer secondary">
      <div className="experts">
        <div className="experts__inner">
          <h2 className="experts__title">{title}</h2>
          <div className="experts__contact">
            {isOpen && (
              <a
                href={`tel:${props.telephoneOne}`}
                title="Téléphone"
                className="experts__contact-link experts__contact-link--tel"
              >
                <span>{props.telephoneOne}</span>
              </a>
            )}

            <a
              href={`mailto:${props.emailOne}?cc=contact@liesel.alsace&subject=${Texts.demande_informations}`}
              title="Email"
              className="experts__contact-link experts__contact-link--email"
            >
              <span>{props.emailOne}</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

const FooterPrimaryWithRouter = withRouter(FooterPrimary);
const FooterSecondaryWithRouter = withRouter(FooterSecondary);

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRefererIdlei: null,
      selectedFooterWP: {},
      classBtn: "",
    };
  }

  componentDidMount() {
    // Fetch all the footers
    window.addEventListener("scroll", this.handleScroll);

    if (!this.props.allFooters.length) {
      this.props.fetchAllFooters((allFooters) => {
        this.getSelectedFooterLEI(allFooters);
      });
    } else {
      this.getSelectedFooterLEI(this.props.allFooters);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this);
  }

  componentDidUpdate() {
    if (
      !this.props.allFooters.length &&
      this.getURLParam()["id-campaign-avizi"]
    ) {
      this.props.fetchAllFooters((allFooters) => {
        this.getSelectedFooterLEI(allFooters);
      });
    }
  }
  handleScroll = () => {
    this.setState({ classBtn: "itemHover" });
    setTimeout(() => {
      this.setState({ classBtn: "" });
    }, 2000);
  };

  getURLParam() {
    const qString = this.props.location.search;
    const queryObj = qs.parse(qString, { ignoreQueryPrefix: true });

    return queryObj;
  }

  getMatchingFooterFromArray(footers) {
    // Get params from URL and get the matching footer accordingly
    const qParam = this.getURLParam();

    var matchingFooterWP = qParam.territoire
      ? footers.find((footer) => footer.slug === qParam.territoire)
      : footers.find((footer) => footer.id === 50);

    // offres en random : footer Alsace
    if (qParam.territoire === "") {
      matchingFooterWP = footers.find((footer) => footer.id === 50);
    }
    return matchingFooterWP;
  }

  getSelectedFooterLEI(footers) {
    const selectedFooterWP = this.getMatchingFooterFromArray(footers);
    if (!selectedFooterWP) {
      return null;
    }
    this.setState({ selectedFooterWP });
    const selectedRefererIdlei = selectedFooterWP.meta.idlei;
    this.setState({ selectedRefererIdlei }, () => {
      this.props.fetchSelectedFooter(this.state.selectedRefererIdlei);
    });
  }

  hasBtnMessage() {
    const qParam = this.getURLParam();
    var hasBtnMessage = qParam["id-campaign-avizi"] ? false : true;

    return hasBtnMessage;
  }

  isOpen(idlei) {
    let open;
    if (idlei === undefined || open !== undefined) {
      return;
    }
    const startTime = moment().tz("Europe/Paris").format("HH:mm");
    const today = moment().locale("fr").format("dddd");

    if (
      this.props.selectedFooter.horaires === undefined ||
      this.props.selectedFooter.horaires.length === 0
    ) {
      return null;
    }

    for (
      var nbTranches = 0;
      nbTranches < this.props.selectedFooter.horaires.length;
      nbTranches++
    ) {
      var currentSchedules =
        this.props.selectedFooter.horaires[nbTranches].heures;
      for (var i = 0; i < currentSchedules.length; i++) {
        open =
          currentSchedules[i][today] === "O" &&
          currentSchedules[i] !== undefined &&
          startTime >= currentSchedules[i].heure_debut &&
          startTime <= currentSchedules[i].heure_fin
            ? true
            : false;

        if (open !== undefined) {
          return open;
        } else {
          return false;
        }
      }
    }
  }
  render() {
    const { settings } = this.props;
    const selectedFooterLEI = this.props.selectedFooter;
    const selectedFooterWP = this.state.selectedFooterWP;
    let searchParams = new URLSearchParams(this.props.location.search);
    if (
      (this.props.selectedFooter.id === 201000123 ||
        this.state.selectedRefererIdlei === 201000123) &&
      searchParams.has("territoire") &&
      searchParams.has("id-campaign-avizi")
    ) {
      window.location.reload();
    }
    const classBtn =
      this.state.classBtn !== undefined ? this.state.classBtn : null;
    const title =
      selectedFooterLEI.nom !== undefined ? selectedFooterLEI.nom : null;
    const street =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.libelle_voie !== undefined
        ? selectedFooterLEI.coordonnees.libelle_voie
        : null;
    const postCode =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.code_postal !== undefined
        ? selectedFooterLEI.coordonnees.code_postal
        : null;
    const city =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.libelle_commune !== undefined
        ? selectedFooterLEI.coordonnees.libelle_commune
        : null;
    const telephoneOne =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.telephone1 !== undefined
        ? selectedFooterLEI.coordonnees.telephone1.replace("0", "+33 ")
        : null;
    const fax =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.fax !== undefined
        ? selectedFooterLEI.coordonnees.fax
        : null;
    const emailOne =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.email1 !== undefined
        ? selectedFooterLEI.coordonnees.email1
        : null;
    const urlOne =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.url1 !== undefined
        ? selectedFooterLEI.coordonnees.url1
        : null;
    const country =
      selectedFooterLEI.coordonnees !== undefined &&
      selectedFooterLEI.coordonnees.pays !== undefined
        ? selectedFooterLEI.coordonnees.pays
        : null;
    const logoURL =
      !isEmpty(selectedFooterWP) &&
      selectedFooterWP._embedded !== undefined &&
      selectedFooterWP._embedded["wp:featuredmedia"] !== undefined &&
      selectedFooterWP._embedded["wp:featuredmedia"][0].media_details !==
        undefined &&
      selectedFooterWP._embedded["wp:featuredmedia"][0].media_details.sizes !==
        undefined &&
      selectedFooterWP._embedded["wp:featuredmedia"][0].media_details.sizes
        .medium !== undefined &&
      selectedFooterWP._embedded["wp:featuredmedia"][0].media_details.sizes
        .medium.source_url !== undefined
        ? selectedFooterWP._embedded["wp:featuredmedia"][0].media_details.sizes
            .medium.source_url
        : null;
    const logoALT =
      !isEmpty(selectedFooterWP) &&
      selectedFooterWP._embedded !== undefined &&
      selectedFooterWP._embedded["wp:featuredmedia"] !== undefined &&
      selectedFooterWP._embedded["wp:featuredmedia"][0].alt_text !== undefined
        ? selectedFooterWP._embedded["wp:featuredmedia"][0].alt_text
        : null;

    const footerEl = (
      <FooterPrimaryWithRouter
        title={title}
        street={street}
        postCode={postCode}
        city={city}
        telephoneOne={telephoneOne}
        fax={fax}
        emailOne={emailOne}
        urlOne={urlOne}
        logoURL={logoURL}
        logoALT={logoALT}
        country={country}
        hasBtnMessage={this.hasBtnMessage()}
        classBtn={classBtn}
        settings={settings}
      />
    );
    const footerElOt = (
      <FooterSecondaryWithRouter
        title={title}
        street={street}
        postCode={postCode}
        city={city}
        telephoneOne={telephoneOne}
        fax={fax}
        emailOne={emailOne}
        urlOne={urlOne}
        logoURL={logoURL}
        logoALT={logoALT}
        country={country}
        hasBtnMessage={this.hasBtnMessage()}
        isOpen={this.isOpen(selectedFooterLEI.id)}
      />
    );

    if (isEmpty(selectedFooterLEI)) {
      return <Loader loading={true} text={Texts.chargement} />;
    } else {
      return (
        <React.Fragment>
          {footerElOt} {footerEl}{" "}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps({ allFooters, selectedFooter, settings }) {
  return { allFooters, selectedFooter, settings };
}

export default connect(mapStateToProps, {
  fetchAllFooters,
  fetchSelectedFooter,
})(withRouter(Footer));
