import React, { Component } from "react";
import { Link } from "react-router-dom";
import ButtonChecked from "../components/ButtonChecked";
const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
class FlatBox extends Component {
  renderBoxEat() {
    var searchParams = new URLSearchParams(this.props.params.search);
    const currentType = searchParams.get("manger");
    searchParams.set("manger", this.props.href);
    searchParams.delete("inspiration");
    let vers = window.location.pathname.includes("article")
      ? "../resultats?" + searchParams.toString()
      : "resultats?" + searchParams.toString();
    let nom_categorie;
    let isSelected = "";
    if (currentType === this.props.href) {
      isSelected = true;
    }
    switch (this.props.category) {
      case "Vite et bon":
        if (lang === "EN") {
          nom_categorie = "Fast and good";
        } else if (lang === "DE") {
          nom_categorie = "Schnell und gut";
        } else {
          nom_categorie = "Vite et bon";
        }
        break;
      case "Tables d'exception":
        if (lang === "EN") {
          nom_categorie = "Exceptional restaurants";
        } else if (lang === "DE") {
          nom_categorie = "Spitzenrestaurants";
        } else {
          nom_categorie = "Tables d’exception";
        }
        break;
      case "Saveurs d'Alsace":
        if (lang === "EN") {
          nom_categorie = "Alsace flavours";
        } else if (lang === "DE") {
          nom_categorie = "Elsässer Genüsse";
        } else {
          nom_categorie = "Saveurs d'Alsace";
        }
        break;
      case "D’autres envies":
        if (lang === "EN") {
          nom_categorie = "Other cravings";
        } else if (lang === "DE") {
          nom_categorie = "Appetit auf mehr";
        } else {
          nom_categorie = "D'autres envies";
        }
        break;
      default:
        break;
    }
    return (
      <div className="flat-box__inner">
        <Link to={vers} title="" className="flat-box__content">
          <h5 className="flat-box__title">{nom_categorie}</h5>
          <ButtonChecked isSelected={isSelected} />
        </Link>
      </div>
    );
  }

  render() {
    const type = this.props.type;
    const classesBox = this.props.isActive ? "flat-box is-active" : "flat-box";

    if (type === "eat") {
      return <div className={classesBox}>{this.renderBoxEat()}</div>;
    }
  }
}

export default FlatBox;
