/**
 * add gtag script and init gtag
 * 
 * @param {string} trackingId 
 * @param {object} additionalConfigInfo 
 * @param {boolean} consentManagement / to deal with js tag only if consent manager doesn't deal with it first 
 */
const install = (trackingId, additionalConfigInfo = {}, consentManagement = false) => {
  const scriptId = `ga-gtag-${trackingId}`;

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  window.dataLayer = window.dataLayer || [];

  if (! consentManagement) {
    gtag("js", new Date());
  }

  gtag("config", trackingId, additionalConfigInfo);
};

/**
 * push arguments to dataLayer
 */
const gtag = function () {
  if (typeof window !== undefined && window.dataLayer !== undefined) {
    // Can't use arrow func + destructuring as Google expects
    // arguments objects in dataLayer (not an array of arguments).
    window.dataLayer.push(arguments);
  }
};

const Gtag = {
  install,
  gtag,
};

export default Gtag;
