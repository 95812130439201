import React from 'react';

const Day = props => {
  const classes = props.isSelected ? 'day is-active' : 'day';

  return (
    <li className={classes} onClick={props.onDayToggle}>
      <div className="day__inner">
        <span className="day__number">{props.number}</span>
        <span className="day__month">{props.month}</span>
      </div>
    </li>
  );
};

export default Day;
