import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Matomo from "../tracking/matomo";
import {
  MATOMO_SITE_ID,
  MATOMO_TRACKER_URL,
  MATOMO_SRC_URL,
  MATOMO_CONTAINER_SRC_URL,
} from "../constants/Matomo";

const UseMatomo = (props) => {
  const location = useLocation();

  useEffect(() => {
    Matomo.init(MATOMO_SITE_ID, MATOMO_TRACKER_URL, MATOMO_SRC_URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Matomo.tagManager(MATOMO_CONTAINER_SRC_URL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return null;
};

export default UseMatomo;
