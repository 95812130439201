import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import RenderDatePicker from "./RenderDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment";
import City from "./City";
import { API_URL } from "../constants/Api";
import Gtag from "../utils/Gtag";
import ReactPixel from "react-facebook-pixel";
import Translations from "../lang/translations.json";
import { ReCaptcha } from "react-recaptcha-v3";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].form;
class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      activeCityIndex: null,
      citiesByDistance: [],
      cities: [],
      citiesFiltered: [],
      ville: "",
      prenom: "",
      saved: false,
      recaptchaToken: "",
      disabled: false,
      pays: [],
    };

    // Facebook pixel
    ReactPixel.pageView();

    this.onSubmit = this.onSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    const { cities } = this.props;
    this.setState({ cities });

    // Autoriser la réinscription au bout de 15 jours
    const dateExpirationInscription = window.localStorage.getItem(
      "dateExpirationInscription"
    );
    if (dateExpirationInscription) {
      // console.log(
      //   "inscription expire dans " +
      //     moment(dateExpirationInscription, ["DDMMYY"]).diff(moment(), "days") +
      //     " j"
      // );
    }
    if (
      moment(dateExpirationInscription, ["DDMMYY"]).diff(moment(), "days") <= 0
    ) {
      window.localStorage.removeItem("dateExpirationInscription");
      window.localStorage.removeItem("contactAvizi");
      window.localStorage.removeItem("contactEmail");
      window.localStorage.removeItem("firstName");
      window.localStorage.removeItem("modalSubscribeStop");
    }
    // console.log(window.grecaptcha, "captcha object")
    fetch('/avizi-pays.json')
    .then((response) => response.json())
    .then((data) => {
      this.setState({ pays: data.results });
    })
    .catch((error) => {
      // Gérer les erreurs ici
      console.log(error);
    });

  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let { cities, startDate, endDate } = this.props;
      cities = cities.filter((city) => !city.commune.match("Autour de"));
      this.setState({ cities, startDate, endDate });
    }
  }
  onInputChange(e) {
    this.setState({
      activeCityIndex: null,
      term: e.target.value,
    });
    this.setState({ ville: e.target.value });

    const filteredCities = this.state.cities
      .filter((city) =>
        city.commune.toLowerCase().includes(e.target.value.toLowerCase())
      )
      .slice(0, 8);
    this.setState({ citiesFiltered: filteredCities });
  }

  onCityToggle(cityName) {
    const { selectCity } = this.props;
    this.setState({ term: cityName });
    selectCity(cityName);
    this.hideAutocomplete();
  }

  renderCities() {
    const classes = this.state.activeCityIndex ? "city is-active" : "city";
    const activeIndex = this.state.activeCityIndex;
    const cities = this.state.citiesFiltered;
    if (cities.length > 0) {
      return cities
        .map((city, index) => {
          return (
            <City
              key={index}
              onCityToggle={this.onCityToggle.bind(this, city.commune)}
              isSelected={index === activeIndex}
              name={city.commune.toLowerCase()}
              distance={city.distance}
              classes={classes}
              departement={
                city.metas._Insee[0] !== ""
                  ? city.metas._Insee[0].substring(0, 2)
                  : ""
              }
            />
          );
        })
        .slice(0, 8);
    }
  }
  pushEvent() {
    const eventName = "Inscription avizi";
    const eventParameters = {
      category: eventName,
      action: "inscriptionAvizi",
      label: "Inscription avizi",
    };

    Gtag.gtag("event", eventName, eventParameters);
  }
  renderTextField(field) {
    return (
      <div className="form__row">
        {!field.labelTxt && (
          <label className="form__label" htmlFor={field.labelFor}>
            <input
              type={field.type}
              id={field.labelFor}
              placeholder={field.placeholder}
              className="form__input"
              {...field.input}
            />
            {field.meta.touched && (
              <span className="form__error">{field.meta.error}</span>
            )}
          </label>
        )}
        {field.labelTxt && (
          <div>
            <label
              className="form__label form__label--outside"
              htmlFor={field.labelFor}
            >
              {field.labelTxt}
            </label>
            <input
              type={field.type}
              id={field.labelFor}
              placeholder={field.placeholder}
              className="form__input"
              {...field.input}
            />
            {field.meta.touched && (
              <span className="form__error">{field.meta.error}</span>
            )}
          </div>
        )}
      </div>
    );
  }
  renderPaysField(field) {
    return (
      <div className="form__row">
        <select
          id={field.labelFor} 
          className="form__input"
          {...field.input}
          >
          <option value="">{field.placeholder}</option>

          {field.pays.map((item, i) => {     
            return (<option key={item.id} value={item.id}>{item.name}</option>) 
          })}

        </select>
        {field.meta.touched && (
          <span className="form__error">{field.meta.error}</span>
        )}

      </div>
    );
  }


  onSubmit(values) {
    if (this.state.recaptchaToken) {
      // console.log(this.state.recaptchaToken, "recaptcha token");
    } else {
      // console.log(this.state, "recaptcha token invalide");
      return null;
    }
    this.setState({ disabled: true });
    // console.log(values);
    const me = this;
    const { firstName, lastName, pays, email, mobile, location, dateStart, dateEnd } = values;

    var myDateStart = dateStart.split("/");
    var myDateEnd = dateEnd.split("/");
    myDateStart = myDateStart[2] + "-" + myDateStart[1] + "-" + myDateStart[0];
    myDateEnd = myDateEnd[2] + "-" + myDateEnd[1] + "-" + myDateEnd[0];
    axios
      .post(
        `${API_URL}/liesel/v1/addcontact/${firstName}/${lastName}/${pays}/${email}/${mobile}/${location}/${myDateStart}/${myDateEnd}`
      )
      .then(function (response) {
        me.pushEvent();
        me.setState({ saved: true, prenom: firstName });
        window.localStorage.setItem("firstName", firstName);
        window.localStorage.setItem(
          "contactAvizi",
          response.data.infos.contact_id
        );

        window.localStorage.setItem("contactEmail", email);
        // console.log(
        //   "contact_id inscription :" + response.data.infos.contact_id
        // );

        let dateLimite = moment().add(15, "day");
        dateLimite = dateLimite.format("DDMMYY");
        window.localStorage.setItem("dateExpirationInscription", dateLimite);

        const eventName = "Inscription avizi " + response.data.infos.contact_id;
        const eventParameters = {
          category: eventName,
          action: "inscriptionAvizi",
          label: response.data.infos.contact_id,
        };
        Gtag.gtag("event", eventName, eventParameters);

        ReactPixel.trackCustom("inscriptionAvizi", {
          userAvizi: response.data.infos.contact_id,
        });
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  hideAutocomplete() {
    this.setState({ citiesFiltered: [] });
  }

  verifyCallback = (recaptchaToken) => {
    // console.log(recaptchaToken, "recaptcha token");
    this.setState({ recaptchaToken });
  };

  render() {
    const { handleSubmit } = this.props;
    const { saved, prenom } = this.state;
    const knownUser = window.localStorage.getItem("firstName");
    if (saved) {
      return (
        <div className="main--about">
          <div className="generic-content">
            <div className="generic-content__block message_confirmation">
              <h3>
                {Texts.bonjour} {prenom},
              </h3>
              <p>{Texts.vos_informations}</p>
              <p>{Texts.chaque_soir}</p>
              <a href="/" type="submit" className="btn-primary">
                {Texts.retour}
              </a>
            </div>
          </div>
        </div>
      );
    }
    if (knownUser && !saved) {
      return (
        <div className="main--about">
          <div className="generic-content">
            <div className="generic-content__block message_confirmation">
              <h3>
                {Texts.bonjour} {knownUser},
              </h3>
              <p>{Texts.vous_etes_deja}</p>
              <p>{Texts.chaque_soir_lendemain}</p>
              <p>{Texts.surveillez}</p>
              <p>{Texts.vous_ne_les_recevez}</p>
              <a type="submit" className="btn-primary" href={Texts.email_aide}>
                {Texts.contactez_moi}
              </a>
              <p>{Texts.je_ferai}</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <form onSubmit={handleSubmit(this.onSubmit)} className="form">
        <div className="form__inner">
          <Field
            id="dateStart"
            name="dateStart"
            placeholder={moment().locale("fr").format("DD/MM/YYYY")}
            labelTxt={Texts.debut_sejour}
            component={RenderDatePicker}
          />
          <Field
            id="dateEnd"
            name="dateEnd"
            placeholder={moment()
              .add(1, "days")
              .locale("fr")
              .format("DD/MM/YYYY")}
            labelTxt={Texts.fin_sejour}
            dateStart={this.props.dateStart}
            component={RenderDatePicker}
          />
          <Field
            name="location"
            type="location"
            labelFor="location"
            placeholder={Texts.ville}
            labelTxt={Texts.ville_sejour}
            onChange={this.onInputChange}
            component={this.renderTextField}
          />

          {this.state.citiesFiltered && this.state.citiesFiltered.length > 0 && (
            <div className="cities__inner">
              <ul className="cities__list">{this.renderCities()}</ul>
            </div>
          )}
          <div className="formSection">
            <h2>{Texts.vos_coordonnees}</h2>
          </div>
          <Field
            name="lastName"
            type="text"
            labelFor="last-name"
            placeholder={Texts.nom}
            labelTxt={false}
            component={this.renderTextField}
          />
          <Field
            name="firstName"
            type="text"
            labelFor="first-name"
            placeholder={Texts.prenom}
            labelTxt={false}
            component={this.renderTextField}
          />
          <Field
            name="pays"
            type="text"
            labelFor="pays"
            placeholder={Texts.pays}
            labelTxt={false}
            props={this.state}
            component={this.renderPaysField}
          />

          <Field
            name="email"
            type="email"
            labelFor="email"
            placeholder={Texts.email}
            labelTxt={false}
            component={this.renderTextField}
          />
          <Field
            name="mobile"
            type="text"
            labelFor="mobile"
            placeholder={Texts.mobile}
            labelTxt={false}
            component={this.renderTextField}
          />

          {window.grecaptcha ? (
            <ReCaptcha
              sitekey="6LeCguQUAAAAAB-ueXIMSPULisgUU2NJH2ebZum0"
              action="homepage"
              verifyCallback={this.verifyCallback}
            />
          ) : null}
          <div className="form__row form__row--submit">
            <div className="formExpl">
              <p>{Texts.text_je_minscris}</p>
            </div>

            <button
              type="submit"
              className="btn-primary"
              disabled={this.state.disabled}
            >
              {Texts.je_minscris}
            </button>
          </div>
          <div className="form__row recaptcha">
            {Texts.recaptcha_1}{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {Texts.recaptcha_2}
            </a>{" "}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              {Texts.recaptcha_3}
            </a>{" "}
            {Texts.recaptcha_4}
          </div>
        </div>
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};

  if (!values.lastName) {
    errors.lastName = Texts.erreur_nom;
  }

  if (values.lastName !== undefined && values.lastName.length < 3) {
    errors.lastName = Texts.erreur_nom_trois;
  }

  if (!values.firstName) {
    errors.firstName = Texts.erreur_prenom;
  }

  if (!values.pays) {
    errors.pays = Texts.erreur_pays;
  }

  if (!values.email) {
    errors.email = Texts.erreur_email;
  }

  if (!values.dateStart) {
    errors.dateStart = Texts.erreur_date_debut;
  }

  if (!values.dateEnd) {
    errors.dateEnd = Texts.erreur_date_fin;
  }

  if (!values.location) {
    errors.location = Texts.erreur_ville;
  } else {
    if (
      !props.cities.find((ville) => {
        return ville.commune === values.location;
      })
    ) {
      errors.location = Texts.erreur_ville_valide;
    }
  }

  return errors;
}

const selector = formValueSelector("subscribeForm");

Form = reduxForm({
  validate,
  form: "subscribeForm",
})(Form);

export default connect(
  (state) => {
    // can select values individually
    const { dateStart, dateEnd } = selector(state, "dateStart", "dateEnd");
    return {
      dateStart,
      dateEnd,
    };
  },
  { selectCity: (city) => change("subscribeForm", "location", city) }
)(Form);
