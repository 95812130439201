import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import imgPlaceholder from "../../img/img-placeholder-v1.jpg";
import { getDistanceFromCrush } from "../shared";
import Translations from "../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].crush;
const Crush = ({
  type,
  title,
  link,
  location,
  imgURL,
  imgTitle,
  desc,
  latitude,
  longitude,
  distance,
  userPos,
  closestCity
}) => {
  const classes = `crush crush--${type}`;

  if (latitude && longitude && userPos !== undefined && !isEmpty(userPos)) {
    const crushPos = { latitude, longitude };
    var distanceFromCrush = getDistanceFromCrush(crushPos, userPos);
  }
  if (distance !== undefined && distance !== null) {
    distance = distance.replace(",", ".");
  }
  var selectedCity = window.localStorage.getItem("selectedCity")
    ? window.localStorage.getItem("selectedCity").replace("Autour de", "")
    : "";
  var distanceFromLastCrush = selectedCity
    ? Texts.a +
      " " +
      Math.round(distance) +
      " km " +
      Texts.de +
      " " +
      selectedCity.replace("Around ", "").replace("Rund um ","")
    : "";
  if (distance > 0 && distance < 1) {
    distanceFromLastCrush = Texts.proche;
  }
  if (imgTitle !== undefined && imgTitle !== null) {
    imgTitle = "© " + imgTitle.replace("©", "");
  } else {
    imgTitle = "";
  }

  const img = imgURL ? (
    <img src={imgURL} alt={imgTitle} title={imgTitle} className="crush__img" />
  ) : (
    <img src={imgPlaceholder} alt="" className="crush__img" />
  );
  return (
    <article className={classes}>
      <Link to={link} className="crush__link">
        <div className="crush__img-wrapper">
          {img}
          <span className="crush__img-copyrights hide-desktop">{imgTitle}</span>
        </div>
        <div className="crush__content">
          <div>
            <h3 className="crush__title">{title}</h3>
            <p className="crush__location">
              {location}
              {distanceFromCrush > 0 && selectedCity
                ? ` (${Texts.a} ${distanceFromCrush} ${Texts.km_de_vous_et} ${distanceFromLastCrush})`
                : ""}
              {distanceFromCrush > 0 && !selectedCity
                ? ` (${Texts.a} ${distanceFromCrush} ${Texts.km_de_vous})`
                : ""}
              {distanceFromCrush === "proximité" ? ` (${Texts.proximite})` : ""}
              {distanceFromLastCrush &&
                !distanceFromCrush &&
                ` (${distanceFromLastCrush})`}
            </p>
          </div>
          {desc && <p className="crush__desc">{desc}</p>}
          <span className="crush__fake-link">{Texts.decouvrir}</span>
        </div>
      </Link>
    </article>
  );
};

export default Crush;
