import React, { Component } from "react";
import { connect } from "react-redux";
import HoplaBanner from "../../img/img-hopla.jpg";
import Translations from "../lang/translations.json";
import isEmpty from "lodash/isEmpty";
import { showModal } from "../actions";
import * as geolib from "geolib";

import { MODAL_TYPE_LOCATION } from "../constants/ModalTypes";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].banner;
class Banner extends Component {
  openLocationModal = (e) => {
    e.preventDefault();
    this.props.showModal(MODAL_TYPE_LOCATION, {
      title: "Title of location modal",
      position: "right",
    });
  };
  getClosestLocations(pos) {
    const { latitude, longitude } = pos.coords;
    const myPos = { latitude, longitude };
    const cities = this.props.cities;
    const closestCities = cities
      .map((city) => {
        const lat = city.metas._CommuneLat
          ? parseFloat(city.metas._CommuneLat[0])
          : 0;
        const lon = city.metas._CommuneLon
          ? parseFloat(city.metas._CommuneLon[0])
          : 0;
        const distance = Math.floor(
          geolib.getDistance(myPos, {
            latitude: lat,
            longitude: lon,
          }) / 1000
        );

        city.distance = distance;

        return city;
      })
      .sort((prev, next) => (prev.distance > next.distance ? 1 : -1));

    this.setState({
      cities: closestCities,
      citiesByDistance: closestCities,
    });
  }

  initClosestLocations() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getClosestLocations(position);
        },
        (err) => {
          // console.log(err);
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }
  render() {
    // console.log(this.props)
    return (
      <div>
        {isEmpty(this.props.userLocation) &&
          this.props.selectedCity &&
          this.props.selectedCity.includes("?") && (
            <p className="banner__txt nogeo">
              Pour que je vous conseille mes bonnes adresses, je vous invite à
              sélectionner une{" "}
              <a
                href="/"
                title=""
                className=""
                onClick={this.openLocationModal}
              >
                <strong>ville</strong>
              </a>{" "}
              ci-dessus ou à vous{" "}
              <a
                href="/resultats?manger=saveurs-dalsace"
                title=""
                className=""
                onClick={this.initClosestLocations}
              >
                <strong>géolocaliser</strong>
              </a>{" "}
              .
            </p>
          )}
        <div className="banner" id="hopla">
          <figure className="banner__figure">
            <img src={HoplaBanner} alt="" className="banner__img" />
          </figure>
          <figcaption className="banner__figcaption">
            <p className="banner__txt">{Texts.hopla_on_mange}</p>
          </figcaption>
        </div>
      </div>
    );
  }
}

export default connect(null, { showModal })(Banner);
