import {
  FETCH_BLOG_RANDOM_POSTS,
  HANDLE_XHR_ERROR,
} from "../constants/ActionTypes";

function blogRandomPostsReducer(state = [], action) {
  switch (action.type) {
    case FETCH_BLOG_RANDOM_POSTS:
      return [...action.payload];
    case HANDLE_XHR_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default blogRandomPostsReducer;
