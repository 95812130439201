import React from "react";
import { Link, withRouter } from "react-router-dom";
import Translations from "../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].subheader;
const SubHeader = props => {
  const classes = props.hasBorder
    ? "sub-header__inner has-border"
    : "sub-header__inner";

  return (
    <div className="sub-header">
      <div className={classes}>
        <h1 className="sub-header__title">{props.title}</h1>
        {props.body && <p className="sub-header__body">{props.body}</p>}
        {props.closestCity && (
          <p className="sub-header__city">
            {Texts.vous_etes_localise_a} {props.closestCity}.
          </p>
        )}
        {props.link && (
          <Link
            to={{ pathname: "/resultats", search: props.location.search }}
            title=""
            className="sub-header__link"
          >
            <span>{props.link}</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default withRouter(SubHeader);
