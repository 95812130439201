import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as geolib from "geolib";
import qs from "qs";

import { slugify } from "../../helpers";
import { hideModal, fetchCities } from "../../actions";
import Modal from "../../components/Modal";
import SearchBox from "../../components/SearchBox";
import City from "../../components/City";
import logoAlsaceFlat from "../../../img/logo-alsace-flat.svg";
import closeSmRed from "../../../img/ico-close-sm-red.svg";
import Translations from "../../lang/translations.json";
import MapUser from "../../components/MapUser";
import { getUserLocationCustom } from "../../shared";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].modalLocationDate;
// title, afterClose, hideModal

class ModalLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      activeCityIndex: null,
      citiesByDistance: [],
      cities: [],
      citiesFiltered: [],
      userLocation: "",
    };

    this.onClose = this.onClose.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.clearSearchInputAndURL = this.clearSearchInputAndURL.bind(this);
  }

  componentDidMount() {
    const { cities } = this.props;
    if (!cities || !cities.length) {
      this.props.fetchCities((cities) => {
        this.setState({ cities, citiesByDistance: cities });
        this.initClosestLocations();
      });
    } else {
      this.setState({ cities, citiesByDistance: cities });
      this.initClosestLocations();
    }
    this.userLocation();
  }

  onClose() {
    this.props.hideModal();
    if (this.props.afterClose) {
      this.props.afterClose();
    }
    window.location.reload(false);
  }

  onSubmit() {
    this.props.hideModal();

    if (this.props.afterClose) {
      this.props.afterClose();
    }
  }

  onInputChange(e) {
    this.setState({
      activeCityIndex: null,
      term: e.target.value,
    });

    const filteredCities = this.state.citiesByDistance
      .filter((city) =>
        city.commune.toLowerCase().includes(e.target.value.toLowerCase())
      )
      .slice(0, 5);

    this.setState({ citiesFiltered: filteredCities });
  }

  updateTerritoryParam(territoryParam = "") {
    const qSearch = this.props.history.location.search;
    const parsedURL = qs.parse(qSearch, { ignoreQueryPrefix: true });
    parsedURL.territoire = territoryParam;

    if (parsedURL.manger) {
      parsedURL.manger = "";
    }

    if (parsedURL["manger-a-proximite"]) {
      parsedURL["manger-a-proximite"] = 0;
    }

    if (parsedURL["id-campaign-avizi"]) {
      parsedURL["id-campaign-avizi"] = "";
    }

    this.props.history.push({
      pathname: "/resultats",
      search: qs.stringify(parsedURL),
    });
    window.location.reload(false);
  }

  updateCity(city = "") {
    window.localStorage.setItem("selectedCity", city);
  }

  clearSearchInputAndURL() {
    this.setState({
      term: "",
      activeCityIndex: null,
    });
    this.initClosestLocations();
    this.updateTerritoryParam();
    this.updateCity();
    window.localStorage.removeItem("userCustomLocation");
  }

  onCityToggle(index, cityName, territory) {
    window.localStorage.removeItem("userCustomLocation");
    if (this.state.activeCityIndex === index) {
      this.setState({ activeCityIndex: null }, () => {
        this.clearSearchInputAndURL();
      });
    } else {
      this.setState({ activeCityIndex: index }, () => {
        if (this.state.activeCityIndex !== null) {
          this.updateTerritoryParam(slugify(territory));
          this.updateCity(cityName);
          this.setState({ term: cityName });
        }
      });
    }
  }

  getClosestLocations(pos) {
    const { latitude, longitude } = pos.coords;
    const myPos = { latitude, longitude };
    const cities = this.props.cities;
    const closestCities = cities
      .map((city) => {
        const lat = city.metas._CommuneLat
          ? parseFloat(city.metas._CommuneLat[0])
          : 0;
        const lon = city.metas._CommuneLon
          ? parseFloat(city.metas._CommuneLon[0])
          : 0;
        const distance = Math.floor(
          geolib.getDistance(myPos, {
            latitude: lat,
            longitude: lon,
          }) / 1000
        );

        city.distance = distance;

        return city;
      })
      .sort((prev, next) => (prev.distance > next.distance ? 1 : -1));

    this.setState({
      cities: closestCities,
      citiesByDistance: closestCities,
    });
  }

  initClosestLocations() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getClosestLocations(position);
        },
        (err) => {
          // console.log(err);
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }

  renderAutourDe() {
    const classes = this.state.activeCityIndex ? "city is-active" : "city";
    const activeIndex = this.state.activeCityIndex;
    const cities = this.state.cities;

    if (cities.length > 0) {
      return cities
        .filter((ville) => {
          return (
            ville.commune.match("Autour de") && ville.metas._Radius[0] !== 0
          );
        })
        .map((city, index) => {
          let ville = city.commune;
          switch (lang) {
            case "EN":
              ville = city.commune.replace("Autour de", "Around");
              break;
            case "DE":
              ville = city.commune.replace("Autour de", "Rund um");
              break;
            default:
              ville = city.commune;
              break;
          }
          return (
            <City
              key={index}
              onCityToggle={this.onCityToggle.bind(
                this,
                index,
                ville,
                city.metas._Territoire[0]
              )}
              isSelected={index === activeIndex}
              name={ville.toLowerCase()}
              distance={city.distance}
              classes={classes}
            />
          );
        })
        .slice(0, 5);
    }
  }

  renderCities() {
    const classes = this.state.activeCityIndex ? "city is-active" : "city";
    const activeIndex = this.state.activeCityIndex;
    const cities =
      this.state.citiesFiltered.length > 0
        ? this.state.citiesFiltered
        : this.state.cities;
    if (cities.length > 0) {
      return cities
        .map((city, index) => {
          return (
            <City
              key={index}
              onCityToggle={this.onCityToggle.bind(
                this,
                index,
                city.commune,
                city.metas._Territoire[0]
              )}
              isSelected={index === activeIndex}
              name={city.commune.toLowerCase()}
              distance={city.distance}
              classes={classes}
              departement={
                city.metas._Insee[0] !== ""
                  ? city.metas._Insee[0].substring(0, 2)
                  : ""
              }
            />
          );
        })
        .slice(0, 5);
    }
  }
  userLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userCustomLocation = getUserLocationCustom();
          // console.log(userCustomLocation)
          if (userCustomLocation !== undefined && userCustomLocation !== null) {
            this.setState({
              userLocation: [userCustomLocation[0], userCustomLocation[1]],
            });
            window.localStorage.removeItem("selectedCity");
          } else {
            this.setState({
              userLocation: [
                position.coords.latitude,
                position.coords.longitude,
              ],
            });
          }
        },
        (err) => {
          // console.log(err);
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  };
  render() {
    // console.log(this.state);
    return (
      <Modal
        title={this.props.title}
        position={this.props.position}
        onClose={this.onClose}
      >
        <div className="modal__logo-wrapper">
          <img src={logoAlsaceFlat} alt="Alsace" />
        </div>
        <div className="searchboxes">
          <div className="searchboxes__inner">
            <SearchBox type="date" isActive={false} />
            <SearchBox type="location" isActive={true} />
          </div>
        </div>

        <div className="modal__selection">
          <div>
            <button
              className="modal__selection__btn"
              onClick={this.clearSearchInputAndURL}
              style={{
                backgroundImage: `url(${closeSmRed})`,
              }}
            />
          </div>
          <span className="modal__selection__txt">{Texts.toute_lalsace}</span>
          {this.state.userLocation !== undefined && this.state.userLocation && (
            <MapUser
              userLocation={this.state.userLocation}
              texts={Translations[lang].results}
              close={this.onClose}
            ></MapUser>
          )}
        </div>

        <div className="cities">
          <div className="cities__inner" style={{ border: "none" }}>
            {this.props.cities.length > 0 && (
              <ul className="cities__list">{this.renderAutourDe()}</ul>
            )}
          </div>
          <div className="cities__inner">
            <div className="cities__search">
              <input
                type="text"
                onChange={this.onInputChange}
                placeholder={Texts.saisir_nom_commune}
                className="cities__input"
                value={this.state.term}
              />
            </div>
            {this.props.cities.length > 0 && (
              <ul className="cities__list">{this.renderCities()}</ul>
            )}
          </div>
        </div>
        <div className="modal__btn-wrapper">
          <button className="btn-primary" onClick={this.onSubmit.bind(this)}>
            {Texts.resultats}
          </button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ cities }) => {
  return { cities };
};

ModalLocation.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps, { hideModal, fetchCities })(
  withRouter(ModalLocation)
);
