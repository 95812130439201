import React, { Component } from "react";
import ReactFlagsSelect from "react-flags-select";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { APP_VERSION } from "../constants/Version";
import facebookIcon from "../../img/ico-fb.svg";
import youtubeIcon from "../../img/ico-yt.svg";
import instagramIcon from "../../img/ico-ig.svg";
import burgerMenu from "../../img/ico-menu.svg";
import defaultHeaderImg from "../../img/LIESEL_web-default-header.png";
import logoAlsace from "../../img/logo-liesel-alsace.png";
import icoHeart from "../../img/ico-heart.svg";
import Translations from "../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].header;

// selecteur de langue utilise la variable GB
const langFlag = lang === "EN" ? "GB" : lang;

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleNav = this.toggleNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.stickMenu = this.stickMenu.bind(this);

    this.state = {
      isNavOpen: false,
    };
  }

  toggleNav() {
    const isOpen = this.state.isNavOpen;
    this.setState({ isNavOpen: !isOpen });
  }

  closeNav() {
    this.setState({ isNavOpen: false });
  }

  hopla = (event) => {
    event.preventDefault();
    this.setState({ isNavOpen: false });
    var searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set("manger", "saveurs-dalsace");
    searchParams.delete("inspiration");
    this.props.history.push({
      pathname: "/resultats",
      search: "?" + searchParams.toString(),
    });
  };

  accueil = (event) => {
    event.preventDefault();
    this.setState({ isNavOpen: false });
    var searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.has("manger-a-proximite")) {
      searchParams.delete("manger-a-proximite");
      searchParams.delete("lat");
      searchParams.delete("lon");
    } else if (searchParams.has("inspiration")) {
      searchParams.delete("inspiration");
    } else if (searchParams.has("manger")) {
      searchParams.delete("manger");
    }
    this.props.history.push({
      pathname: "/",
      search: "?" + searchParams.toString(),
    });
  };

  clearInspi = (event) => {
    event.preventDefault();
    this.setState({ isNavOpen: false });
    var searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set("inspi", "oui");
    searchParams.delete("inspiration");
    searchParams.delete("manger");
    this.props.history.push({
      pathname: "/",
      search: "?" + searchParams.toString(),
    });
  };

  componentDidMount() {
    // langues depuis subdomain
    let parts = window.location.hostname.split(".");
    let subdomain = parts.shift();
    let domain = parts.join(".");
    domain = domain === "" ? window.location.hostname : domain;
    let lang = window.localStorage.getItem("lang");

    // Force app to update
    let version = APP_VERSION;
    const last_version = localStorage.getItem(`liesel-version-${lang}`);
    console.log(`version ${version} ${lang}`);
    if (last_version !== version) {
      console.log(
        `version ${version} ${lang} available (current version ${last_version})`
      );
      localStorage.setItem(`liesel-version-${lang}`, version);
      if (window.caches) {
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
      }
      window.location.reload(true);
    }

    // console.log(domain);
    if (
      (subdomain === "liesel-en-alsace" || subdomain === "en") &&
      lang !== "EN"
    ) {
      window.localStorage.setItem("lang", "EN");
      window.location.reload();
    } else if (
      (subdomain === "liesel-de-alsace" || subdomain === "de") &&
      lang !== "DE"
    ) {
      window.localStorage.setItem("lang", "DE");
      window.location.reload();
    } else if (
      (subdomain === "liesel-alsace" || subdomain === "www") &&
      lang !== "FR"
    ) {
      window.localStorage.setItem("lang", "FR");
      window.location.reload();
    } else {
      if (domain === "localhost") {
        // console.log("=> localhost");
      } else {
        // console.log("=> subdomain " + subdomain);
        // console.log("=> domain " + domain);
      }
    }

    // langues depuis paramètre
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("lang") === true) {
      if (
        window.localStorage.getItem("lang") ===
        searchParams.get("lang").toUpperCase()
      ) {
        return null;
      }
      let paramLang = searchParams.get("lang").toUpperCase();
      // console.log(searchParams.get("lang"));
      searchParams.delete("lang");
      this.props.history.push({
        pathname: "/",
        search: "?" + searchParams.toString(),
        state: { isNavOpen: false },
      });
      switch (paramLang) {
        case "FR":
          window.localStorage.setItem("lang", "FR");
          window.location.reload();
          break;
        case "EN":
          window.localStorage.setItem("lang", "EN");
          window.location.reload();
          break;
        case "DE":
          window.localStorage.setItem("lang", "DE");
          window.location.reload();
          break;
        default:
          window.localStorage.setItem("lang", "FR");
          window.location.reload();
          break;
      }
    }

    window.addEventListener("scroll", this.stickMenu);
  }

  onSelectFlag(flag) {
    let parts = window.location.hostname.split(".");
    let subdomain = parts.shift();
    let domain = parts.join(".");
    let url = window.location.host;
    let pathname = window.location.pathname;
    let params = window.location.search;
    domain = domain === "" ? window.location.hostname : domain;
    // console.log("=> " + url + pathname + params);
    switch (flag) {
      case "FR":
        window.localStorage.setItem("lang", "FR");
        if (domain !== "localhost") {
          subdomain = domain === "apsulis.fr" ? "liesel-alsace" : "www";
          url = `https://${subdomain}.${domain}${pathname}${params}`;
        }
        // console.log(url);
        window.location = `${url}`;
        break;
      case "GB":
        window.localStorage.setItem("lang", "EN");
        if (domain !== "localhost") {
          subdomain = domain === "apsulis.fr" ? "liesel-en-alsace" : "en";
          url = `https://${subdomain}.${domain}${pathname}${params}`;
        }
        // console.log(url);
        window.location = `${url}`;
        break;
      case "DE":
        window.localStorage.setItem("lang", "DE");
        if (domain !== "localhost") {
          subdomain = domain === "apsulis.fr" ? "liesel-de-alsace" : "de";
          url = `https://${subdomain}.${domain}${pathname}${params}`;
        }
        // console.log(url);
        window.location = `${url}`;
        break;
      default:
        break;
    }
  }

  menu = React.createRef();

  stickMenu = () => {
    if (this.menu && this.menu.current) {
      const menuOffSetTop = this.menu.current.offsetTop;
      const windowPageYOffset = window.pageYOffset;

      if (windowPageYOffset > menuOffSetTop) {
        this.menu.current.classList.add("sticky");
      } else {
        this.menu.current.classList.remove("sticky");
      }
    }
  };

  render() {
    const {
      settings: { images },
    } = this.props;

    // Image en fonction de la langue
    let imgHeaderDesktop = defaultHeaderImg;
    if (images && images[0]) {
      switch (lang) {
        case "EN":
          imgHeaderDesktop = images[0].header.en;
          break;
        case "DE":
          imgHeaderDesktop = images[0].header.de;
          break;
        default:
          imgHeaderDesktop = images[0].header.fr;
          break;
      }
    }

    const qString = this.props.location.search;
    const classesOpenNav = this.state.isNavOpen
      ? "header__main is-open"
      : "header__main";
    const classesTriggerBtn = this.state.isNavOpen
      ? "header__btn is-open"
      : "header__btn";

    return (
      <div className="header__wrapper">
        <img src={imgHeaderDesktop} alt="Liesel" />
        <header id="menu" className="header" ref={this.menu}>
          <div className="header__inner">
            <div className="header__container-logo">
              <div className="header__logo">
                <div className="header__btn-wrapper">
                  <button
                    className={classesTriggerBtn}
                    onClick={this.toggleNav}
                    aria-pressed={this.state.isNavOpen}
                    style={{
                      backgroundImage: `url(${burgerMenu})`,
                    }}
                  />
                </div>
                <NavLink
                  exact
                  to={`/${qString}`}
                  title="Alsace"
                  className="header__logo-link"
                  onClick={this.accueil}
                >
                  <img src={logoAlsace} alt="Liesel" />
                </NavLink>
              </div>
              <ReactFlagsSelect
                countries={["FR", "GB", "DE"]}
                selected={langFlag}
                customLabels={{
                  GB: "English",
                  FR: "Français",
                  DE: "Deutsch",
                }}
                showSelectedLabel={false}
                showOptionLabel={true}
                selectedSize={24}
                optionsSize={20}
                alignOptions="right"
                refs="userFlag"
                onSelect={this.onSelectFlag}
                className="menu-flags-mobile"
              />
              <div className="header__img-alsace"></div>
            </div>
            <div className={classesOpenNav}>
              <nav className="header__nav">
                <ul className="header__nav-list">
                  <li className="header__nav-item">
                    <NavLink
                      to={`/qui-suis-je${qString}`}
                      title={Texts.qui_suis_je}
                      className="header__nav-link"
                      activeClassName="is-active"
                      onClick={this.closeNav}
                    >
                      {Texts.qui_suis_je}
                    </NavLink>
                  </li>
                  <li className="header__nav-item">
                    <NavLink
                      to={`/${qString}`}
                      title={Texts.besoin_dinspiration}
                      className="header__nav-link"
                      activeClassName="is-inactive"
                      onClick={this.clearInspi}
                    >
                      {Texts.besoin_dinspiration}
                    </NavLink>
                  </li>
                  <li className="header__nav-item">
                    <NavLink
                      to={`/${qString}`}
                      title={Texts.hopla_on_mange}
                      className="header__nav-link"
                      activeClassName="is-inactive"
                      onClick={this.hopla}
                    >
                      {Texts.hopla_on_mange}
                    </NavLink>
                  </li>
                  <li className="header__nav-item">
                    <NavLink
                      to={`/abonnement${qString}`}
                      title={Texts.recevoir_mes_coups_de_coeur}
                      className="header__nav-link header__nav-link--favorite"
                      activeClassName="is-active"
                      onClick={this.closeNav}
                    >
                      <img src={icoHeart} alt="Liesel" />
                      {Texts.recevoir_mes_coups_de_coeur}
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className="header__social">
                <ReactFlagsSelect
                  countries={["FR", "GB", "DE"]}
                  selected={langFlag}
                  customLabels={{
                    GB: "English",
                    FR: "Français",
                    DE: "Deutsch",
                  }}
                  showSelectedLabel={false}
                  showOptionLabel={true}
                  selectedSize={24}
                  optionsSize={14}
                  alignOptions="right"
                  refs="userFlag"
                  onSelect={this.onSelectFlag}
                  className="menu-flags-desktop"
                />
              </div>
              <div className="header__nav-item">
                  <a className="header__nav-link blog" title={Texts.blog} href={Texts.blog_url} target="_blank">{Texts.blog}</a>
              </div>

            </div>
          </div>
        </header>
      </div>
    );
  }
}

function mapStateToProps({ settings }) {
  return {
    settings,
  };
}

export default withRouter(connect(mapStateToProps)(Header));
