import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import {
  fetchCrushesByCoords,
  fetchRestaurantsByCoords,
  fetchCrushesByGRC,
  fetchCrushesByCoordsGRC,
  fetchMoreCrushes,
  fetchMoreCrushesMap,
  fetchCities,
  fetchInspirations,
  fetchUser,
  getUserLocation,
  fetchCrushesCatering,
} from "../actions";
import {
  getClosestCity,
  getCityTerritoryMatch,
  getSelectedDate,
  getUserLocationCustom,
} from "../shared";
import { frDateFormat, getRandomInt } from "../helpers";
import { slugify } from "../helpers";
import SubHeader from "../components/SubHeader";
import SearchBox from "../components/SearchBox";
import FlatBox from "../components/FlatBox";
import Banner from "../components/Banner";
import Crush from "../components/Crush";
import Loader from "../components/Loader";
import Slider from "../components/Slider";
import ModalRoot from "../containers/ModalRoot";
import Translations from "../lang/translations.json";
import MapResults from "../components/MapResults";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].results;

const userLocation = getUserLocationCustom();

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCrushesLoading: false,
      selectedDate: "",
      selectedCity: window.localStorage.getItem("selectedCity") || Texts.ou,
      selectedCateringNumber: "",
      selectedInspirationIndex: -1,
      selectedInspirationNumber: "",
      selectedUser: window.localStorage.getItem("firstName") || "",
      userLocation: userLocation ? userLocation : "",
      isMapOpen: false,
    };

    this.loadMoreCrushes = this.loadMoreCrushes.bind(this);
    this.loadMoreCrushesMap = this.loadMoreCrushesMap.bind(this);
  }

  componentDidMount() {
    // console.log(userLocation);
    // Get params from URL
    const qParam = this.getURLParam();

    // Get user geolocation
    if (
      (isEmpty(this.props.userLocation) &&
        this.state.userLocation === undefined) ||
      userLocation === undefined
    ) {
      this.props.getUserLocation((userLocation) => {
        const { latitude, longitude } = userLocation;
        this.setState({
          userLocation: {
            latitude,
            longitude,
          },
        });
      });
    }
    if (this.state.userLocation !== undefined) {
      const latitude = this.state.userLocation[0];
      const longitude = this.state.userLocation[1];
      this.setState({
        userLocation: {
          latitude,
          longitude,
        },
      });
    }

    // Get the date from URL params and update the searchbox text
    if (qParam.jour) {
      const selectedDate = getSelectedDate(qParam.jour);
      this.setState({ selectedDate });
    } else {
      this.setState({ selectedDate: "" });
    }

    // Fetch inspirations
    if (!this.props.inspirations.length) {
      this.props.fetchInspirations((inspirations) => {
        // Get the inspiration param from URL and update state
        if (qParam.inspiration) {
          this.setSelectedInspirationIndex(qParam.inspiration, inspirations);
          this.setSelectedInspirationNumber(qParam.inspiration);
        }
      });
    } else {
      // Update inspiration state when we come from another page
      if (qParam.inspiration) {
        this.setSelectedInspirationIndex(
          qParam.inspiration,
          this.props.inspirations
        );
        this.setSelectedInspirationNumber(qParam.inspiration);
      }
    }

    // Get the "manger" param from URL and update state
    if (qParam.manger) {
      this.setSelectedCatering(qParam.manger);
    } else {
      this.setState({ selectedCateringNumber: "" });
      window.localStorage.removeItem("selectedCateringNumber");
    }

    // Get the user from URL params
    if (qParam.id) {
      this.updateSelectedUser(qParam.id);
    }

    // Fetch crushes
    this.fetchAllCrushes();

    // Fetch the catering crushes to get the different catering categories
    if (!this.props.crushesCatering.length) {
      this.props.fetchCrushesCatering();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Get the city from the local storage and update the searchbox text
    const selectedCity =
      window.localStorage.getItem("selectedCity") || Texts.ou;
    if (selectedCity !== prevState.selectedCity) {
      this.setState({ selectedCity });
    }

    if (prevProps.location.search !== this.props.location.search) {
      // Get params from URL
      const qParam = this.getURLParam();

      // Get the date from URL params and update the searchbox text
      if (qParam.jour) {
        const selectedDate = getSelectedDate(qParam.jour);
        this.setState({ selectedDate });
      } else {
        this.setState({ selectedDate: "" });
      }

      // Get the "manger" param from URL and update state
      if (qParam.manger) {
        this.setSelectedCatering(qParam.manger);
      } else {
        this.setState({ selectedCateringNumber: "" });
        window.localStorage.removeItem("selectedCateringNumber");
      }

      // Get the inspiration param from URL and update state
      if (qParam.inspiration && this.props.inspirations.length) {
        this.setSelectedInspirationIndex(
          qParam.inspiration,
          this.props.inspirations
        );
        this.setSelectedInspirationNumber(qParam.inspiration, () => {
          this.fetchAllCrushes();
        });
      } else {
        this.setState(
          {
            selectedInspirationIndex: -1,
            selectedInspirationNumber: "",
          },
          () => {
            // If we unselect an inspiration or when no inspiration are selected
            this.fetchAllCrushes();
          }
        );
      }
      this.setState({ isMapOpen: "" });
      // Refetch crushes but not if we have new inspirations because that fetch had to be done above
      // const prevQueryString = prevProps.location.search;
      // const prevQueryObj = qs.parse(prevQueryString, {
      //   ignoreQueryPrefix: true
      // });
      // if (qParam.inspiration === prevQueryObj.inspiration) {
      //   this.fetchAllCrushes();
      // }
    }
  }

  toggleMap = () => {
    const isOpen = this.state.isMapOpen;
    this.setState({ isMapOpen: !isOpen });
  };
  getURLParam() {
    const qString = this.props.location.search;
    const queryObj = qs.parse(qString, { ignoreQueryPrefix: true });
    return queryObj;
  }

  setSelectedCatering(eatParam) {
    var selectedCateringNumber = "1901496"; // manger = tous
    if (eatParam === "vite-et-bon") {
      selectedCateringNumber = "1901496,1911412";
    } else if (eatParam === "saveurs-dalsace") {
      selectedCateringNumber = "1901496,1911413";
    } else if (eatParam === "tables-dexception") {
      selectedCateringNumber = "1901496,1911414";
    } else if (eatParam === "dautres-envies") {
      selectedCateringNumber = "1901496,1911415";
    } else if (eatParam === "autour-de-moi") {
      selectedCateringNumber = "1901496";
    }

    this.setState({ selectedCateringNumber });
    window.localStorage.setItem(
      "selectedCateringNumber",
      selectedCateringNumber
    );
  }

  setSelectedInspirationIndex(inspirationParam, inspirations) {
    const selectedInspirationIndex = inspirations.findIndex(
      (inspiration) => inspiration.slug === inspirationParam
    );

    this.setState({ selectedInspirationIndex });
  }

  setSelectedInspirationNumber(inspirationParam, callback) {
    var selectedInspirationNumber = "1901495"; // inspiration = toutes
    if (
      inspirationParam === "bouger-se-defouler" ||
      inspirationParam === "get-moving-let-off-steam" ||
      inspirationParam === "aktiv-sein-austoben"
    ) {
      selectedInspirationNumber = "1901495,1911411";
    } else if (
      inspirationParam === "toute-une-histoire" ||
      inspirationParam === "history-to-tell" ||
      inspirationParam === "eine-lange-geschichte"
    ) {
      selectedInspirationNumber = "1901495,1911410";
    } else if (
      inspirationParam === "se-laisser-surprendre" ||
      inspirationParam === "be-amazed" ||
      inspirationParam === "sich-uberraschen-lassen"
    ) {
      selectedInspirationNumber = "1901495,1911409";
    } else if (
      inspirationParam === "a-laffiche-en-ce-moment" ||
      inspirationParam === "whats-happening-now" ||
      inspirationParam === "zur-zeit-aktuell"
    ) {
      selectedInspirationNumber = "1901495,1911408";
    } else if (
      inspirationParam === "lalsace-carte-postale" ||
      inspirationParam === "alsace-a-picture-postcard" ||
      inspirationParam === "das-elsass-ein-postkartenbild"
    ) {
      selectedInspirationNumber = "1901495,1911407";
    } else if (
      inspirationParam === "consommer-local" ||
      inspirationParam === "local-products" ||
      inspirationParam === "lokal-verbrauchen"
    ) {
      selectedInspirationNumber = "1901495,1911843";
    } else if (
      inspirationParam === "rencontrer-nos-vignerons" ||
      inspirationParam === "gesprach-mit-einem-winzer" ||
      inspirationParam === "meet-our-winegrowers"
    ) {
      selectedInspirationNumber = "1901495,1911844";
    }

    this.setState({ selectedInspirationNumber }, callback);
  }

  updateSelectedUser(userParam) {
    var selectedUser;
    if (isEmpty(this.props.user)) {
      this.props.fetchUser(userParam, (user) => {
        selectedUser = user.prenom;
        this.setState({ selectedUser });
      });
    } else {
      selectedUser = this.props.user.prenom;
      this.setState({ selectedUser });
    }
  }

  fetchRestaurants(lon, lat) {
    this.props.fetchRestaurantsByCoords(lon, lat);
  }

  fetchClosestCrushesByCoords() {
    const that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          var lat = pos.coords.latitude.toString().replace(".", ",");
          var lon = pos.coords.longitude.toString().replace(".", ",");
          // console.log(
          //   '===> Territory param in URL === "autour de moi" and user allowed geolocation ==> Fetch with coordinates'
          // );
          that.props.fetchCrushesByCoords(
            12,
            lat,
            lon,
            5,
            that.state.selectedCateringNumber
          );
        },
        function (err) {
          // If the user does not allow his geolocation and we don't have a territorry in the URL
          // We fetch the crushes on the territory of Sélestat
          // console.log(
          //   'Territory param in URL === "autour de moi"  and user did not allow geolocation ==> Fetch with default GRC Criteria'
          // );

          const dateFormatted =
            frDateFormat(that.state.selectedDate) ||
            moment().format("DD/MM/YYYY");

          var GRCSoonTwoThree = "222000045";
          that.props.fetchCrushesByGRC(
            12,
            GRCSoonTwoThree,
            that.state.selectedCateringNumber,
            that.state.selectedInspirationNumber,
            dateFormatted
          );
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }

  fetchCrushesByFilter(cities, territoryParam, isToday) {
    const city = window.localStorage.getItem("selectedCity");
    let cityMatch = false;
    if (city) {
      cityMatch = cities.find((ville) => {
        return ville.commune === city;
      });
    }
    if (!cityMatch || cityMatch.metas._Radius[0] === 0) {
      cityMatch = getCityTerritoryMatch(cities, territoryParam);
    }
    var todayGRC = cityMatch.metas._GRC_Quotidien_fiche_1[0];
    var todayGRC_2 = cityMatch.metas._GRC_Quotidien_fiche_2_et_3[0];
    var soonGRC = cityMatch.metas._GRC_Quotidien_fiche_1[0];
    var soonGRC_2 = cityMatch.metas._GRC_Quotidien_fiche_2_et_3[0];
    // If we have a date different from today or no date, we fetch by GRC Criteria _GRC_Bienvenue_fiche_2_et_3
    if (!isToday) {
      const yesterday = moment(this.state.selectedDate)
        .subtract(1, "days")
        .format("DD/MM/YYYY");
      if (cityMatch.metas._Radius[0] === 0) {
        // console.log("===> Fetch crushes by GRC Criteria for SOON");
        this.props.fetchCrushesByGRC(
          12,
          soonGRC,
          soonGRC_2,
          this.state.selectedCateringNumber,
          this.state.selectedInspirationNumber,
          frDateFormat(this.state.selectedDate),
          yesterday
        );
      } else {
        // console.log("===> Fetch crushes by Coords and GRC Criteria for SOON");
        let nombreResultats = 3;
        if (cityMatch.commune.indexOf("Autour de") !== -1) {
          nombreResultats = 6;
        }
        this.props.fetchCrushesByCoordsGRC(
          nombreResultats,
          cityMatch.metas._CommuneLat[0],
          cityMatch.metas._CommuneLon[0],
          cityMatch.metas._Radius[0],
          soonGRC,
          soonGRC_2,
          this.state.selectedCateringNumber,
          this.state.selectedInspirationNumber,
          frDateFormat(this.state.selectedDate),
          yesterday,
          cityMatch.commune
        );
      }
    } else {
      // Else we fetch by GRC Criteria _GRC_Quotidien_fiche_2_et_3
      const todayFormatted = moment().format("DD/MM/YYYY");
      const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
      if (cityMatch.metas._Radius[0] === 0) {
        // console.log("===> Fetch crushes by GRC Criteria for TODAY");
        this.props.fetchCrushesByGRC(
          12,
          todayGRC,
          todayGRC_2,
          this.state.selectedCateringNumber,
          this.state.selectedInspirationNumber,
          todayFormatted,
          yesterday
        );
      } else {
        // Get the "manger" param from URL and update state
        let nombreResultats = 3;
        if (cityMatch.commune.indexOf("Autour de") !== -1) {
          nombreResultats = 6;
        }
        this.props.fetchCrushesByCoordsGRC(
          nombreResultats,
          cityMatch.metas._CommuneLat[0],
          cityMatch.metas._CommuneLon[0],
          cityMatch.metas._Radius[0],
          soonGRC,
          soonGRC_2,
          window.localStorage.getItem("selectedCateringNumber"),
          this.state.selectedInspirationNumber,
          todayFormatted,
          yesterday,
          cityMatch.commune
        );
      }
    }
  }

  fetchAllCrushes() {
    const qParam = this.getURLParam();
    const territoryParam = qParam.territoire;
    const nearbyRestaurantsParam = qParam["manger-a-proximite"];
    const lonParam = qParam.lon;
    const latParam = qParam.lat;
    const isToday = !qParam.jour || qParam.jour.includes("plus-0");

    // We have a territory in the URL and it is different from 'autour-de-moi'
    // => we fetch by GRC Criteria
    if (territoryParam && territoryParam !== "autour-de-moi") {
      if (!this.props.cities.length) {
        this.props.fetchCities((cities) =>
          this.fetchCrushesByFilter(cities, territoryParam, isToday)
        );
      } else {
        this.fetchCrushesByFilter(this.props.cities, territoryParam, isToday);
      }
    } else if (territoryParam === "autour-de-moi") {
      // We have 'autour-de-moi' param in URL
      // => We fetch crushes by user geolocation
      this.fetchClosestCrushesByCoords();
    } else if (nearbyRestaurantsParam === "1" && lonParam && latParam) {
      this.fetchRestaurants(lonParam, latParam);
    } else if (!territoryParam) {
      // We have '' param in URL or no param at all
      // => We fetch crushes using the closest city from the user
      if (!this.props.cities.length) {
        this.props.fetchCities((cities) =>
          this.fetchClosestCrushesByGRC(cities, isToday)
        );
      } else {
        this.fetchClosestCrushesByGRC(this.props.cities, isToday);
      }
    }
  }

  fetchClosestCrushesByGRC(cities, isToday) {
    const that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          // console.log(
          //   "No territory param in URL and user allowed geolocation ==> Find user territory with his coordinates"
          // );
          const { latitude, longitude } = pos.coords;
          const myPos = { latitude, longitude };
          const closestCity = getClosestCity(myPos, cities);
          // console.log(
          //   "Closest city from user: ",
          //   closestCity.commune + " => " + closestCity.metas._Territoire
          // );
          that.setState({
            closestCity: closestCity.commune,
          });
          const GRCTodayOne = closestCity.metas._GRC_Quotidien_fiche_1[0];
          const GRCTodayTwoThree =
            closestCity.metas._GRC_Quotidien_fiche_2_et_3[0];
          const GRCSoonOne = closestCity.metas._GRC_Quotidien_fiche_1[0];
          const GRCSoonTwoThree =
            closestCity.metas._GRC_Quotidien_fiche_2_et_3[0];
          if (isToday) {
            // console.log("===> Geoloc: Fetch crushes by GRC Criteria for TODAY");
            const todayFormatted = moment().format("DD/MM/YYYY");
            const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
            that.props.fetchCrushesByCoordsGRC(
              9,
              closestCity.metas._CommuneLat,
              closestCity.metas._CommuneLon,
              closestCity.metas._Radius,
              GRCTodayOne,
              GRCTodayTwoThree,
              that.state.selectedCateringNumber,
              that.state.selectedInspirationNumber,
              todayFormatted,
              yesterday,
              closestCity.commune
            );
          } else {
            // console.log("===> Geoloc: Fetch crushes by GRC Criteria for SOON");
            const yesterday = moment(that.state.selectedDate)
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            that.props.fetchCrushesByCoordsGRC(
              9,
              closestCity.metas._CommuneLat,
              closestCity.metas._CommuneLon,
              closestCity.metas._Radius,
              GRCSoonOne,
              GRCSoonTwoThree,
              that.state.selectedCateringNumber,
              that.state.selectedInspirationNumber,
              frDateFormat(that.state.selectedDate),
              yesterday,
              closestCity.commune
            );
          }
        },
        function (err) {
          // console.log(err);
          // console.log(
          //   "No territory param in URL and user did not allow geolocation"
          // );
          var randomCity = getRandomInt(0, cities.length);
          var GRCTodayOne = cities[randomCity].metas._GRC_Quotidien_fiche_1[0];
          var GRCTodayTwoThree =
            cities[randomCity].metas._GRC_Quotidien_fiche_2_et_3[0];
          var GRCSoonOne = cities[randomCity].metas._GRC_Quotidien_fiche_1[0];
          var GRCSoonTwoThree =
            cities[randomCity].metas._GRC_Quotidien_fiche_2_et_3[0];
          if (isToday) {
            // console.log(
            //   "===> Geolocation not allowed: Fetch crushes by GRC Criteria for TODAY with RANDOM city : " +
            //     cities[randomCity].commune +
            //     " (" +
            //     cities[randomCity].metas._Territoire +
            //     ")"
            // );
            const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
            const todayFormatted = moment().format("DD/MM/YYYY");
            that.props.fetchCrushesByGRC(
              9,
              GRCTodayOne,
              GRCTodayTwoThree,
              that.state.selectedCateringNumber,
              that.state.selectedInspirationNumber,
              todayFormatted,
              yesterday
            );
          } else {
            // console.log(
            //   "===> Geolocation not allowed: Fetch crushes by GRC Criteria for SOON with RANDOM city : " +
            //     cities[randomCity].commune +
            //     " (" +
            //     cities[randomCity].metas._Territoire +
            //     ")"
            // );
            const yesterday = moment(that.state.selectedDate)
              .subtract(1, "days")
              .format("DD/MM/YYYY");
            that.props.fetchCrushesByGRC(
              9,
              GRCSoonOne,
              GRCSoonTwoThree,
              that.state.selectedCateringNumber,
              that.state.selectedInspirationNumber,
              frDateFormat(that.state.selectedDate),
              yesterday
            );
          }
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }

  loadMoreCrushes() {
    const nextURL = this.props.crushes.pagination.next.replace("http", "https");

    this.setState({ isCrushesLoading: true });

    this.props.fetchMoreCrushes(nextURL, () => {
      this.setState({ isCrushesLoading: false });
    });
  }

  loadMoreCrushesMap() {
    if (this.props.crushes.pagination.next) {
      let nextURL = this.props.crushes.pagination.next.replace("http", "https");
      nextURL = nextURL.replace(/(rto=)[^&]+/, "$1999");
      this.props.fetchMoreCrushesMap(nextURL, () => {});
    }
  }

  renderCateringFilterBox() {
    let params = this.props.location;
    const names = [
      "Saveurs d'Alsace",
      "Tables d'exception",
      "Vite et bon",
      "D’autres envies",
    ];
    return names.map((name) => {
      return (
        <FlatBox
          key={slugify(name)}
          type="eat"
          isActive={false}
          href={slugify(name)}
          category={name}
          params={params}
        />
      );
    });
  }

  renderCrushes() {
    const crushes = this.props.crushes.products;
    const crushesMap =
      this.props.crushes.productsMap !== undefined
        ? this.props.crushes.productsMap
        : null;

    const classesTriggerBtn = this.state.isMapOpen ? "is-open" : "";

    if (!crushes) {
      return <Loader loading={true} text="Chargement..." />;
    } else if (!crushes.length) {
      return (
        <p className="crushes__message">
          {`${Texts.desole} ${this.state.selectedUser}, ${Texts.mes_prochains}`}
        </p>
      );
    } else {
      return (
        <div className="crushes__list">
          {isArray(crushes) && crushes[0].latitude !== undefined && (
            <div>
              <button
                className={`btn-primary btn-primary--list ${classesTriggerBtn}`}
                onClick={this.toggleMap}
                aria-pressed={this.state.isMapOpen}
              ></button>
              <button
                className={`btn-primary btn-primary--map ${classesTriggerBtn}`}
                onClick={this.toggleMap}
                aria-pressed={this.state.isMapOpen}
              ></button>
            </div>
          )}

          {this.state.isMapOpen && (
            <MapResults
              state={this.state}
              crushes={crushesMap !== null ? crushesMap : crushes}
              texts={Texts}
              loadmore={this.loadMoreCrushesMap}
            ></MapResults>
          )}
          {!this.state.isMapOpen && (
            <div className="crushes__list crushes__list--three">
              {crushes.map(this.renderCrush.bind(this))}
            </div>
          )}
        </div>
      );
    }
  }

  renderCrush(crush) {
    const id = crush.id;
    const title = crush.nom;
    const location =
      crush.coordonnees !== undefined
        ? crush.coordonnees.libelle_commune
        : null;

    if (crush.criteres !== undefined && crush.criteres.length) {
      var criteriaImgURL = crush.criteres.find(
        (criteria) => criteria.id === 1900421
      );
      var criteriaImgALT = crush.criteres.find(
        (criteria) => criteria.id === 1900480
      );
      var criteriaDesc = crush.criteres.find(
        (criteria) => criteria.id === 1901440
      );
    }

    const imgURL = criteriaImgURL
      ? `https://${criteriaImgURL.valeur
          .replace("https://", "")
          .replace("http://", "")}`
      : null;

    const imgTitle = criteriaImgALT ? criteriaImgALT.valeur : null;

    const desc = criteriaDesc ? criteriaDesc.valeur : null;

    const link = title
      ? `/article/${slugify(title)}-${id}${this.props.location.search}`
      : `/article/${id}`;

    const latitude = crush.latitude !== undefined ? crush.latitude : null;
    const longitude = crush.longitude !== undefined ? crush.longitude : null;
    const distance = crush.distance !== undefined ? crush.distance : null;

    return (
      <Crush
        key={id}
        type="small"
        title={title}
        link={link}
        location={location}
        imgURL={imgURL}
        imgTitle={imgTitle}
        desc={desc}
        latitude={latitude}
        longitude={longitude}
        distance={distance}
        userPos={this.state.userLocation}
      />
    );
  }

  render() {
    var nextPageURL = this.props.crushes.pagination
      ? this.props.crushes.pagination.next
      : null;
    if (!this.props.location.search.includes("manger")) {
      var SubHeaderTitle = Texts.mes_coups_de_coeur;
    }
    let userLocation = this.state.userLocation;
    return (
      <main className="main main--results">
        <SubHeader
          hasBorder={false}
          title={SubHeaderTitle}
          body=" "
          closestCity={this.state.closestCity}
        />
        <div className="searchboxes">
          <div className="searchboxes__inner">
            <SearchBox
              type="date"
              isActive={false}
              selectedDate={this.state.selectedDate}
            />
            <SearchBox
              type="location"
              isActive={false}
              selectedCity={this.state.selectedCity}
            />
            <ModalRoot />
          </div>
        </div>

        {this.props.inspirations.length > 0 &&
          !this.props.location.search.includes("manger") && (
            <div className="tns tns--filter">
              <Slider
                type="filter"
                data={this.props.inspirations}
                handleOnClick={this.setSelectedInspirationIndex}
                selectedIndex={this.state.selectedInspirationIndex}
              />
            </div>
          )}
        <div id="viewContent" />
        {!this.props.location.search.includes("inspiration") && (
          <div className="hopla">
            <div className="hopla__inner">
              <Banner
                userLocation={userLocation}
                selectedCity={this.state.selectedCity}
              />
            </div>
          </div>
        )}
        {!this.props.location.search.includes("inspiration") && (
          <div className="boxfilter">
            <div className="boxfilter__boxes">
              {this.renderCateringFilterBox()}
            </div>
          </div>
        )}
        <div className="crushes">
          <div className="crushes__inner">
            {/* <div className="crushes__spotlight">
              <Crush type="spotlight" />
            </div> */}
            {this.renderCrushes()}

            {nextPageURL && !this.state.isMapOpen && (
              <Loader
                loading={this.state.isCrushesLoading}
                handleClick={this.loadMoreCrushes}
                text={Texts.afficher_plus}
              />
            )}
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps({
  crushes,
  cities,
  inspirations,
  crushesCatering,
  userLocation,
  user,
}) {
  return { crushes, cities, inspirations, crushesCatering, userLocation, user };
}

export default connect(mapStateToProps, {
  fetchCrushesByCoords,
  fetchRestaurantsByCoords,
  fetchCrushesByCoordsGRC,
  fetchCrushesByGRC,
  fetchMoreCrushes,
  fetchMoreCrushesMap,
  fetchCities,
  fetchInspirations,
  fetchCrushesCatering,
  fetchUser,
  getUserLocation,
})(Results);
