import React from 'react';
import SubHeader from '../components/SubHeader';

const Unsubscribe = () => {
  return (
    <main className="main main--unsubscribe">
      <SubHeader
        title="Me Désabonner"
        body="Lorem ipsum dolor sit amet"
        hasBorder={true}
      />
      <div className="generic-content">
        <div className="generic-content__block">
          <h3>Lorem ipsum dolor</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Unsubscribe;
