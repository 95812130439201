import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    var searchParams = new URLSearchParams(this.props.location.search);

    // hopla scroll + effacer paramètre de la query
    if (searchParams.has("hopla") === true) {
      var elementHopla = document.getElementById("hopla");
      if (elementHopla) {
        elementHopla.scrollIntoView();
        // console.log("Hopla !");
        searchParams.delete("hopla");
        this.props.history.push({
          pathname: "/",
          search: "?" + searchParams.toString(),
          state: { isNavOpen: false },
        });
      }
    }
    // inspirations scroll + effacer paramètre de la query
    if (searchParams.has("inspi") === true) {
      var elementInspi = document.getElementById("inspirations");
      if (elementInspi) {
        elementInspi.scrollIntoView();
        // console.log("Inspirations !");
        searchParams.delete("inspi");
        this.props.history.push({
          pathname: "/",
          search: "?" + searchParams.toString(),
          state: { isNavOpen: false },
        });
      }
    }

    if (this.props.location !== prevProps.location) {
      var element = document.getElementById("viewContent");
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
