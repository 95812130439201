import { FETCH_SETTINGS } from "../constants/ActionTypes";

function citiesReducer(state = [], action) {
  switch (action.type) {
    case FETCH_SETTINGS:
      return action.payload;
    default:
      return state;
  }
}

export default citiesReducer;
