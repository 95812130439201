import { FETCH_INSPIRATIONS } from '../constants/ActionTypes';

function inspirationsReducer(state = [], action) {
  switch (action.type) {
    case FETCH_INSPIRATIONS:
      return [...state, ...action.payload];
    default:
      return state;
  }
}

export default inspirationsReducer;
