import React, { Component, Fragment } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "../../../node_modules/leaflet/dist/leaflet.css";
import { slugify } from "../helpers";
// https://github.com/PaulLeCam/react-leaflet/issues/255#issuecomment-388492108
import marker from "../../img/ico-map-marker.svg";
import markerUserImg from "../../img/ico-user-location.png";
import marker2x from "../../img/ico-map-marker.svg";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import MarkerClusterGroup from "react-leaflet-markercluster";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
});

const MyPopupMarker = ({ content, position }) => (
  <Marker position={position}>
    <Popup>
      <h3 className="crush__title crush__link">{content}</h3>
    </Popup>
  </Marker>
);

const MyMarkersList = ({ markers }) => {
  const items = markers.map(({ key, ...props }) => (
    <MyPopupMarker key={key} {...props} />
  ));
  return <Fragment>{items}</Fragment>;
};

const markerUser = new L.Icon({
  iconUrl: markerUserImg,
  iconSize: [30, 30],
});

export default class MapResults extends Component {
  constructor(props) {
    super(props);

    const markers = this.props.crushes.map((crush, index) => {
      const id = crush.id;
      const nom = crush.nom;
      const lat =
        crush.latitude !== undefined ? crush.latitude.replace(",", ".") : "";
      const lon =
        crush.longitude !== undefined ? crush.longitude.replace(",", ".") : "";
      const criteriaImgURL =
        crush.criteres !== undefined
          ? crush.criteres.find((criteria) => criteria.id === 1900421)
          : null;
      const imgURL = criteriaImgURL
        ? `https://${criteriaImgURL.valeur
            .replace("https://", "")
            .replace("http://", "")}`
        : null;
      let url = new URL(window.location.href);
      const urlSearchParams = url.searchParams;
      const lien = nom
        ? `/article/${slugify(nom)}-${id}?${urlSearchParams}`
        : `/article/${id}`;
      const crushMarker = {
        key: index,
        position: [lat, lon],
        content: (
          <div className="crush__img-wrapper">
            <a href={lien}>
              <div className="crush__title">{nom}</div>
              <img className="crush__img" src={imgURL} alt={nom} />
            </a>
          </div>
        ),
      };

      return crushMarker;
    });

    const alsaceLocation = [48.38, 7.36];
    const userLocation =
      this.props.state.userLocation.latitude !== undefined
        ? [
            this.props.state.userLocation.latitude,
            this.props.state.userLocation.longitude,
          ]
        : alsaceLocation; // Alsace

    const bounds = L.latLngBounds([
      userLocation,
      markers[0].position,
    ]);

    this.state = {
      markers: markers,
      chargement: "",
      mapRef: null,
      userLocation,
      bounds
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mapRef !== this.state.mapRef || prevState.bounds !== this.state.bounds) {
      this.state.mapRef.fitBounds(this.state.bounds);
    }
  }

  handleLoadCrushes = () => {
    if (this.state.chargement !== "fait") {
      this.props.loadmore();
      this.forceUpdate();
    }

    const markers = this.props.crushes.map((crush, index) => {
      const id = crush.id;
      const nom = crush.nom;
      const lat =
        crush.latitude !== undefined ? crush.latitude.replace(",", ".") : "";
      const lon =
        crush.longitude !== undefined ? crush.longitude.replace(",", ".") : "";
      const criteriaImgURL =
        crush.criteres !== undefined
          ? crush.criteres.find((criteria) => criteria.id === 1900421)
          : null;
      const imgURL = criteriaImgURL
        ? `https://${criteriaImgURL.valeur
            .replace("https://", "")
            .replace("http://", "")}`
        : null;
      let url = new URL(window.location.href);
      const urlSearchParams = url.searchParams;
      const lien = nom
        ? `/article/${slugify(nom)}-${id}?${urlSearchParams}`
        : `/article/${id}`;
      const crushMarker = {
        key: index,
        position: [lat, lon],
        content: (
          <div className="crush__img-wrapper">
            <a href={lien}>
              <div className="crush__title">{nom}</div>
              <img className="crush__img" src={imgURL} alt={nom} />
            </a>
          </div>
        ),
      };

      return crushMarker;
    });

    this.setState({
      markers: markers,
      chargement: "fait",
    });
  };

  render() {
    const { bounds, userLocation } = this.state;

    const marker = userLocation ? (
      <Marker icon={markerUser} position={userLocation}>
        <Popup>{this.props.texts.votre_position}</Popup>
      </Marker>
    ) : null;

    if (this.state.chargement !== "fait") {
      this.state.markers.forEach((marker) => {
        this.state.bounds.extend(marker.position);
      });
    }

    return (
      <MapContainer
        center={userLocation}
        // ref="mapRef"
        // https://github.com/PaulLeCam/react-leaflet/issues/841#issuecomment-779048091
        whenCreated={ mapInstance => this.setState({mapRef: mapInstance}) }
        id="map-results"
        zoom={8}
        bounds={bounds}
        scrollWheelZoom={false}
        onmoveend={this.handleLoadCrushes}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup>
          <MyMarkersList markers={this.state.markers} />
        </MarkerClusterGroup>
        {userLocation[0] === 48.38 && userLocation[1] === 7.36 ? null : marker}
      </MapContainer>
    );
  }
}
