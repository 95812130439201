import { GET_USER_LOCATION } from '../constants/ActionTypes';

function userLocationReducer(state = {}, action) {
  switch (action.type) {
    case GET_USER_LOCATION:
      return { ...action.payload };
    default:
      return state;
  }
}

export default userLocationReducer;
