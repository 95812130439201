import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "html-react-parser";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import moment from "moment";
import qs from "qs";
import axios from "axios";
import Iframe from "react-iframe";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer } from "react-leaflet";
import ReactLeafletKml from "react-leaflet-kml";
import { API_URL } from "../constants/Api";
import {
  fetchCrush,
  fetchCrushesOfTheDay,
  removeCrush,
  fetchInspirations,
  fetchCities,
  getUserLocation,
  fetchAviziTerritory,
  fetchCrushesCatering,
} from "../actions";
import {
  getClosestCity,
  getCityTerritoryMatch,
  getSelectedDate,
  getDistanceFromCrush,
} from "../shared";
import { slugify, getRandomInt } from "../helpers";
import SubHeader from "../components/SubHeader";
import SearchBox from "../components/SearchBox";
import Loader from "../components/Loader";
import Slider from "../components/Slider";
import Crush from "../components/Crush";
import ModalRoot from "../containers/ModalRoot";
import Gtag from "../utils/Gtag";
import Translations from "../lang/translations.json";
import flagFR from "../../img/fr.svg";
import flagEN from "../../img/gb.svg";
import flagDE from "../../img/de.svg";
import FlatBox from "../components/FlatBox";
import Banner from "../components/Banner";
import passAlsace from "../../img/pass-alsace.png";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].article;
class Article extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.customAreaRef = React.createRef();
    this.state = {
      selectedDate: "",
      selectedCity: window.localStorage.getItem("selectedCity") || Texts.ou,
      selectedInspirationIndex: null,
      idArticle: props.match.params.articleTitle.split("-").pop(),
      userLocation: {},
      erreur: "",
      relatedCrushes: "",
      relatedCrushesContent: [],
      inspirations: false,
      loadingKml: false,
      kmlUrl: "",
      kml: "",
    };

    const self = this;

    if (typeof window !== undefined && window.addOswEvent) {
      window.addOswEvent(window, "load", function () {
        window.oswTrackView(self.state.idArticle);
      });
    }

    const eventName = "Vue article";
    const eventParameters = {
      category: eventName,
      action: this.state.idArticle,
    };

    Gtag.gtag("event", eventName, eventParameters);
  }

  componentDidMount() {
    // Get params from URL
    const qParam = this.getURLParam();
    let nextState = {};

    // Get user geolocation
    if (isEmpty(this.props.userLocation)) {
      this.props.getUserLocation((userLocation) => {
        const { latitude, longitude } = userLocation;
        this.setState({
          userLocation: {
            latitude,
            longitude,
          },
        });
      });
    }
    this.errorFetch(this.state.idArticle);
    // Fetch inspirations
    if (!this.props.inspirations.length) {
      this.props.fetchInspirations((inspirations) => {
        // Get the inspiration param from URL and update state
        if (qParam.inspiration !== undefined) {
          this.updateSelectedInspiration(qParam.inspiration, inspirations);
        }
      });
    } else {
      // Update inspiration state when we come from another page
      if (qParam.inspiration !== undefined) {
        this.updateSelectedInspiration(
          qParam.inspiration,
          this.props.inspirations
        );
      }
    }

    // Get the date from URL params and update the searchbox text
    if (qParam.jour !== undefined) {
      nextState = Object.assign(
        nextState,
        this.getUpdateForSelectedDate(qParam.jour)
      );
    }

    if (isEmpty(this.props.crush)) {
      this.props.fetchCrush(this.state.idArticle);
      nextState = Object.assign(nextState, this.crushOk());
    }

    // Fetch the crushes of the day on the article page
    if (!this.props.crushesOfTheDay.length) {
      this.fetchCrushesOfTheDay();
    }

    // Get the Avizi territory from URL params
    if (qParam["id-campaign-avizi"]) {
      this.updateAviziTerritory(qParam["id-campaign-avizi"]);
      window.localStorage.setItem("modalSubscribeStop", "true");
    }

    // Fetch the catering crushes to get the different catering categories
    if (!this.props.crushesCatering.length) {
      this.props.fetchCrushesCatering();
    }

    if (Object.keys(nextState).length) {
      this.setState(nextState);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEmpty(this.props.crush.produits_associes)) {
      const isInspirations = !isEmpty(
        this.props.crush.criteres.find((criteria) => criteria.id === 1901541)
      );
      this.renderRelatedCrushImg(
        this.props.crush.produits_associes,
        isInspirations
      );
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.removeCrush(this.state.idArticle);
      this.props.fetchCrush(
        this.props.match.params.articleTitle.split("-").pop()
      );
      if (navigator.geolocation) {
        const cities = this.props.cities;
        navigator.geolocation.getCurrentPosition(function (pos) {
          const { latitude, longitude } = pos.coords;
          const myPos = { latitude, longitude };
          const closestCity = getClosestCity(myPos, cities);
          if (this) {
            this.setState({
              closestCity: closestCity.commune,
            });
          }
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.removeCrush(this.state.idArticle);
    if (navigator.geolocation) {
      const cities = this.props.cities;
      navigator.geolocation.getCurrentPosition(function (pos) {
        const { latitude, longitude } = pos.coords;
        const myPos = { latitude, longitude };
        const closestCity = getClosestCity(myPos, cities);
        if (this) {
          this.setState({
            closestCity: closestCity.commune,
          });
        }
      });
    }
  }

  getURLParam() {
    const qString = this.props.location.search;
    const queryObj = qs.parse(qString, { ignoreQueryPrefix: true });

    return queryObj;
  }

  getUpdateForSelectedDate(dateParam) {
    var selectedDate = "";
    if (dateParam !== "") {
      selectedDate = getSelectedDate(dateParam);
    }

    return { selectedDate };
  }

  stats = (type, valeur) => {
    const eventName = "data-dnc-t" + type + " " + this.state.idArticle;
    const eventParameters = {
      category: eventName,
      action: type,
      label: this.state.idArticle,
    };

    Gtag.gtag("event", eventName, eventParameters);
  };

  updateSelectedInspiration(inspirationParam, inspirations) {
    const selectedInspirationIndex = inspirations.findIndex(
      (inspiration) => inspiration.slug === inspirationParam
    );

    this.setState({ selectedInspirationIndex });
  }

  updateAviziTerritory(aviziParam) {
    var selectedAviziTerritory;
    if (isEmpty(this.props.aviziTerritory)) {
      this.props.fetchAviziTerritory(aviziParam, (aviziTerritory) => {
        selectedAviziTerritory = aviziTerritory.Territoire[0];
        this.setState({ selectedAviziTerritory });
      });
    } else {
      selectedAviziTerritory = this.props.aviziTerritory.Territoire[0];
      this.setState({ selectedAviziTerritory });
    }
  }

  fetchCrushesOfTheDay() {
    // Get slugified territory from URL
    const qParam = this.getURLParam();
    const todayFormatted = moment().format("DD/MM/YYYY");
    const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");

    if (!this.props.cities.length) {
      this.props.fetchCities((cities) => {
        var territoryName, matchingCity, GRCTodayOne, GRCTodayTwoThree;
        // Get the territory from URL id-campaign-avizi param and find a matching city
        if (
          qParam["id-campaign-avizi"] &&
          qParam["date-routage-avizi"] &&
          this.state.erreur === "erreur"
        ) {
          if (!this.state.selectedAviziTerritory) {
            this.fetchCrushesOfTheDay();
            return;
          }

          territoryName = slugify(this.state.selectedAviziTerritory);
          // Add territory from campaign
          var searchParams = new URLSearchParams(this.props.location.search);
          searchParams.set("territoire", territoryName);
          this.props.history.push({
            search: "?" + searchParams.toString(),
          });

          this.getCrushes();
        }
        if (
          qParam["id-campaign-avizi"] &&
          qParam["date-routage-avizi"] &&
          this.state.erreur !== "erreur"
        ) {
          if (!this.state.selectedAviziTerritory) return null;
          const dateRoutage = moment
            .unix(qParam["date-routage-avizi"])
            .format("DD/MM/YYYY");
          const dateLendemain = moment
            .unix(qParam["date-routage-avizi"])
            .add(1, "days")
            .format("DD/MM/YYYY");
          territoryName = slugify(this.state.selectedAviziTerritory);
          matchingCity = getCityTerritoryMatch(cities, territoryName);
          // Add territory from campaign
          searchParams = new URLSearchParams(this.props.location.search);
          searchParams.set("territoire", territoryName);
          this.props.history.push({
            search: "?" + searchParams.toString(),
          });
          if (!matchingCity) return null;
          GRCTodayOne = matchingCity.metas._GRC_Quotidien_fiche_1[0];
          GRCTodayTwoThree = matchingCity.metas._GRC_Quotidien_fiche_2_et_3[0];
          // If we have a territory passed in the URL we fetch by GRC Criteria
          // console.log(
          //   "We have an id-campaign-avizi param in URL ==> Fetch with GRC Criteria"
          // );

          this.props.fetchCrushesOfTheDay(
            3,
            GRCTodayOne,
            GRCTodayTwoThree,
            dateLendemain,
            dateRoutage
          );
        }

        // Get the territory from URL params and find a matching city
        else if (qParam.territoire && qParam.territoire !== "autour-de-moi") {
          const territoryName = qParam.territoire;
          const matchingCity = getCityTerritoryMatch(cities, territoryName);
          const GRCTodayOne = matchingCity.metas._GRC_Quotidien_fiche_1[0];
          const GRCTodayTwoThree =
            matchingCity.metas._GRC_Quotidien_fiche_2_et_3[0];
          // If we have a territory passed in the URL we fetch by GRC Criteria
          // console.log(
          //   "We have a territory param in URL ==> Fetch with GRC Criteria"
          // );

          this.props.fetchCrushesOfTheDay(
            3,
            GRCTodayOne,
            GRCTodayTwoThree,
            todayFormatted,
            yesterday
          );
        } else {
          // Otherwise we try to fetch according to the user geolocation
          this.fetchWithCoords(cities);
        }
      });
    } else {
      const cities = this.props.cities;
      if (qParam.territoire && qParam.territoire !== "autour-de-moi") {
        const territoryName = qParam.territoire;
        const matchingCity = getCityTerritoryMatch(cities, territoryName);
        const GRCTodayTwoThree =
          matchingCity.metas._GRC_Quotidien_fiche_2_et_3[0];

        // If we have a territory passed in the URL we fetch by GRC Criteria
        // console.log(
        //   "We have a territory param in URL ==> Fetch with GRC Criteria"
        // );

        this.props.fetchCrushesOfTheDay(3, GRCTodayTwoThree, todayFormatted);
      } else {
        // Otherwise we try to fetch according to the user geolocation
        this.fetchWithCoords(cities);
      }
    }
  }

  fetchWithCoords(cities) {
    const that = this;
    const todayFormatted = moment().format("DD/MM/YYYY");
    const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          // console.log(
          //   "No territory param in URL and user allowed geolocation ==> Find territory with coordinates"
          // );
          const { latitude, longitude } = pos.coords;
          const myPos = { latitude, longitude };
          const closestCity = getClosestCity(myPos, cities);
          const GRCTodayOne = closestCity.metas._GRC_Quotidien_fiche_1[0];
          const GRCTodayTwoThree =
            closestCity.metas._GRC_Quotidien_fiche_2_et_3[0];

          that.setState({
            closestCity: closestCity.commune,
          });
          that.props.fetchCrushesOfTheDay(
            3,
            GRCTodayOne,
            GRCTodayTwoThree,
            todayFormatted,
            yesterday
          );
        },
        function (err) {
          // console.log(err);
          // If the user does not allow his geolocation and we don't have a territorry in the URL
          // We fetch the crushes on the territory of RANDOM

          const randomCity = getRandomInt(0, cities.length);
          const GRCTodayOne =
            cities[randomCity].metas._GRC_Quotidien_fiche_1[0];
          const GRCTodayTwoThree =
            cities[randomCity].metas._GRC_Quotidien_fiche_2_et_3[0];
          // console.log(
          //   "No territory param in URL and user did not allow geolocation ==> Fetch with RANDOM city : " +
          //     cities[randomCity].commune +
          //     " (" +
          //     cities[randomCity].metas._Territoire +
          //     ") GRC Criteria"
          // );
          that.props.fetchCrushesOfTheDay(
            3,
            GRCTodayOne,
            GRCTodayTwoThree,
            todayFormatted,
            yesterday
          );
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }

  renderRelatedCrush(crush, inspirations) {
    const id = crush.id;
    const title = crush.nom;
    const location =
      crush.coordonnees !== undefined
        ? crush.coordonnees.libelle_commune
        : null;
    const imgURL =
      crush.imgURL !== undefined
        ? `https://${crush.imgURL
            .replace("https://", "")
            .replace("http://", "")}`
        : null;
    const imgTitle =
      crush.criteres !== undefined &&
      crush.criteres[0] !== undefined &&
      crush.criteres[0].valeur !== undefined
        ? crush.criteres[0].nom
        : null;

    let searchParams = new URLSearchParams(this.props.location.search);
    inspirations
      ? searchParams.set("inspirations", true)
      : searchParams.delete("inspirations");
    const link = title
      ? `/article/${slugify(title)}-${id}?${searchParams}`
      : `/article/${id}`;
    return (
      <Crush
        key={id}
        type="small"
        title={title}
        link={link}
        location={location}
        imgURL={imgURL}
        imgTitle={imgTitle}
      />
    );
  }

  renderCrushesOfTheDay() {
    const crushesOfTheDay = this.props.crushesOfTheDay;

    if (!crushesOfTheDay.length) {
      return <Loader loading={true} text="Chargement..." />;
    } else {
      return (
        <div className="crushes__list crushes__list--three">
          {crushesOfTheDay.map(this.renderCrush, this)}
        </div>
      );
    }
  }

  renderCrush(crush, index, inspirations) {
    const id = crush.id;
    const title = crush.nom;
    const location =
      crush.coordonnees !== undefined
        ? crush.coordonnees.libelle_commune
        : null;

    if (crush.criteres !== undefined && crush.criteres.length) {
      var criteriaImgURL = crush.criteres.find(
        (criteria) => criteria.id === 1900421
      );
      var criteriaImgALT = crush.criteres.find(
        (criteria) => criteria.id === 1900480
      );
      var criteriaDesc = crush.criteres.find(
        (criteria) => criteria.id === 1901440
      );
    }

    const imgURL = criteriaImgURL
      ? `https://${criteriaImgURL.valeur
          .replace("https://", "")
          .replace("http://", "")}`
      : null;

    const imgTitle = criteriaImgALT ? criteriaImgALT.valeur : null;

    const desc = criteriaDesc ? criteriaDesc.valeur : null;
    let searchParams = new URLSearchParams(this.props.location.search);
    searchParams.delete("inspirations");
    const link = title
      ? `/article/${slugify(title)}-${id}?${searchParams}`
      : `/article/${id}`;
    const type = index === 0 ? "big-on-mobile" : "small";
    const latitude = crush.latitude !== undefined ? crush.latitude : null;
    const longitude = crush.longitude !== undefined ? crush.longitude : null;

    return (
      <Crush
        key={id}
        type={type}
        title={title}
        link={link}
        location={location}
        imgURL={imgURL}
        imgTitle={imgTitle}
        desc={desc}
        latitude={latitude}
        longitude={longitude}
        userPos={this.state.userLocation}
      />
    );
  }

  renderPrices() {
    const crush = this.props.crush;
    const criterias = crush.criteres ? crush.criteres : [];
    const prices = criterias.find((criteria) => criteria.id === 1900529);
    var priceItems;
    var unite;
    if (prices) {
      priceItems = prices.modalites.map((price) => {
        if (price.valeur === "0") {
          price.valeur = Texts.gratuit;
          unite = "";
        } else if (price.valeur > 0) {
          unite = "€";
        }
        return (
          <li key={price.nom} className="price__item">
            <span className="price__category">{price.nom}</span>
            <span className="price__value">
              {price.valeur} {unite}
            </span>
          </li>
        );
      });

      return (
        <section className="price">
          <div className="price__inner">
            <h2 className="price__title">{Texts.tarifs}</h2>
            <ul className="price__list">{priceItems}</ul>
          </div>
        </section>
      );
    } else {
      return false;
    }
  }
  errorFetch(article) {
    const resolvedProm = Promise.resolve(this.props.fetchCrush(article));

    let thenProm = resolvedProm.then((value) => {
      this.setState({ erreur: value });
    });

    setTimeout(() => {
      console.log(thenProm);
    });
  }
  crushOk() {
    return { erreur: "non" };
  }

  async getKml(circuit) {
    this.setState({ loadingKml: true }, () => {
      return axios
        .get(`${API_URL}/liesel/v1/kml`, {
          params: { url: circuit },
        })
        .then((response) => {
          const kmlText = response.data;
          const parser = new DOMParser();
          const kml = parser.parseFromString(kmlText, "text/xml");
          if (kmlText.substr(0, 5) === "<?xml") {
            this.setState({ kml: kml });
          }
          if (
            this.mapRef !== null &&
            this.mapRef.current !== null &&
            this.customAreaRef !== null &&
            this.customAreaRef.current !== null &&
            this.state.kml !== ""
          ) {
            //we will get inside just once when loading
            const map = this.mapRef.current.leafletElement;
            const layer = this.customAreaRef.current.leafletElement;
            if (layer && map) {
              map.fitBounds(layer.getBounds().pad(0.5));
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          return Promise.reject(error);
        });
    });
  }

  getCrushes() {
    const cities = this.props.cities;
    const todayFormatted = moment().format("DD/MM/YYYY");
    const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
    if (this.state.selectedAviziTerritory === undefined) {
      return;
    }

    const territoryName = slugify(this.state.selectedAviziTerritory);
    const matchingCity = getCityTerritoryMatch(cities, territoryName);

    if (!isObject(matchingCity)) {
      return;
    } else {
      var stop = true;
    }

    const GRCTodayOne = matchingCity.metas._GRC_Quotidien_fiche_1[0];
    const GRCTodayTwoThree = matchingCity.metas._GRC_Quotidien_fiche_2_et_3[0];
    if (!stop) {
      this.props.fetchCrushesOfTheDay(
        3,
        GRCTodayOne,
        GRCTodayTwoThree,
        todayFormatted,
        yesterday
      );
    }
  }

  renderCateringFilterBox() {
    let params = this.props.location;
    const names = [
      "Saveurs d'Alsace",
      "Tables d'exception",
      "Vite et bon",
      "D’autres envies",
    ];
    return names.map((name) => {
      return (
        <FlatBox
          key={slugify(name)}
          type="eat"
          isActive={false}
          href={slugify(name)}
          category={name}
          params={params}
        />
      );
    });
  }

  async getData(id) {
    const SCHEMA =
      window.localStorage.getItem("lang") === null ||
      window.localStorage.getItem("lang") === "FR"
        ? "WEBACCESS"
        : `WEBACCESS_${window.localStorage.getItem("lang")}`;
    const res = await axios(
      `https://apps.tourisme-alsace.info/xml/exploitation/ficheproduit.asp?user=2002231&pwkey=30af3b324c5c84bd969807a3bfe44ab6&urlnames=tous&lxml=sit_fiche&SCHEMA=${SCHEMA}&editer=211&leschamps=ADRPROD_NUMERO,ADRPROD_NUM_VOIE,ADRPROD_COMPL_ADRESSE,ADRPROD_CP,ADRPROD_LIBELLE_COMMUNE,ADRPROD_TEL,ADRPROD_EMAIL,ADRPROD_URL,ADRPROD_PAYS,Commentaire,CommentaireL1&lescritex=1901485,1900421,1900480,1900603,1900661,1900751,1900752,1900781,1900783,1900782,1900784,1900861,1900866,1900862,1900867,1900863,1900868,1900946,1900948,1900947,1900949,1900958,1900959,1900960,1900961,1900962,1900963,1900964,1900965,1900966,1900967,1901401,1901402,1901440,1900433,1900529*1903973,1900529*1903974,1900267,1901656,1901348,1900268,1901498,1900067,1900825*1907169,1901536*1911822,1901537,1901285*1911036&typsor=6&produit=${id}`
    );
    return await res.data.product;
  }
  renderRelatedCrushes(relatedCrushesContent, isInspirations) {
    return relatedCrushesContent.map((crush) => {
      if (crush === undefined) {
        return null;
      }
      const img =
        crush.criteres !== undefined
          ? crush.criteres.find((criteria) => criteria.id === 1900421)
          : "";
      crush.imgURL = img.valeur;
      return this.renderRelatedCrush(crush, isInspirations);
    });
  }
  renderRelatedCrushImg(relatedCrushes, isInspirations) {
    if (this.state.relatedCrushes === "done") {
      return;
    }
    relatedCrushes.forEach((relatedCrush) => {
      (async () => {
        try {
          const article = await this.getData(relatedCrush.id);
          this.setState((prevState) => ({
            relatedCrushesContent: [
              ...prevState.relatedCrushesContent,
              article,
            ],
          }));
        } catch (e) {
          // console.log(e, "getdata");
        }
      })();
    });
    this.setState({ relatedCrushes: "done" });
    if (isInspirations && isInspirations !== undefined) {
      this.setState({ inspirations: this.props.location.pathname });
    }
  }

  render() {
    if (isEmpty(this.props.crush)) {
      return (
        <main className="main main--article">
          <SubHeader
            title={Texts.mes_coups_de_coeur}
            link={Texts.retour_liste}
            hasBorder={true}
          />
          <div className="searchboxes" id="viewContent">
            <div className="searchboxes__inner">
              <SearchBox
                type="date"
                isActive={false}
                selectedDate={this.state.selectedDate}
              />
              <SearchBox
                type="location"
                isActive={false}
                selectedCity={this.state.selectedCity}
              />
              <ModalRoot />
            </div>
          </div>
          {this.props.inspirations.length > 0 && (
            <div className="tns tns--filter">
              <Slider
                type="filter"
                data={this.props.inspirations}
                handleOnClick={this.updateSelectedInspiration}
                selectedIndex={this.state.selectedInspirationIndex}
              />
            </div>
          )}
          {this.state.erreur === "erreur" && this.getCrushes()}

          {this.state.erreur === "erreur" &&
            this.props.crushesOfTheDay &&
            this.props.crushesOfTheDay.length >= 1 && (
              <section className="crushes">
                <div className="crushes__inner">
                  <h2 className="crushes__title">{Texts.desole}</h2>
                  {this.renderCrushesOfTheDay()}
                </div>
              </section>
            )}
          {this.state.erreur === undefined &&
            this.props.crushesOfTheDay === undefined && (
              <Loader loading={true} text="Chargement..." />
            )}
        </main>
      );
    }

    const crush = this.props.crush;

    const title = crush.nom;
    const city =
      crush.coordonnees.libelle_commune !== undefined
        ? crush.coordonnees.libelle_commune
        : null;
    const commentOne =
      crush.commentaire !== undefined ? crush.commentaire : null;
    let document =
      crush.documentation1 !== undefined ? crush.documentation1 : null;
    document = document && !document.includes("https://")
      ? `https://${document}`
      : document;
    const reservation = crush.criteres.find(
      (criteria) => criteria.id === 1900825
    );
    const reservation_lien =
      crush.criteres.find((criteria) => criteria.id === 1900825) !==
        undefined && reservation.modalites[0].valeur.trim() !== ""
        ? reservation.modalites[0].valeur
        : "";

    const membre = crush.criteres.find((criteria) => criteria.id === 1901285);

    const pass_alsace =
      crush.criteres.find((criteria) => criteria.id === 1901285) !==
        undefined && membre.modalites[0].nom.trim() !== ""
        ? membre.modalites[0].nom
        : "";

    const mesures_protections = crush.criteres.find(
      (criteria) => criteria.id === 1901536
    );

    const reservation_obligatoire =
      crush.criteres.find((criteria) => criteria.id === 1901536) !==
        undefined && mesures_protections.modalites[0].nom.trim() !== ""
        ? mesures_protections.modalites[0].nom
        : "";

    const commentaire_mesures = crush.criteres.find(
      (criteria) => criteria.id === 1901537
    );

    const descriptif_inspirations = crush.criteres.find(
      (criteria) => criteria.id === 1901541
    );

    const addressNb =
      crush.coordonnees.num_voie !== undefined
        ? crush.coordonnees.num_voie
        : '';
    const addressSt =
      crush.coordonnees.libelle_voie !== undefined
        ? crush.coordonnees.libelle_voie
        : null;
    const addressStart =
      addressSt !== null
        ? `${addressNb} ${addressSt}`
        : null;
    const postCode =
      crush.coordonnees.code_postal !== undefined
        ? crush.coordonnees.code_postal
        : null;
    const addressEnd =
      postCode !== null && city !== null ? `${postCode} ${city}` : null;

    const emailOne =
      crush.coordonnees.email1 !== undefined ? crush.coordonnees.email1 : null;
    const emailTwo =
      crush.coordonnees.email2 !== undefined ? crush.coordonnees.email2 : null;

    const telephoneOne =
      crush.coordonnees.telephone1 !== undefined &&
      crush.coordonnees.pays === "FRANCE"
        ? crush.coordonnees.telephone1.replace("0", "+33 ")
        : crush.coordonnees.telephone1;
    const telephoneTwo =
      crush.coordonnees.telephone2 !== undefined &&
      crush.coordonnees.pays === "FRANCE"
        ? crush.coordonnees.telephone2.replace("0", "+33 ")
        : crush.coordonnees.telephone2;
    const country = crush.coordonnees.pays;
    const urlOne = crush.coordonnees.url1;
    const relatedCrushes =
      crush.produits_associes !== undefined ? crush.produits_associes : [];
    const photoIDs = [
      1900421, 1900603, 1900751, 1900781, 1900782, 1900861, 1900862, 1900863,
      1900946, 1900947, 1900958, 1900960, 1900962, 1900964, 1900966, 1901401,
    ];

    const creditPhotoIDs = [
      1900480, 1900661, 1900752, 1900783, 1900784, 1900866, 1900867, 1900868,
      1900948, 1900949, 1900959, 1900961, 1900963, 1900965, 1900967, 1901402,
    ];

    const criterias = crush.criteres ? crush.criteres : [];
    const typeRestaurant = [1900014, 1900156, 1900017, 1900219];
    const isRestaurant =
      crush.type && typeRestaurant.includes(crush.type.id) ? true : false;

    /**
     * Circuits
     */
    const typeCircuit = [1900009, 1900045, 1900316];
    const isCircuit =
      crush.type && typeCircuit.includes(crush.type.id) ? true : false;

    const circuit = crush.criteres.find((criteria) => criteria.id === 1901505);
    const circuitLongueur = crush.criteres.find(
      (criteria) => criteria.id === 1900916
    );
    const circuitDuree = crush.criteres.find(
      (criteria) => criteria.id === 1900487
    );
    const circuitLieu = crush.criteres.find(
      (criteria) => criteria.id === 1900219
    );
    const circuitDifficulte = crush.criteres.find(
      (criteria) => criteria.id === 1900564
    );
    const circuitPoussette = crush.criteres.find(
      (criteria) => criteria.id === 1901348
    );
    const circuitMateriel = crush.criteres.find(
      (criteria) => criteria.id === 1900621
    );
    const circuitKml = crush.criteres.find(
      (criteria) => criteria.id === 900002
    );
    if (circuitKml && circuitKml.valeur) {
      if (this.state.kml === "" && this.state.loadingKml === false) {
        this.getKml(circuitKml.valeur);
      }
    }

    const slides = criterias.filter(
      (criteria) => photoIDs.includes(criteria.id) && criteria.valeur
    );
    const slidesCredits = criterias.filter(
      (criteria) => creditPhotoIDs.includes(criteria.id) && criteria.valeur
    );

    const hasFamilyPicto = crush.criteres.find(
      (criteria) => criteria.id === 1901498
    );
    const classFamilyPicto = hasFamilyPicto ? "ico-family" : "";

    const latitude = crush.latitude !== undefined ? crush.latitude : null;
    const longitude = crush.longitude !== undefined ? crush.longitude : null;

    if (latitude && longitude && !isEmpty(this.state.userLocation)) {
      const crushPos = { latitude, longitude };
      var distanceFromCrush = getDistanceFromCrush(
        crushPos,
        this.state.userLocation
      );
    }
    if (crush.type.id === 1900014 || crush.type.id === 1900219) {
      var SubHeaderTitle = "";
    } else {
      SubHeaderTitle = Texts.mes_coups_de_coeur;
    }
    let aujourdhui = moment().format("DD/MM/YYYY");
    let langCalendar;
    switch (lang) {
      case "FR":
        langCalendar = 0;
        break;
      case "EN":
        langCalendar = 1;
        break;
      case "DE":
        langCalendar = 2;
        break;
      default:
        langCalendar = 0;
        break;
    }

    let iframeUrl = `https://apps.tourisme-alsace.info/communs/calendar/widget.asp?produit=${this.state.idArticle}&color=2880b9&date=${aujourdhui}&vue=s&langue=${langCalendar}`;

    const qParam = this.getURLParam();
    if (qParam["id-campaign-avizi"] && this.state.erreur !== "erreur") {
      iframeUrl = `https://apps.tourisme-alsace.info/communs/calendar/widget.asp?produit=${this.state.idArticle}&color=2880b9&date=&vue=s&langue=${langCalendar}`;
    }

    let criteriaLanguage = crush.criteres.find(
      (criteria) => criteria.id === 1900067
    );
    const currentUrl = `${window.location.protocol}//${window.location.host}${this.props.location.pathname}`;
    const languageSpoken = criteriaLanguage;
    let userLocation = this.state.userLocation;

    const criteriaVigneron = crush.criteres.find(
      (criteria) => criteria.id === 1900489
    );
    let params = new URLSearchParams(this.props.location.search);

    return (
      <main className="main main--article">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Liesel - {title}</title>
          <link
            rel="canonical"
            href={`${
              currentUrl && this.props.location.pathname ? currentUrl : ""
            }`}
          />
        </Helmet>
        <SubHeader
          title={SubHeaderTitle}
          link={Texts.retour_liste}
          hasBorder={true}
          closestCity={this.state.closestCity}
        />
        <div>{this.closestCity}</div>
        <div className="searchboxes" id="viewContent">
          <div className="searchboxes__inner">
            <SearchBox
              type="date"
              isActive={false}
              selectedDate={this.state.selectedDate}
            />
            <SearchBox
              type="location"
              isActive={false}
              selectedCity={this.state.selectedCity}
            />
            <ModalRoot />
          </div>
        </div>
        {isRestaurant && crush && (
          <div id="viewContent">
            <div className="hopla">
              <div className="hopla__inner">
                <Banner
                  userLocation={userLocation}
                  selectedCity={this.state.selectedCity}
                />
              </div>
            </div>
            <div className="boxfilter">
              <div className="boxfilter__boxes">
                {this.renderCateringFilterBox()}
              </div>
            </div>
          </div>
        )}
        {this.props.inspirations.length > 0 && false === isRestaurant && (
          <div className="tns tns--filter">
            <Slider
              type="filter"
              data={this.props.inspirations}
              handleOnClick={this.updateSelectedInspiration}
              selectedIndex={this.state.selectedInspirationIndex}
            />
          </div>
        )}
        {slides.length > 0 && (
          <div className="tns tns--static">
            <Slider
              type="static"
              data={slides}
              credits={slidesCredits}
              nav={true}
              edgePaddingMobile={20}
              edgePaddingDesktop={150}
            />
          </div>
        )}

        <section className="generic-content">
          <div className="generic-content__block">
            <div className="generic-content__heading">
              {title && <h3 className={`${classFamilyPicto}`}>{title}</h3>}
              {city && (
                <h5>
                  {city}{" "}
                  {distanceFromCrush > 0
                    ? ` (${Texts.a} ${distanceFromCrush} ${Texts.km_de_vous})`
                    : ""}
                  {distanceFromCrush === "proximité"
                    ? ` (${Texts.a} ${distanceFromCrush} ${Texts.de_votre_position})`
                    : ""}
                </h5>
              )}
            </div>
            {commentOne && <div>{ReactHtmlParser(commentOne)}</div>}
            {isCircuit && circuit && (
              <ul className="list circuit">
                <p>{Texts.type_de_circuit}</p>
                {circuit.modalites.map((elem, index) => {
                  return elem.nom !== undefined ? (
                    <li key={index}>{elem.nom}</li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            )}

            {isCircuit && circuitLongueur && (
              <ul className="list circuit">
                <p>{Texts.circuit_longueur}</p>
                <li>{circuitLongueur.valeur} km</li>
              </ul>
            )}
            {isCircuit && circuitDuree && (
              <ul className="list circuit">
                <p>{Texts.circuit_duree}</p>
                {circuitDuree.modalites.map((elem, index) => {
                  return elem.nom !== undefined ? (
                    <li key={index}>{elem.nom}</li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            )}
            {isCircuit && circuitLieu && (
              <ul className="list circuit">
                <p>{Texts.circuit_lieu}</p>
                <li>{circuitLieu.valeur}</li>
              </ul>
            )}
            {isCircuit && circuitDifficulte && (
              <ul className="list circuit">
                <p>{Texts.circuit_difficulte}</p>
                {circuitDifficulte.modalites.map((elem, index) => {
                  return elem.nom !== undefined ? (
                    <li key={index}>{elem.nom}</li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            )}
            {isCircuit && circuitPoussette && (
              <ul className="list circuit">
                <p>{Texts.circuit_poussette}</p>
                {circuitPoussette.modalites.map((elem, index) => {
                  return elem.nom !== undefined ? (
                    <li key={index}>{elem.nom}</li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            )}
            {isCircuit && circuitMateriel && (
              <ul className="list circuit">
                <p>{Texts.circuit_materiel}</p>
                {circuitMateriel.modalites.map((elem, index) => {
                  return elem.nom !== undefined ? (
                    <li key={index}>{elem.nom}</li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            )}
            {circuitKml && this.state.kml !== "" && (
              <MapContainer zoom={7} center={[48.38, 7.36]} ref={this.mapRef}>
                {this.state.kml !== "" && (
                  <ReactLeafletKml
                    kml={this.state.kml}
                    ref={this.customAreaRef}
                  />
                )}
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
              </MapContainer>
            )}

            {criteriaVigneron && (
              <ul className="list">
                <p>{Texts.prestations_proposees}</p>
                {criteriaVigneron.modalites.map((elem, index) => {
                  return elem.nom !== undefined ? (
                    <li key={index}>{elem.nom}</li>
                  ) : (
                    ""
                  );
                })}
              </ul>
            )}

            {languageSpoken && (
              <div>
                {`${languageSpoken.nom} : `}
                {languageSpoken.modalites.map(function (element, index) {
                  let FR =
                    element.nom === "Français" ||
                    element.nom === "French" ||
                    element.nom === "Französisch"
                      ? true
                      : false;
                  let EN =
                    element.nom === "Anglais" ||
                    element.nom === "English" ||
                    element.nom === "Englisch"
                      ? true
                      : false;
                  let DE =
                    element.nom === "Allemand" ||
                    element.nom === "German" ||
                    element.nom === "Deutsch"
                      ? true
                      : false;
                  if (FR) {
                    return (
                      <img
                        src={flagFR}
                        alt={element.nom}
                        className="flag"
                        key={index}
                      />
                    );
                  } else if (EN) {
                    return (
                      <img
                        src={flagEN}
                        alt={element.nom}
                        className="flag"
                        key={index}
                      />
                    );
                  } else if (DE) {
                    return (
                      <img
                        src={flagDE}
                        alt={element.nom}
                        className="flag"
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}
            {document && (
              <div className="btn-group__inner">
                <a
                  href={`${document.replace("http://", "")}`}
                  className="btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  {Texts.telecharger}
                </a>
              </div>
            )}
            {(reservation_lien || pass_alsace) && (
              <div className="btn-group__inner">
                {reservation_lien !==
                  "https://www.pass-alsace.com/boutique" && (
                  <a
                    href={`${reservation_lien}`}
                    className="btn-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {Texts.reserver}
                  </a>
                )}

                {pass_alsace && (
                  <a
                    href="https://www.pass-alsace.com/boutique?liesel"
                    className=""
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={passAlsace}
                      alt="Pass Alsace"
                      className="img pass-alsace"
                    />
                  </a>
                )}
              </div>
            )}
            {params.has("inspirations", true) &&
              this.state.inspirations &&
              this.state.inspirations !== undefined &&
              this.state.inspirations !== this.props.location.pathname && (
                <div className="btn-group__inner">
                  <Link
                    to={{
                      pathname: this.state.inspirations,
                      search: this.props.location.search,
                    }}
                    title="Retour à l'inspiration"
                    className="btn-primary"
                  >
                    Retour à l'inspiration
                  </Link>
                </div>
              )}
          </div>
        </section>

        {relatedCrushes.length > 0 && this.props.crush.type.id === 1900319 && (
          <section className="crushes">
            <div className="crushes__inner">
              <h2 className="crushes__title">Inspirations</h2>
              <section className="generic-content">
                <div className="generic-content__block">
                  {descriptif_inspirations.modalites.map((elem, index) => {
                    return elem.valeur !== undefined ? (
                      <p key={index}>{elem.valeur}</p>
                    ) : (
                      ""
                    );
                  })}
                </div>
              </section>
              <div className="crushes__list crushes__list--two">
                {this.state.relatedCrushesContent.length < 1 ? (
                  <Loader loading={true} text="Chargement..." />
                ) : (
                  this.renderRelatedCrushes(
                    this.state.relatedCrushesContent,
                    true
                  )
                )}
              </div>
            </div>
          </section>
        )}

        {aujourdhui && iframeUrl && !isCircuit && (
          <section className="schedules">
            <div className="schedules__inner">
              <h2 className="schedules__title">{Texts.horaires_ouverture}</h2>
              <Iframe
                url={iframeUrl}
                width="650px"
                height="450px"
                id="iframe__horaires"
                className="horaires"
                display="initial"
                position="relative"
              />
            </div>
          </section>
        )}

        {(reservation_obligatoire || commentaire_mesures) && (
          <section className="details">
            <div className="details__inner">
              <div className="information__complementaire">
                <h2 className="details__title">
                  {Texts.information_complementaire}
                </h2>
                {reservation_obligatoire && (
                  <p className="reservation__obligatoire">
                    {Texts.reservation_obligatoire}
                  </p>
                )}

                {isObject(commentaire_mesures) &&
                  commentaire_mesures.valeur !== undefined &&
                  commentaire_mesures.valeur.trim() !== "" && (
                    <div>{ReactHtmlParser(commentaire_mesures.valeur)}</div>
                  )}
              </div>
            </div>
          </section>
        )}

        {this.renderPrices()}

        <section className="details">
          <div className="details__inner">
            <h2 className="details__title">{Texts.localiser_contacter}</h2>
            <ul className="details__list">
              {(addressStart || addressEnd) && (
                <li className="details__item details__item--location">
                  <address>
                    {addressStart && <span>{addressStart}</span>}
                    {addressStart && addressEnd && <br />}
                    {addressEnd && <span>{addressEnd}</span>}
                    {country !== "FRANCE" && <p>{country}</p>}
                  </address>
                </li>
              )}
              {(telephoneOne || telephoneTwo) && (
                <li className="details__item details__item--tel">
                  <div>
                    {telephoneOne && (
                      <a
                        href={`tel:${telephoneOne.replace(/\s/g, "")}`}
                        title=""
                        onClick={() => this.stats("tel", telephoneOne)}
                      >
                        {telephoneOne}
                      </a>
                    )}
                    {telephoneTwo && (
                      <div>
                        <a
                          href={`tel:${telephoneTwo.replace(/\s/g, "")}`}
                          title=""
                          onClick={() => this.stats("tel", telephoneTwo)}
                        >
                          {telephoneTwo}
                        </a>
                      </div>
                    )}
                  </div>
                </li>
              )}
              {(emailOne || emailTwo) && (
                <li className="details__item details__item--mail">
                  <div>
                    {emailOne && (
                      <a
                        href={`mailto:${emailOne}?subject=${Texts.demande_informations}`}
                        title=""
                        onClick={() => this.stats("email", emailOne)}
                      >
                        {emailOne}
                      </a>
                    )}
                    {emailTwo && (
                      <div>
                        <a
                          href={`mailto:${emailTwo}?subject=${Texts.demande_informations}`}
                          title=""
                          onClick={() => this.stats("email", emailTwo)}
                        >
                          {emailTwo}
                        </a>
                      </div>
                    )}
                  </div>
                </li>
              )}
              {urlOne && (
                <li className="details__item details__item--website">
                  <div>
                    <a
                      href={`https://${urlOne.replace(/(^\w+:|^)\/\//, "")}`}
                      target="_blank"
                      title=""
                      onClick={() => this.stats("url", urlOne)}
                      rel="noreferrer"
                    >
                      {urlOne}
                    </a>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </section>

        <div className="button-group">
          <div className="btn-group__inner">
            <a
              href={`https://maps.google.com/?q=${parseFloat(
                crush.latitude.toString().replace(",", ".")
              )},${parseFloat(crush.longitude.toString().replace(",", "."))}`}
              title={Texts.sy_rendre}
              className="btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              {Texts.sy_rendre}
            </a>
            {crush.type.id !== 1900014 && (
              <Link
                to={`../resultats?manger-a-proximite=1&lat=${latitude}&lon=${longitude}`}
                title={Texts.manger_proximite}
                className="btn-primary btn-primary--eat"
              >
                {Texts.manger_proximite}
              </Link>
            )}
          </div>
        </div>
        {relatedCrushes.length > 0 && relatedCrushes[0].type_couplage === 5000 && (
          <section className="crushes">
            <div className="crushes__inner">
              <h2 className="crushes__title">{Texts.mes_recommandations}</h2>
              {this.props.location.pathname.includes("230110051") && (
                <div className="crushes__list crushes__list--two">
                  {this.state.relatedCrushesContent.length < 10 ? (
                    <Loader loading={true} text="Chargement..." />
                  ) : (
                    this.renderRelatedCrushes(this.state.relatedCrushesContent)
                  )}
                </div>
              )}
              {!this.props.location.pathname.includes("230110051") && (
                <div className="crushes__list crushes__list--two">
                  {this.state.relatedCrushesContent.length < 1 ? (
                    <Loader loading={true} text="Chargement..." />
                  ) : (
                    this.renderRelatedCrushes(this.state.relatedCrushesContent)
                  )}
                </div>
              )}
            </div>
          </section>
        )}

        {this.props.crushesOfTheDay && this.props.crushesOfTheDay.length > 1 && (
          <section className="crushes">
            <div className="crushes__inner">
              <h2 className="crushes__title">
                {Texts.mes_coups_de_coeur_jour}
              </h2>
              {this.renderCrushesOfTheDay()}
            </div>
          </section>
        )}
      </main>
    );
  }
}

function mapStateToProps({
  crush,
  removeCrush,
  crushesOfTheDay,
  inspirations,
  cities,
  userLocation,
  closestCity,
  crushesCatering,
}) {
  return {
    crush,
    removeCrush,
    crushesOfTheDay,
    inspirations,
    cities,
    userLocation,
    closestCity,
    crushesCatering,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCrush,
      removeCrush,
      fetchCrushesOfTheDay,
      fetchInspirations,
      fetchCities,
      getUserLocation,
      fetchAviziTerritory,
      fetchCrushesCatering,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
