import React from 'react';

const City = props => {
  const classes = props.isSelected ? 'city is-active' : 'city';
  return (
    <li className={classes} onClick={props.onCityToggle}>
      <div className="city__inner">
        <span className="city__distance">{props.distance && props.distance + ' km'}</span>
        <span className="city__txt">{props.name} {props.departement && '('+props.departement+')'}</span>
      </div>
    </li>
  );
};

export default City;
