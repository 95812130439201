import { FETCH_HOPLA, FETCH_MORE_HOPLA } from '../constants/ActionTypes';

function crushesHoplaReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_MORE_HOPLA:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          next: action.payload.pagination.next
        },
        products: [...state.products, ...action.payload.products]
      };
    case FETCH_HOPLA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default crushesHoplaReducer;
