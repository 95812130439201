import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { addDays, addYears, format, toDate } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");

class renderDatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: "",
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      dateMinimale: new Date(),
      dateMaximale: addYears(new Date(), 1),
    };
  }

  componentDidMount() {
    const { input } = this.props;
    this.setState({
      dateMinimale:
        input.name === "dateStart" ? new Date() : addDays(new Date(), 1),
      dateMaximale:
        input.name === "dateStart"
          ? addYears(new Date(), 1)
          : addYears(new Date(), 1),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.dateStart) {
      const splitedDate = this.props.dateStart.split("/");
      const year = parseInt(splitedDate[2]);
      const month = parseInt(splitedDate[1]) - 1;
      const day = parseInt(splitedDate[0]);
      const newDate = toDate(new Date(year, month, day));

      const newDateMinimale = addDays(newDate, 1);
      const newDateMaximale = addYears(newDate, 1);

      this.setState({
        dateMinimale: newDateMinimale,
        dateMaximale: newDateMaximale,
      });
    }
  }

  handleChange(date) {
    this.props.input.onChange(format(date, "dd/MM/yyyy"));
  }

  render() {
    const {
      input,
      id,
      placeholder,
      labelTxt,
      meta: { touched, error },
    } = this.props;
    const { dateMinimale, dateMaximale } = this.state;

    return (
      <div className="form__row">
        <label className="form__label form__label--outside" htmlFor={id}>
          {labelTxt}
        </label>
        <DatePicker
          {...input}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          minDate={dateMinimale}
          maxDate={dateMaximale}
          selected={null}
          onChange={this.handleChange}
          autoComplete="off"
          // isClearable={true}
          locale={lang}
          className="form__input"
        />
        {touched && error && <span>{error}</span>}
      </div>
    );
  }
}

export default renderDatePicker;
