import { FETCH_CRUSH, REMOVE_CRUSH } from '../constants/ActionTypes';

function crushReducer(state = {}, action) {
  switch (action.type) {
    case REMOVE_CRUSH:
      return {};
    case FETCH_CRUSH:
      return { ...action.payload.product };
    default:
      return state;
  }
}

export default crushReducer;
