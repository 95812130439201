import React from "react";
import { Link } from "react-router-dom";
import imgPlaceholder from "../../img/img-placeholder-v1.jpg";
import Translations from "../lang/translations.json";
import ReactHtmlParser from 'html-react-parser';
const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].blog;
const BlogPostCard = ({ type, title, link, imgURL, imgTitle, desc, handleRefresh }) => {
  const classes = `crush crush--${type}`;

  const img = imgURL ? (
    <img src={imgURL} alt={imgTitle} title={imgTitle} className="crush__img" />
  ) : (
    <img src={imgPlaceholder} alt="" className="crush__img" />
  );
  return (
    <article className={classes}>
      <Link to={link} className="crush__link" onClick={handleRefresh}>
        <div className="crush__img-wrapper">
          {img}
          <span className="crush__img-copyrights hide-desktop">{imgTitle}</span>
        </div>
        <div className="crush__content">
          <div>
            <h3 className="crush__title">{title}</h3>
          </div>
          {desc && <p className="crush__desc">{ReactHtmlParser(desc)}</p>}
          <span className="crush__fake-link">{Texts.lire}</span>
        </div>
      </Link>
    </article>
  );
};

export default BlogPostCard;
