import { FETCH_CRUSHES_OF_THE_DAY } from '../constants/ActionTypes';

function crushesOfTheDayReducer(state = [], action) {
  switch (action.type) {
    case FETCH_CRUSHES_OF_THE_DAY:
      return [...state, ...action.payload.products];
    default:
      return state;
  }
}

export default crushesOfTheDayReducer;
