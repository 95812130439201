import { FETCH_CRUSHES_CATERING } from '../constants/ActionTypes';

function crushesCateringReducer(state = [], action) {
  switch (action.type) {
    case FETCH_CRUSHES_CATERING:
      return [...state, ...action.payload.products];
    default:
      return state;
  }
}

export default crushesCateringReducer;
