import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Translations from "../lang/translations.json";
import { fetchBlogPosts, fetchBlogPostMore } from "../actions";
import Loader from "../components/Loader";
import BlogPostCard from "../components/BlogPostCard";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].blog;
class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: props.match.params.postTitle
        ? props.match.params.postTitle.split("-").pop()
        : "",
    };
  }

  componentDidMount() {
    // Fetch Posts
    if (this.props.blogPosts === undefined || !this.props.blogPosts.length) {
      this.props.fetchBlogPosts(lang);
    }
  }

  loadMorePosts(link, totalPages) {
    let url = new URL(link);
    let params = new URLSearchParams(url.search);
    let currentPage =
      params.get("page") === null ? 1 : parseInt(params.get("page"));
    const nextPage = currentPage + 1;

    params.set("page", nextPage);
    url.search = params.toString();
    const nextURL = url.toString();

    this.setState({ isPostsLoading: true });

    this.props.fetchBlogPostMore(lang, nextURL, () => {
      this.setState({ isPostsLoading: false });
    });
    if (nextPage >= parseInt(totalPages)) {
      this.setState({ isTotalPages: true });
    }
  }

  renderBlogPosts() {
    const blogPosts = this.props.blogPosts.data;
    if (!blogPosts) {
      return <Loader loading={true} text={Texts.chargement} />;
    } else {
      return (
        <div className="crushes__list">
          <div className="crushes__list crushes__list--three">
            {blogPosts.map(this.renderBlogPost, this)}
          </div>
        </div>
      );
    }
  }

  renderBlogPost(post, index) {
    const id = post.id;
    const title = post.title.rendered;
    const imgURL =
      post.featured_media > 0
        ? post._embedded["wp:featuredmedia"][0].media_details.sizes.medium
            .source_url
        : null;
    const imgTitle =
      post.featured_media > 0
        ? post._embedded["wp:featuredmedia"][0].title.rendered
        : post.title.rendered;
    const desc = post.excerpt.rendered.replace(/(<([^>]+)>)/gi, "");
    const qString = this.props.location.search;
    const link = `blog/${post.slug}-${post.id}${qString}`;
    const type = index === 0 ? "big-on-mobile" : "small";

    return (
      <BlogPostCard
        key={id}
        title={title}
        link={link}
        type={type}
        imgURL={imgURL}
        imgTitle={imgTitle}
        desc={desc}
      />
    );
  }

  render() {
    const totalPages = parseInt(this.props.blogPosts.totalPages);
    return (
      <main className="main main--results blog">
        <div id="viewContent" className="crushes">
          <div className="crushes__inner">
            {this.renderBlogPosts()}
            {!this.state.isTotalPages && totalPages > 1 && (
              <Loader
                loading={this.state.isPostsLoading}
                handleClick={() =>
                  this.loadMorePosts(
                    this.props.blogPosts.nextURL,
                    this.props.blogPosts.totalPages
                  )
                }
                text={Texts.afficher_plus}
              />
            )}
          </div>
        </div>
      </main>
    );
  }
}
function mapStateToProps({ blogPosts }) {
  return {
    blogPosts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBlogPosts,
      fetchBlogPostMore,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
