import { FETCH_BLOG, FETCH_BLOG_POST_MORE } from "../constants/ActionTypes";

function blogReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_BLOG:
      return {
        ...state,
        data: [...action.payload.response.data],
        nextURL: action.payload.url,
        totalPages: action.payload.response.headers["x-wp-totalpages"],
      };
    case FETCH_BLOG_POST_MORE:
      return {
        ...state,
        data: [...state.data, ...action.payload.response.data],
        nextURL: action.payload.url,
        totalPages: action.payload.response.headers["x-wp-totalpages"],
      };
    default:
      return state;
  }
}

export default blogReducer;
