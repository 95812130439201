import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import qs from "qs";
import isEmpty from "lodash/isEmpty";

import { hideModal } from "../../actions";
import Modal from "../../components/Modal";
import SearchBox from "../../components/SearchBox";
import Day from "../../components/Day";
import logoAlsaceFlat from "../../../img/logo-alsace-flat.svg";
import Translations from "../../lang/translations.json";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].modalLocationDate;

// Les mois en DE
moment.updateLocale("de", {
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ]
});
// title, afterClose, hideModal

class ModalDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeDayIndex: null
    };

    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const qString = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (!isEmpty(qString) && qString.jour !== undefined) {
      const nb = parseInt(qString.jour.split("-")[1], 10);
      this.setState({ activeDayIndex: nb });
    }
  }

  onClose() {
    this.props.hideModal();

    if (this.props.afterClose) {
      this.props.afterClose();
    }
  }

  onSubmit() {
    this.props.hideModal();

    if (this.props.afterClose) {
      this.props.afterClose();
    }
  }

  onDayToggle(index) {
    var qSearch = this.props.history.location.search;
    var parsedURL = qs.parse(qSearch, {
      ignoreQueryPrefix: true
    });

    if (parsedURL.manger) {
      parsedURL.manger = "";
    }

    if (parsedURL["manger-a-proximite"]) {
      parsedURL["manger-a-proximite"] = 0;
    }

    if (this.state.activeDayIndex === index) {
      // Unselect a day
      this.setState({ activeDayIndex: null });
      parsedURL.jour = "";
    } else {
      // Select a day
      this.setState({ activeDayIndex: index });
      const fullDate = moment()
        .add("days", index)
        .format("DD-MM-YYYY");
      parsedURL.jour = `plus-${index}-${fullDate}`;
    }

    this.props.history.push({
      pathname: "/resultats",
      search: qs.stringify(parsedURL)
    });
  }

  renderDays() {
    const classes = this.state.activeDayIndex ? "day is-active" : "day";
    const activeIndex = this.state.activeDayIndex;
    let days = [];
    
    for (let i = 0; i <= 7; i++) {
      const number = moment()
        .add("days", i)
        .format("DD");
      const month = moment()
        .locale(lang)
        .add("days", i)
        .format("MMMM");

      let day = (
        <Day
          key={`index-${i}`}
          onDayToggle={this.onDayToggle.bind(this, i)}
          isSelected={i === activeIndex}
          number={number}
          month={month}
          isActive={i === 0}
          classes={classes}
        />
      );

      days.push(day);
    }
    return days;
  }

  render() {
    return (
      <Modal
        title={this.props.title}
        position={this.props.position}
        onClose={this.onClose}
      >
        <div className="modal__logo-wrapper">
          <img src={logoAlsaceFlat} alt="Alsace" />
        </div>
        <div className="searchboxes">
          <div className="searchboxes__inner">
            <SearchBox type="date" isActive={true} />
            <SearchBox type="location" isActive={false} />
          </div>
        </div>

        <div className="current-date">
          <span className="current-date__txt">{Texts.nous_sommes}</span>
          <span className="current-date__number">
            {moment()
              .format("DD")}
          </span>
          <span className="current-date__month">
            {moment()
              .locale(lang)
              .format("MMMM")}
          </span>
        </div>

        <div className="upcoming-days">
          <ul className="upcoming-days__list">{this.renderDays()}</ul>
        </div>
        <div className="modal__btn-wrapper">
          <button className="btn-primary" onClick={this.onSubmit.bind(this)}>
            {Texts.resultats}
          </button>
        </div>
      </Modal>
    );
  }
}

ModalDate.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func
};

export default connect(
  null,
  { hideModal }
)(withRouter(ModalDate));
