/**
 * Matomo Module
 *
 */
let initialized = false;

/**
 * Utilities
 */
function init(siteId, trackerUrl, srcUrl) {
  if (!siteId) {
    return console.warn("Please insert matomo ID for initializing");
  }

  /* eslint-disable */
  var _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);

  (function () {
    var u = `${trackerUrl}`;
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", siteId]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = `${srcUrl}`;
    s.parentNode.insertBefore(g, s);
  })();
  /* eslint-enable */
}

function tagManager(containerSrcUrl) {
  var _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
  var d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.async = true;
  g.src = `${containerSrcUrl}`;
  s.parentNode.insertBefore(g, s);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init,
  tagManager,
};
