import { CLIENT_ID_AXEPTIO, COOKIES_VERSION_ENGLISH, COOKIES_VERSION_FRENCH } from '../constants/Axeptio';

/**
 * @returns window.axeptioSettings
 */
const	initializeAxeptio = () => {
  const lang =
    window.localStorage.getItem("lang") === null
      ? "FR"
      : window.localStorage.getItem("lang");

  let cookiesVersion = '';
  switch (lang) {
    case "EN":
    case "GB":
      cookiesVersion = COOKIES_VERSION_ENGLISH;
      break;
    // !@todo : for now use english version - we'll have to be changed
    case "DE":
      cookiesVersion = COOKIES_VERSION_ENGLISH;
      break;
    case "FR":
      cookiesVersion = COOKIES_VERSION_FRENCH;
      break;
    default: 
      cookiesVersion = COOKIES_VERSION_FRENCH;
      break;
  }

  return window.axeptioSettings = {
    clientId: CLIENT_ID_AXEPTIO,
    cookiesVersion: cookiesVersion,
  };
};

/**
 * @returns axeptio script tag function
 */
const loadAxeptioScript = () => {
  return (function(d, s) {
    var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
    e.async = true; e.src = "//static.axept.io/sdk-slim.js";
    t.parentNode.insertBefore(e, t);
  })(document, "script");
};

/**
 * @returns axeptio reload spa script 
 */
const reloadSpaForAxeptio = () => {
  window._axcb = window._axcb || [];

  return window._axcb.push(function(sdk) {
    sdk.on('consent:saved', function(choices) { 
        window.location.reload()
    });
  });
}

const Axeptio = {
  initializeAxeptio,
  loadAxeptioScript,
  reloadSpaForAxeptio
}

export default Axeptio;