export function slugify(text) {
  const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, '-et-') // Replace & with 'et'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-+|-+$/g, ''); // remove leading, trailing -
}

export function arrayToObject(array, keyField) {
  return array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});
}

export function usDateFormat(date) {
  var dateSplit = [];

  if (date.includes('/')) {
    dateSplit = date.split('/');
  }
  if (date.includes('-')) {
    dateSplit = date.split('-');
  }

  const dateParamFormatted = [dateSplit[2], dateSplit[1], dateSplit[0]].join(
    '-'
  );

  return dateParamFormatted;
}

export function frDateFormat(date) {
  var dateSplit = [];

  if (date.includes('/')) {
    dateSplit = date.split('/');
  }
  if (date.includes('-')) {
    dateSplit = date.split('-');
  }

  const dateParamFormatted = [dateSplit[2], dateSplit[1], dateSplit[0]].join(
    '/'
  );

  return dateParamFormatted;
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function stripHtml(string){
  return string.replace(/(<([^>]+)>)/gi, "");
}