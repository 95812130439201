import { combineReducers } from "redux";
import { reducer as FormReducer } from "redux-form";
import ModalReducer from "./ModalReducer";
import InspirationsReducer from "./InspirationsReducer";
import CitiesReducer from "./CitiesReducer";
import CrushesReducer from "./CrushesReducer";
import CrushesHoplaReducer from "./CrushesHoplaReducer";
import CrushReducer from "./CrushReducer";
import crushesOfTheDayReducer from "./CrushesOfTheDayReducer";
import crushesOfTheDayFranceBleuReducer from "./CrushesOfTheDayFranceBleuReducer";
import CrushesCateringReducer from "./CrushesCateringReducer";
import AllFootersReducer from "./FootersReducer";
import SelectedFooterReducer from "./SelectedFooterReducer";
import SettingsReducer from "./SettingsReducer";
import UserReducer from "./UserReducer";
import UserLocationReducer from "./UserLocationReducer";
import AviziTerritoryReducer from "./AviziTerritoryReducer";
import TermsReducer from "./TermsReducer";
import BlogReducer from "./BlogReducer";
import BlogPostReducer from "./BlogPostReducer";
import blogRandomPostsReducer from "./BlogRandomPostsReducer";
import pageReducer from "./PageReducer";

const rootReducer = combineReducers({
  form: FormReducer,
  modal: ModalReducer,
  cities: CitiesReducer,
  crush: CrushReducer,
  crushes: CrushesReducer,
  crushesHopla: CrushesHoplaReducer,
  crushesOfTheDay: crushesOfTheDayReducer,
  crushesOfTheDayFranceBleu: crushesOfTheDayFranceBleuReducer,
  crushesCatering: CrushesCateringReducer,
  allFooters: AllFootersReducer,
  selectedFooter: SelectedFooterReducer,
  inspirations: InspirationsReducer,
  settings: SettingsReducer,
  user: UserReducer,
  userLocation: UserLocationReducer,
  aviziTerritory: AviziTerritoryReducer,
  terms: TermsReducer,
  blogPosts: BlogReducer,
  blogPost: BlogPostReducer,
  blogRandomPosts: blogRandomPostsReducer,
  page: pageReducer
});

export default rootReducer;
