import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import ReactHtmlParser from 'html-react-parser';
import qs from "qs";
import ReactModal from "react-modal";
import ButtonChecked from "../components/ButtonChecked";
import imgPlaceholder from "../../img/img-placeholder-v1.jpg";
import ReactDOM from "react-dom";
import { stripHtml } from "../helpers";

ReactModal.setAppElement("#root");

class Slider extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }

  handleOpen = (key, credit, imageUrl) => {
    this.setState({
      showModal: true,
      currentModal: key,
      credit: credit,
      imageUrl: imageUrl,
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  updateInspirationParam(index, slug) {
    const qSearch = this.props.history.location.search;
    const parsedURL = qs.parse(qSearch, { ignoreQueryPrefix: true });
    parsedURL.inspiration = this.props.selectedIndex === index ? "" : slug;

    if (parsedURL.manger) {
      parsedURL.manger = "";
    }

    if (parsedURL["manger-a-proximite"]) {
      parsedURL["manger-a-proximite"] = 0;
    }

    this.props.history.push({
      pathname: "/resultats",
      search: qs.stringify(parsedURL),
    });
  }

  renderTinySliderFilter(settings) {
    if (!this.props.data) {
      return;
    }
    const slides = this.props.data;

    return (
      <TinySlider settings={settings}>
        {slides.map((slide, index) => {
          const id = slide.id;
          const title = ReactHtmlParser(slide.title.rendered);
          const imgURL =
            slide._embedded["wp:featuredmedia"][0].media_details.sizes.slider
              .source_url;
          const slug = slide.slug;
          const copyright = ReactHtmlParser(
            stripHtml(slide._embedded["wp:featuredmedia"][0].caption.rendered)
          );

          return (
            <ButtonChecked
              key={index}
              title={title}
              imgURL={imgURL}
              isSelected={index === this.props.selectedIndex}
              id={id}
              handleOnClick={this.updateInspirationParam.bind(
                this,
                index,
                slug
              )}
              copyright={copyright}
            />
          );
        })}
      </TinySlider>
    );
  }

  renderTinySliderStatic(settings) {
    const slides = this.props.data;
    const slidesCredits = this.props.credits;
    return (
      <div>
        {this.state.showModal && (
          <ReactModal
            isOpen={this.state.showModal}
            contentLabel={this.state.credit}
            className="modal"
            overlayClassName="modal__overlay"
            onRequestClose={this.handleCloseModal}
            closeTimeoutMS={250}
          >
            <button onClick={this.handleCloseModal}>
              <div className="modal__image-container">
                <img
                  src={this.state.imageUrl}
                  alt={this.state.credit}
                  className="modal__image"
                  title={this.state.credit}
                />
                {this.state.credit && (
                  <p className="credit">{this.state.credit}</p>
                )}
              </div>
            </button>
          </ReactModal>
        )}

        <TinySlider settings={settings}>
          {slides.map((slide, index) => {
            const imgURL = slide.valeur
              ? `https://${slide.valeur
                  .replace("https://", "")
                  .replace("http://", "")}`
              : imgPlaceholder;

            var credit = "";
            var copyright = "";
            if (slidesCredits[index] !== undefined) {
              credit = "© " + slidesCredits[index].valeur.replace("©", "");
              copyright = "©";
            } else {
              credit = "";
            }

            return (
              <div key={index} className="slide slide--static">
                <div className="slide__inner">
                  <div className="slide__responsive-wrapper">
                    <button
                      onClick={() => this.handleOpen(index, credit, imgURL)}
                    >
                      <img
                        src={imgURL}
                        alt={credit}
                        title={credit}
                        className="slide__img"
                      />{" "}
                    </button>
                    <div className="slide__credit">{copyright}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </TinySlider>
      </div>
    );
  }

  render() {
    const settings = {
      container: "#toto",
      nav: this.props.nav || false,
      mouseDrag: true,
      loop: false,
      startIndex: 1,
      items: 2,
      gutter: 10,
      edgePadding: this.props.edgePaddingMobile || 50,
      controls: true,
      responsive: {
        1024: {
          items: 2,
          gutter: 22,
          edgePadding: this.props.edgePaddingDesktop || 250,
        },
      },
    };
    return (
      <div>
        {this.props.type === "filter" &&
          this.props.data &&
          this.renderTinySliderFilter(settings)}
        {this.props.type === "static" && this.renderTinySliderStatic(settings)}
      </div>
    );
  }
}

export default withRouter(Slider);
const props = {};

// ! en cherchant à changer la date dans le formulaire des abonnements -> Error: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.
ReactDOM.render(<Slider {...props} />, document.getElementById("root"));
