import React, { Component, createRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import isEmpty from "lodash/isEmpty";
import "leaflet/dist/leaflet.css";
// https://github.com/PaulLeCam/react-leaflet/issues/255#issuecomment-388492108
import marker from "leaflet/dist/images/marker-icon.png";
import markerUserImg from "../../img/ico-user-location.png";
import marker2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { isArray } from "lodash";
import { getUserLocationCustom } from "../shared";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
});

const markerUser = new L.Icon({
  iconUrl: markerUserImg,
  iconSize: [30, 30],
});

export default class MapUser extends Component {
  state = {
    center: {
      lat: this.props.userLocation[0],
      lng: this.props.userLocation[1],
    },
    marker: {
      lat: this.props.userLocation[0],
      lng: this.props.userLocation[1],
    },
    zoom: 13,
    draggable: true,
  };

  componentDidMount() {}

  refmarker = createRef();
  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable });
  };

  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      this.setState({
        marker: marker.leafletElement.getLatLng(),
      });
      if (this.state.marker) {
        window.localStorage.setItem("userCustomLocation", [
          this.state.marker.lat,
          this.state.marker.lng,
        ]);
      }
    }
  };

  toggleMap = () => {
    const isOpen = this.state.isMapOpen;
    this.setState({ isMapOpen: !isOpen });
  };

  deleteCustomPosition = () => {
    window.localStorage.removeItem("userCustomLocation");
    const isOpen = this.state.isMapOpen;
    this.updatePosition();
    this.setState({ isMapOpen: !isOpen });
  };

  render() {
    const userCustomLocation = getUserLocationCustom();

    const userLocation =
      isArray(userCustomLocation) && !isEmpty(userCustomLocation)
        ? userCustomLocation
        : [this.props.userLocation[0], this.props.userLocation[1]]; // Valeur par défaut
    const markerPosition = [this.state.marker.lat, this.state.marker.lng];
    const marker = userLocation ? (
      <Marker
        icon={markerUser}
        draggable={this.state.draggable}
        onDragend={this.updatePosition}
        position={markerPosition}
        ref={this.refmarker}
      >
        <Popup>
          <span onClick={this.toggleDraggable}></span>
          {this.props.texts.votre_position}
        </Popup>
      </Marker>
    ) : null;

    return (
      <div className="cities__inner custom__position">
        <ul className="cities__list">
          <li className="city" onClick={this.toggleMap}>
            <div className="city__inner">
              <span className="city__distance"></span>
              <span className="city__txt">
                {this.props.texts.modifier_votre_position}
              </span>
            </div>
          </li>
        </ul>
        {window.localStorage.getItem("userCustomLocation") !== null && (
          <button onClick={this.deleteCustomPosition}>
            {this.props.texts.reinitialiser_votre_position}
          </button>
        )}
        {this.state.isMapOpen && (
          <div>
          <MapContainer
            center={userCustomLocation ? markerPosition : userLocation}
            ref="mapRef"
            id="map-user"
            zoom={10}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {marker}
            <Popup position={markerPosition}>{this.props.texts.deplacez}</Popup>
          </MapContainer>
          <div className="modal__btn-wrapper">
          <button className="btn-primary" onClick={this.props.close}>
            {this.props.texts.resultats}
          </button>
        </div>
        </div>
        )}
      </div>
    );
  }
}
