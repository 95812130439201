import { FETCH_PAGE, HANDLE_XHR_ERROR } from "../constants/ActionTypes";

function pageReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_PAGE:
      return { ...state, ...action.payload };
    case HANDLE_XHR_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default pageReducer;
