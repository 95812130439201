import { FETCH_BLOG_POST, HANDLE_XHR_ERROR } from "../constants/ActionTypes";

function blogPostReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_BLOG_POST:
      return { ...state, ...action.payload };
    case HANDLE_XHR_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default blogPostReducer;
