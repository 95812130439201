export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const FETCH_CITIES = "FETCH_CITIES";
export const FETCH_INSPIRATIONS = "FETCH_INSPIRATIONS";
export const FETCH_CRUSH = "FETCH_CRUSH";
export const FETCH_HOPLA = "FETCH_HOPLA";
export const FETCH_MORE_HOPLA = "FETCH_MORE_HOPLA";
export const REMOVE_CRUSH = "REMOVE_CRUSH";
export const FETCH_CRUSHES = "FETCH_CRUSHES";
export const FETCH_MORE_CRUSHES = "FETCH_MORE_CRUSHES";
export const FETCH_MORE_CRUSHES_MAP = "FETCH_MORE_CRUSHES_MAP";
export const FETCH_CRUSHES_OF_THE_DAY = "FETCH_CRUSHES_OF_THE_DAY";
export const FETCH_CRUSHES_OF_THE_DAY_FRANCE_BLEU =
  "FETCH_CRUSHES_OF_THE_DAY_FRANCE_BLEU";
export const FETCH_CRUSHES_CATERING = "FETCH_CRUSHES_CATERING";
export const FETCH_ALL_FOOTERS = "FETCH_ALL_FOOTERS";
export const FETCH_SELECTED_FOOTER = "FETCH_SELECTED_FOOTER";
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_USER = "FETCH_USER";
export const GET_USER_LOCATION = "GET_USER_LOCATION";
export const FETCH_AVIZI_TERRITORY = "FETCH_AVIZI_TERRITORY";
export const FETCH_TERMS = "FETCH_TERMS";
export const FETCH_BLOG = "FETCH_BLOG";
export const FETCH_BLOG_POST = "FETCH_BLOG_POST";
export const HANDLE_XHR_ERROR = "HANDLE_XHR_ERROR";
export const FETCH_BLOG_RANDOM_POSTS = "FETCH_BLOG_RANDOM_POSTS";
export const FETCH_BLOG_POST_MORE = "FETCH_BLOG_POST_MORE";
export const FETCH_PAGE = "FETCH_PAGE";