import { FETCH_CRUSHES_OF_THE_DAY_FRANCE_BLEU } from '../constants/ActionTypes';

function crushesOfTheDayFranceBleuReducer(state = [], action) {
  switch (action.type) {
    case FETCH_CRUSHES_OF_THE_DAY_FRANCE_BLEU:
      return [...state, ...action.payload.products];
    default:
      return state;
  }
}

export default crushesOfTheDayFranceBleuReducer;
