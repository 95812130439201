import { FETCH_CRUSHES, FETCH_MORE_CRUSHES, FETCH_MORE_CRUSHES_MAP } from "../constants/ActionTypes";

function crushesReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_MORE_CRUSHES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          next: action.payload.pagination.next,
        },
        products: [...state.products, ...action.payload.products],
      };
    case FETCH_MORE_CRUSHES_MAP:
      return {
        ...state,
        paginationMap: {
          ...state.pagination,
          next: action.payload.pagination.next,
        },
        productsMap: [...state.products, ...action.payload.products],
      };
    case FETCH_CRUSHES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default crushesReducer;
