import * as geolib from 'geolib';
import { usDateFormat, slugify } from './helpers';

export function getClosestCity(myPos, cities) {
  return cities
    .map(city => {
      const lat = city.metas._CommuneLat
        ? parseFloat(city.metas._CommuneLat[0])
        : 0;
      const lon = city.metas._CommuneLon
        ? parseFloat(city.metas._CommuneLon[0])
        : 0;
      const distance = Math.floor(
        geolib.getDistance(myPos, {
          latitude: lat,
          longitude: lon
        }) / 1000
      );

      city.distance = distance;

      return city;
    })
    .sort((prev, next) => (prev.distance > next.distance ? 1 : -1))
    .slice(0, 1)[0];
}

export function getDistanceFromCrush(crushPos, userPos) {
  if (userPos.latitude !== undefined && (crushPos.latitude !== null) && (crushPos.longitude !== null)) {
    var distanceFromCrush = Math.floor(
      geolib.getDistance(userPos, {
        latitude: parseFloat(crushPos.latitude.replace(',', '.')),
        longitude: parseFloat(crushPos.longitude.replace(',', '.'))
      }) / 1000
    );
    
    if(distanceFromCrush === 0){distanceFromCrush = "proximité";}
    return distanceFromCrush;
  }
}

export function getCityTerritoryMatch(cities, territoryName) {
  return cities.find(
    city => slugify(city.metas._Territoire[0]) === territoryName
  );
}

export function getSelectedDate(selectedDate) {
  const date = selectedDate
    .split('-')
    .slice(2)
    .join('-');
  const dateFormatted = usDateFormat(date);

  return dateFormatted;
}

export function getUserLocationCustom(){
  let userCustomLocation = window.localStorage.getItem("userCustomLocation");
    if (userCustomLocation !== null) {
      userCustomLocation = window.localStorage
        .getItem("userCustomLocation")
        .split(",");
      userCustomLocation = [userCustomLocation[0], userCustomLocation[1]];
      return userCustomLocation
    }
}