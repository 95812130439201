import React from 'react';
import { connect } from 'react-redux';

import ModalDate from './modals/ModalDate';
import ModalLocation from './modals/ModalLocation';
import ModalSubscribe from './modals/ModalSubscribe';
import { MODAL_TYPE_DATE, MODAL_TYPE_LOCATION, MODAL_TYPE_SUBSCRIBE } from '../constants/ModalTypes';

const MODAL_COMPONENTS = {
  [MODAL_TYPE_DATE]: ModalDate,
  [MODAL_TYPE_LOCATION]: ModalLocation,
  [MODAL_TYPE_SUBSCRIBE]: ModalSubscribe
};

const ModalRoot = ({ type, props }) => {
  if (!type) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[type];
  return <ModalComponent {...props} />;
};

export default connect(state => state.modal)(ModalRoot);
