import axios from "axios";
import moment from "moment";
import isObject from "lodash/isObject";
import {
  SHOW_MODAL,
  HIDE_MODAL,
  FETCH_CITIES,
  FETCH_INSPIRATIONS,
  FETCH_CRUSH,
  REMOVE_CRUSH,
  FETCH_CRUSHES,
  FETCH_HOPLA,
  FETCH_MORE_CRUSHES,
  FETCH_MORE_CRUSHES_MAP,
  FETCH_CRUSHES_OF_THE_DAY,
  FETCH_CRUSHES_OF_THE_DAY_FRANCE_BLEU,
  FETCH_CRUSHES_CATERING,
  FETCH_ALL_FOOTERS,
  FETCH_SELECTED_FOOTER,
  FETCH_SETTINGS,
  FETCH_USER,
  GET_USER_LOCATION,
  FETCH_AVIZI_TERRITORY,
  FETCH_TERMS,
  FETCH_BLOG,
  FETCH_BLOG_POST,
  FETCH_PAGE,
  HANDLE_XHR_ERROR,
  FETCH_BLOG_RANDOM_POSTS,
  FETCH_BLOG_POST_MORE,
} from "../constants/ActionTypes";

import { API_URL } from "../constants/Api";

// SCHEMA = LEI en local et sur liesel--alsace.apsulis.fr
const SCHEMA =
  window.localStorage.getItem("lang") === null ||
  window.localStorage.getItem("lang") === "FR"
    ? API_URL.includes("local") || API_URL.includes("apsulis.fr")
      ? "LEI"
      : "WEBACCESS"
    : `WEBACCESS_${window.localStorage.getItem("lang")}`;

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");

const ROOT_URL_CRUSHES = `https://apps.tourisme-alsace.info/xml/exploitation/listeproduits.asp?latable=&user=2002231&pwkey=30af3b324c5c84bd969807a3bfe44ab6&typsor=6&libtext=&delaiperemption=&clause=2002231000003&leschamps=Assurance,Acompte,ADRPROD_LIBELLE_COMMUNE,Produit,Nom&SCHEMA=${SCHEMA}`;
const ROOT_URL_NO_CRUSHES = `https://apps.tourisme-alsace.info/xml/exploitation/listeproduits.asp?rfrom=1&rto=3&user=2002231&pwkey=30af3b324c5c84bd969807a3bfe44ab6&urlnames=tous&clause=2002231000005&typsor=6&minscore=-1&score=2002231000000&SCHEMA=${SCHEMA}`;
const ROOT_URL_WITH_COORDS = `https://apps.tourisme-alsace.info/xml/exploitation/listeproduits.asp?latable=&user=2002231&pwkey=30af3b324c5c84bd969807a3bfe44ab6&typsor=6&libtext=&delaiperemption=&clause=2002231000004&minscore=-1&score=2002231000000&leschamps=Assurance,Acompte,ADRPROD_LIBELLE_COMMUNE,Produit,Nom&SCHEMA=${SCHEMA}`;
const ROOT_URL_NEARBY_RESTAURANTS = `https://apps.tourisme-alsace.info/xml/exploitation/listeproduits.asp?latable=&user=2002231&pwkey=30af3b324c5c84bd969807a3bfe44ab6&typsor=6&libtext=&delaiperemption=&clause=2002231000003&SCHEMA=${SCHEMA}`;
const ROOT_URL_CRUSH = `https://apps.tourisme-alsace.info/xml/exploitation/ficheproduit.asp?user=2002231&pwkey=30af3b324c5c84bd969807a3bfe44ab6&urlnames=tous&lxml=sit_fiche&SCHEMA=${SCHEMA}&editer=211&leschamps=ADRPROD_NUMERO,ADRPROD_NUM_VOIE,ADRPROD_COMPL_ADRESSE,ADRPROD_CP,ADRPROD_LIBELLE_COMMUNE,ADRPROD_TEL,ADRPROD_EMAIL,ADRPROD_URL,ADRPROD_PAYS,Commentaire,CommentaireL1&lescritex=1901485,1900421,1900480,1900603,1900661,1900751,1900752,1900781,1900783,1900782,1900784,1900861,1900866,1900862,1900867,1900863,1900868,1900946,1900948,1900947,1900949,1900958,1900959,1900960,1900961,1900962,1900963,1900964,1900965,1900966,1900967,1901401,1901402,1901440,1900433,1900529*1903973,1900529*1903974,1900267,1901656,1901348,1900268,1901498,1900067,1900825*1907169,1901536*1911822,1901537,1901285*1911036,1900489,1901541,1901533,1901505,1900916,1900487,1900219,1900564,1900621,900002&typsor=6`;
const ROOT_URL_INSPIRATIONS = `${API_URL}/wp/v2/inspiration/?_embed&meta_key=lang&meta_value=${lang}`;
const ROOT_URL_CITIES = `${API_URL}/liesel/v1/allcommunes`;
const ROOT_URL_FOOTERS = `${API_URL}/wp/v2/footer/?_embed`;
const ROOT_URL_SELECTED_FOOTER = `https://apps.tourisme-alsace.info/xml/exploitation/ficheproduit.asp?user=2002231&pwkey=30af3b324c5c84bd969807a3bfe44ab6&urlnames=tous&lxml=sit_fiche&SCHEMA=${SCHEMA}&editer=211&leschamps=ADRPROD_NUMERO,ADRPROD_NUM_VOIE,ADRPROD_COMPL_ADRESSE,ADRPROD_CP,ADRPROD_LIBELLE_COMMUNE,ADRPROD_TEL,ADRPROD_EMAIL,ADRPROD_URL,ADRPROD_PAYS,Commentaire,CommentaireL1&lescritex=1901485,1900421,1900480,1900603,1900661,1900751,1900752,1900781,1900783,1900782,1900784,1900861,1900866,1900862,1900867,1900863,1900868,1900946,1900948,1900947,1900949,1900958,1900959,1900960,1900961,1900962,1900963,1900964,1900965,1900966,1900967,1901401,1901402,1901440,1900433,1900529*1903973,1900529*1903974,1900267,1901656,1901348,1900268,1901498,1900067&typsor=6`;
const ROOT_URL_SETTINGS = `${API_URL}/liesel/v1/settings`;
const ROOT_URL_USER = `${API_URL}/liesel/v1/contact`;
const ROOT_URL_BLOG = `${API_URL}/wp/v2/posts?_embed=wp:featuredmedia&per_page=9`;
const ROOT_URL_BLOG_POST = `${API_URL}/wp/v2/posts`;
const ROOT_URL_PAGE = `${API_URL}/wp/v2/pages`;
const ROOT_URL_BLOG_RANDOM_POST = `${API_URL}/liesel/v1/blog_posts_random`;

//** mentions légales */
let terms_slug = "mentions-legales";
switch (lang) {
  case "EN":
    terms_slug = "legal-notice";
    break;
  case "DE":
    terms_slug = "rechtliche-hinweise";
    break;
  default:
    terms_slug = "mentions-legales";
    break;
}
const ROOT_URL_TERMS = `${API_URL}/wp/v2/pages/?slug=${terms_slug}&_embed`;
const ROOT_URL_AVIZI_TERRITORY = `${API_URL}/liesel/v1/campagne`;

export const showModal = (type, props) => ({
  type: SHOW_MODAL,
  payload: {
    type,
    props,
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const fetchInspirations = (callback) => async (dispatch) => {
  const url = ROOT_URL_INSPIRATIONS;
  var response = await axios.get(url);

  typeof callback === "function" && callback(response.data);

  dispatch({ type: FETCH_INSPIRATIONS, payload: response.data });
};

export const fetchCities = (callback) => async (dispatch) => {
  const url = ROOT_URL_CITIES;
  const response = await axios.get(url);

  typeof callback === "function" && callback(response.data);

  dispatch({ type: FETCH_CITIES, payload: response.data });
};

export const fetchCrushesOfTheDay =
  (
    nbCrushes = 3,
    critGRC = 213000080,
    critGRC_2 = 213000081,
    todayFormatted,
    yesterday,
    lat = "48,361447333333",
    lon = "7,3452958666667"
  ) =>
  async (dispatch) => {
    const url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&PVALUES=${critGRC}|${critGRC_2},${yesterday},${yesterday}&PNAMES=elcriterio0,minimo0,maximo0&leshoraires=${todayFormatted}|${todayFormatted}&lesheures=00:00|23:59&champstri=PPPP.ALEA%%20AS%%20LALEA&lestris=LALEA%20DESC`;
    let response = await axios.get(url);

    if (response.data.products === null) {
      // console.log(response.data.products);
    }

    // Pas de coups de coeur disponibles
    if (response.data.pagination.result_count === 0) {
      let url = `${ROOT_URL_NO_CRUSHES}&lat=${lat}&lon=${lon}`;
      let response = await axios.get(url);
      if (response.data.pagination.result_count === 0) {
        let lat = "48,361447333333";
        let lon = "7,3452958666667";
        // console.log(
        //   url,
        //   "<==== Pas de coup de coeur avec les coordonnées de l'utilisateur"
        // );
        url = `${ROOT_URL_NO_CRUSHES}&lat=${lat}&lon=${lon}`;
        response = await axios.get(url);
      }
      // console.log(url, "<==== Coup de coeur générique");
      dispatch({
        type: FETCH_CRUSHES_OF_THE_DAY,
        payload: response.data,
      });
    } else {
      // Coups de coeur disponibles
      dispatch({
        type: FETCH_CRUSHES_OF_THE_DAY,
        payload: response.data,
      });
    }
  };

export const fetchCrushesOfTheDayFranceBleu =
  (nbCrushes = 1, todayFormatted) =>
  async (dispatch) => {
    const url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&PVALUES=1901543,${todayFormatted},${todayFormatted}&PNAMES=elcriterio0,minimo0,maximo0`;
    let response = await axios.get(url);
    // console.log(url, "france bleu");
    dispatch({
      type: FETCH_CRUSHES_OF_THE_DAY_FRANCE_BLEU,
      payload: response.data,
    });
  };

export const fetchCrushesByCoords =
  (
    nbCrushes = 12,
    lat = "48,361447333333",
    lon = "7,3452958666667",
    rayon = 5,
    cateringID,
    inspirationID
  ) =>
  async (dispatch) => {
    var url;
    url = cateringID
      ? `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}&PVALUES=${cateringID}&PNAMES=elcriterio0&lescritex=1901496`
      : `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}`;
    url = inspirationID
      ? `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}&PVALUES=${inspirationID}&PNAMES=elcriterio0&lescritex=1901496`
      : `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}`;

    const response = await axios.get(url);

    dispatch({ type: FETCH_CRUSHES, payload: response.data });
  };

export const fetchCrushesByCoordsGRC =
  (
    nbCrushes = 12,
    lat = "48,361447333333",
    lon = "7,3452958666667",
    rayon = 5,
    critGRC = 213000080,
    critGRC_2 = 213000081,
    cateringID,
    inspirationID,
    dateFormatted,
    yesterday,
    commune,
    hopla = false
  ) =>
  async (dispatch) => {
    var url, critexParam, modalidad;
    const startTime = moment().locale("fr").format("HH:mm");
    // const endTime = moment()
    //   .add(1, 'hour')
    //   .format('HH:mm');
    const endTime = "23:59";

    if (cateringID === "1901496") {
      critexParam = "";
      modalidad = "";
    } else if (inspirationID === "1901495") {
      critexParam = "&lescritex=1901495";
      modalidad = "";
    } else {
      critexParam = "";
      modalidad = ",modalidad0";
    }
    var autourDe = false;
    // Autour de
    if (commune.indexOf("Autour de") !== -1) {
      autourDe = true;
    }
    const id = cateringID || inspirationID;
    var maintenant = moment().locale("fr").format("DD/MM/YYYY");
    if (cateringID || inspirationID) {
      // si pas date du jour pas d'horaires
      if (maintenant === dateFormatted) {
        // console.log(fr);
        if (autourDe === true) {
          url = `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}&PVALUES=${id}&PNAMES=elcriterio0${modalidad}${critexParam}&leshoraires=${dateFormatted}|${dateFormatted}&lesheures=${startTime}|${endTime}`;
        } else {
          url = `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&PVALUES=${id}&PNAMES=elcriterio0${modalidad}${critexParam}&leshoraires=${dateFormatted}|${dateFormatted}&lesheures=${startTime}|${endTime}`;
        }
      } else {
        if (autourDe === true) {
          url = `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}&PVALUES=${id}&PNAMES=elcriterio0${modalidad}${critexParam}&leshoraires=${dateFormatted}|${dateFormatted}`;
        } else {
          url = `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&PVALUES=${id}&PNAMES=elcriterio0${modalidad}${critexParam}&leshoraires=${dateFormatted}|${dateFormatted}`;
        }
      }
    } else {
      // si pas date du jour pas d'horaires
      if (maintenant === dateFormatted) {
        // Autour de + aujourd'hui
        if (autourDe === true) {
          url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}&PVALUES=1901495&PNAMES=elcriterio0&leshoraires=${dateFormatted}|${dateFormatted}&lesheures=${startTime}|${endTime}`;
        } else {
          url = `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&PVALUES=${critGRC}|${critGRC_2},${yesterday},${yesterday}&PNAMES=elcriterio0,minimo0,maximo0&leshoraires=${dateFormatted}|${dateFormatted}&lesheures=${startTime}|${endTime}`;
        }
      } else {
        // Autour de + autre date
        if (autourDe === true) {
          url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&rayon=${rayon}&PVALUES=1901495&PNAMES=elcriterio0&leshoraires=${dateFormatted}|${dateFormatted}`;
        } else {
          url = `${ROOT_URL_WITH_COORDS}&rfrom=1&rto=${nbCrushes}&lat=${lat}&lon=${lon}&PVALUES=${critGRC}|${critGRC_2},${yesterday},${yesterday}&PNAMES=elcriterio0,minimo0,maximo0&leshoraires=${dateFormatted}|${dateFormatted}`;
        }
      }
    }
    let response = await axios.get(url);

    /**
     * Pas de coups de coeur disponibles
     */
    if (response.data.pagination.result_count === 0) {
      const url = `${ROOT_URL_NO_CRUSHES}&lat=${lat}&lon=${lon}`;
      let response = await axios.get(url);
      // console.log(url, "<==== Pas de coup de coeur");
      dispatch({
        type: FETCH_CRUSHES,
        payload: response.data,
      });
    } else {
      if (hopla) {
        dispatch({ type: FETCH_HOPLA, payload: response.data });
        hopla = false;
      } else {
        dispatch({ type: FETCH_CRUSHES, payload: response.data });
      }
    }
  };

export const fetchCrushesByGRC =
  (
    nbCrushes = 12,
    critGRC = 213000080,
    critGRC_2 = 213000081,
    cateringID,
    inspirationID,
    dateFormatted,
    yesterday
  ) =>
  async (dispatch) => {
    var url, critexParam, modalidad;
    const startTime = moment().locale("fr").format("HH:mm");
    // const endTime = moment()
    //   .add(1, 'hour')
    //   .format('HH:mm');
    const endTime = "23:59";

    if (cateringID === "1901496") {
      critexParam = "";
      modalidad = "";
    } else if (inspirationID === "1901495") {
      critexParam = "&lescritex=1901495";
      modalidad = "";
    } else {
      critexParam = "";
      modalidad = ",modalidad0";
    }

    const id = cateringID || inspirationID;
    var maintenant = moment().locale("fr").format("DD/MM/YYYY");
    if (cateringID || inspirationID) {
      // si pas date du jour pas d'horaires
      if (maintenant === dateFormatted) {
        url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&PVALUES=${id}&PNAMES=elcriterio0${modalidad}${critexParam}&leshoraires=${dateFormatted}|${dateFormatted}&lesheures=${startTime}|${endTime}`;
      } else {
        url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&PVALUES=${id}&PNAMES=elcriterio0${modalidad}${critexParam}&leshoraires=${dateFormatted}|${dateFormatted}`;
      }
    } else {
      // si pas date du jour pas d'horaires
      if (maintenant === dateFormatted) {
        url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&PVALUES=${critGRC}|${critGRC_2},${yesterday},${yesterday}&PNAMES=elcriterio0,minimo0,maximo0&leshoraires=${dateFormatted}|${dateFormatted}&lesheures=${startTime}|${endTime}`;
      } else {
        url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=${nbCrushes}&PVALUES=${critGRC}|${critGRC_2},${yesterday},${yesterday}&PNAMES=elcriterio0,minimo0,maximo0&leshoraires=${dateFormatted}|${dateFormatted}`;
      }
    }

    const response = await axios.get(url);

    dispatch({ type: FETCH_CRUSHES, payload: response.data });
  };

export const fetchMoreCrushes = (nextURL, callback) => async (dispatch) => {
  const response = await axios.get(nextURL);
  callback();

  dispatch({
    type: FETCH_MORE_CRUSHES,
    payload: response.data,
  });
};

export const fetchMoreCrushesMap = (nextURL, callback) => async (dispatch) => {
  const response = await axios.get(nextURL);
  callback();

  dispatch({
    type: FETCH_MORE_CRUSHES_MAP,
    payload: response.data,
  });
};

export const fetchCrush = (crushID) => async (dispatch) => {
  const url = `${ROOT_URL_CRUSH}&produit=${crushID}`;
  const response = await axios.get(url);
  if (isObject(response.data)) {
    dispatch({
      type: FETCH_CRUSH,
      payload: response.data,
    });
  } else {
    const emptyCrush = "erreur";
    return emptyCrush;
  }
};

export const fetchCrushesCatering = () => async (dispatch) => {
  const url = `${ROOT_URL_CRUSHES}&rfrom=1&rto=50&PVALUES=1901496&PNAMES=elcriterio0&lescritex=1901496`;
  const response = await axios.get(url);

  dispatch({
    type: FETCH_CRUSHES_CATERING,
    payload: response.data,
  });
};

export const fetchRestaurantsByCoords = (lon, lat) => async (dispatch) => {
  var maintenant = moment().locale("fr").format("DD/MM/YYYY");
  const startTime = moment().locale("fr").format("HH:mm");
  const url = `${ROOT_URL_NEARBY_RESTAURANTS}&rfrom=1&rto=50&PVALUES=1901496&PNAMES=elcriterio0&lat=${lat}&lon=${lon}&rayon=15&leshoraires=${maintenant}|${maintenant}&lesheures=${startTime}|23:59&score=2002231000000&lescritex=1901485,1900421,1900480,1900603,1900661,1900751,1900752,1900781,1900783,1900782,1900784,1900861,1900866,1900862,1900867,1900863,1900868,1900946,1900948,1900947,1900949,1900958,1900959,1900960,1900961,1900962,1900963,1900964,1900965,1900966,1900967,1901401,1901402,1901440,1900433,1900529*1903973,1900529*1903974,1900267,1901656,1901348,1900268,1901498,1900067,1900825*1907169,1901536*1911822,1901537,1901285*1911036,1900489,1901541,1901505,1900916,1900487,1900219,1900564,1900621,900002`;
  const response = await axios.get(url);

  dispatch({
    type: FETCH_CRUSHES,
    payload: response.data,
  });
};

export const removeCrush = () => {
  const crush = {};

  return {
    type: REMOVE_CRUSH,
    payload: crush,
  };
};

export const fetchAllFooters = (callback) => async (dispatch) => {
  const url = `${ROOT_URL_FOOTERS}&per_page=100`;
  const response = await axios.get(url);

  typeof callback === "function" && callback(response.data);

  dispatch({
    type: FETCH_ALL_FOOTERS,
    payload: response.data,
  });
};

export const fetchSelectedFooter = (idlei, callback) => async (dispatch) => {
  var maintenant = moment().locale("fr").format("DD/MM/YYYY");
  const startTime = moment().locale("fr").format("HH:mm");
  const url = `${ROOT_URL_SELECTED_FOOTER}&produit=${idlei}&leshoraires=${maintenant}|${maintenant}&lesheures=${startTime}|23:59`;
  const response = await axios.get(url);

  typeof callback === "function" && callback(response.data);

  dispatch({
    type: FETCH_SELECTED_FOOTER,
    payload: response.data,
  });
};

export const fetchSettings = (callback) => async (dispatch) => {
  const url = ROOT_URL_SETTINGS;
  const response = await axios.get(url);

  typeof callback === "function" && callback(response.data);

  dispatch({ type: FETCH_SETTINGS, payload: response.data });
};

export const fetchUser = (userID, callback) => async (dispatch) => {
  const url = `${ROOT_URL_USER}/${userID}`;
  const response = await axios.get(url);

  typeof callback === "function" && callback(response.data);

  dispatch({
    type: FETCH_USER,
    payload: response.data,
  });
};

export function getUserLocation(callback) {
  return (dispatch) => {
    const geolocation = navigator.geolocation;
    geolocation.getCurrentPosition((position) => {
      typeof callback === "function" && callback(position.coords);

      dispatch({
        type: GET_USER_LOCATION,
        payload: position,
      });
    });
  };
}

export const fetchAviziTerritory =
  (idCampaign, callback) => async (dispatch) => {
    const url = `${ROOT_URL_AVIZI_TERRITORY}/${idCampaign}`;
    const response = await axios.get(url);

    typeof callback === "function" && callback(response.data);

    dispatch({
      type: FETCH_AVIZI_TERRITORY,
      payload: response.data,
    });
  };

export const fetchTerms = (callback) => async (dispatch) => {
  const url = `${ROOT_URL_TERMS}`;
  const response = await axios.get(url);

  typeof callback === "function" && callback(response.data);

  dispatch({
    type: FETCH_TERMS,
    payload: response.data,
  });
};

export const fetchBlogPosts = (lang, callback) => async (dispatch) => {
  const url = `${ROOT_URL_BLOG}&lang=${lang.toLowerCase()}`;
  var response = await axios.get(url);
  typeof callback === "function" && callback(response.data);

  dispatch({ type: FETCH_BLOG, payload: { response, url } });
};

export const fetchBlogPost = (post, lang, callback) => async (dispatch) => {
  const url = `${ROOT_URL_BLOG_POST}/${post}/?_embed&lang=${lang.toLowerCase()}`;
  await axios
    .get(url)
    .then((response) =>
      dispatch({ type: FETCH_BLOG_POST, payload: response.data })
    )
    .catch((error) =>
      dispatch({ type: HANDLE_XHR_ERROR, payload: error.response.data.data })
    );
};

export const fetchPage = (page, lang, callback) => async (dispatch) => {
  const url = `${ROOT_URL_PAGE}/?_embed&lang=${lang.toLowerCase()}&slug=${page}`;
  await axios
    .get(url)
    .then((response) =>
      dispatch({ type: FETCH_PAGE, payload: response.data[0] })
    )
    .catch((error) =>
      dispatch({ type: HANDLE_XHR_ERROR, payload: error.response.data.data })
    );
};

export const fetchBlogPostMore =
  (lang, nextURL, callback) => async (dispatch) => {
    const url = `${nextURL}&lang=${lang.toLowerCase()}`;
    const response = await axios.get(url);
    callback();

    dispatch({
      type: FETCH_BLOG_POST_MORE,
      payload: { response, url },
    });
  };

export const fetchBlogRandomPosts =
  (post, lang, callback) => async (dispatch) => {
    const url = `${ROOT_URL_BLOG_RANDOM_POST}/${post}?lang=${lang.toLowerCase()}`;
    await axios
      .get(url)
      .then((response) =>
        dispatch({ type: FETCH_BLOG_RANDOM_POSTS, payload: response.data })
      )
      .catch((error) => dispatch({ type: HANDLE_XHR_ERROR, payload: error }));
  };
