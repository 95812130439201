import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Translations from "../lang/translations.json";
import { fetchBlogPost, fetchBlogRandomPosts } from "../actions";
import Loader from "../components/Loader";
import ReactHtmlParser from "html-react-parser";
import { BASE_URL } from "../constants/Api";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import BlogPostCard from "../components/BlogPostCard";
import Gtag from "../utils/Gtag";

const lang =
  window.localStorage.getItem("lang") === null
    ? "FR"
    : window.localStorage.getItem("lang");
const Texts = Translations[lang].blog;
class BlogPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postID: props.match.params.postTitle
        ? Math.abs(props.match.params.postTitle.split("-").pop())
        : "",
    };
  }

  componentDidMount() {
    // Fetch Post
    if (this.props.blogPost === undefined || !this.props.blogPost.length) {
      this.props.fetchBlogPost(this.state.postID, lang);
    }

    // Fetch Random Posts
    if (
      this.props.blogRandomPosts === undefined ||
      !this.props.blogRandomPosts.length
    ) {
      this.props.fetchBlogRandomPosts(this.state.postID, lang);
    }

    const eventName = "Vue article";
    const eventParameters = {
      category: eventName,
      action: this.state.postID,
    };

    Gtag.gtag("event", eventName, eventParameters);
  }

  handleRefresh = (post, lang) => (e) => {
    this.setState({ postID: post });
    this.props.fetchBlogPost(post, lang);
    this.props.fetchBlogRandomPosts(post, lang);
  };

  renderBlogPosts() {
    const blogPost = this.props.blogPost;
    if (
      blogPost === undefined ||
      isEmpty(blogPost) ||
      typeof blogPost !== "object"
    ) {
      return <Loader loading={true} text={Texts.chargement} />;
    } else {
      return (
        <div className="crushes__list crushes__list--three">
          {this.renderBlogPost(blogPost)}
        </div>
      );
    }
  }

  renderBlogRandomPosts() {
    const blogRandomPosts = this.props.blogRandomPosts;
    if (blogRandomPosts === undefined || isEmpty(blogRandomPosts)) {
      return <Loader loading={true} text={Texts.chargement} />;
    } else {
      return (
        <div className="crushes__list crushes__list--three">
          {blogRandomPosts.map(this.renderBlogRandomPost, this)}
        </div>
      );
    }
  }

  renderBlogPost(post) {
    const title = post.title.rendered;
    const content = post.content.rendered;
    if (post.status && post.status > 200) {
      return (
        <article>
          <h1>{title}</h1>
          <div className="post-content">{Texts.article_invalide}</div>
        </article>
      );
    }

    return (
      <article>
        <h1>{title}</h1>
        <div className="post-content">{ReactHtmlParser(content)}</div>
      </article>
    );
  }

  renderBlogRandomPost(post, index) {
    const id = post.id;
    const title = post.title;
    const imgURL = !isEmpty(post.featured_image) ? post.featured_image : null;
    const imgTitle = !isEmpty(post.featured_image_title)
      ? post.featured_image_title
      : post.title;
    const desc = post.excerpt.replace(/(<([^>]+)>)/gi, "");
    const qString = this.props.location.search;
    const link = `../blog/${post.slug}-${post.id}${qString}`;
    const type = index === 0 ? "big-on-mobile" : "small";
    return (
      <BlogPostCard
        key={id}
        title={title}
        link={link}
        type={type}
        imgURL={imgURL}
        imgTitle={imgTitle}
        desc={desc}
        handleRefresh={this.handleRefresh(id, lang)}
      />
    );
  }

  render() {
    const qString = this.props.location.search;
    const blogLink = `../blog${qString}`;
    return (
      <main id="viewContent" className="main main--blog">
        <div className="generic-content">
          <div className="generic-content__block"></div>
          <Link to={blogLink} title="">
            {Texts.retour_liste}
          </Link>
          <link
            href={`${BASE_URL}/wp-admin/load-styles.php?c=0&dir=ltr&load%5B%5D=buttons,wp-components,wp-block-editor,wp-nux,wp-block-library,wp-block-&load%5B%5D=library-theme,wp-format-library,common,list-tables`}
            rel="stylesheet"
          />
          <link
            href={`${BASE_URL}/wp-includes/css/dist/block-library/style.min.css`}
            rel="stylesheet"
          />
          {this.renderBlogPosts()}
          <div className="generic-content__block"></div>
          <h2 className="crushes__title">{Texts.recommandations}</h2>
          {this.renderBlogRandomPosts()}
        </div>
      </main>
    );
  }
}
function mapStateToProps({ blogPost, blogRandomPosts }) {
  return {
    blogPost,
    blogRandomPosts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBlogPost,
      fetchBlogRandomPosts,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost);
